import React from "react";
import { close as closeIcon } from "../../../assets/images/icons";

const Home = () => {
  const handleSearch = () => {
    const searchQuery = document
      .getElementById("searchInput")
      .value.toLowerCase();
    const downloadLinks = document.getElementsByClassName("download-link");

    Array.from(downloadLinks).forEach((link) => {
      const downloadName = link.textContent.toLowerCase();
      if (downloadName.includes(searchQuery)) {
        link.style.display = "block";
      } else {
        link.style.display = "none";
      }
    });
  };
  const handleClear = () => {
    document.getElementById("searchInput").value = "";
    handleSearch();
  };
  return (
    <div className="home">
      <h1 className="title">DS Downloads</h1>
      <div className="filter">
        <input
          type="text"
          id="searchInput"
          placeholder="Search downloads..."
          onInput={handleSearch}
        />
        <button onClick={handleClear}>
          <img src={closeIcon} alt="Close"/>
        </button>
      </div>
      <div className="downloads">
        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0001%20-%20Tom%20Clancys%20Ghost%20Recon%20Shadow%20Wars%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29%28LGC%29.3ds.7z"
        >
          3DS0001 - Tom Clancys Ghost Recon Shadow Wars
          (Europe)(En,Fr,Ge,It,Es)(LGC).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0002%20-%20Super%20Mon_%20Ball%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29%20%28LGC%29.3ds.7z"
        >
          3DS0002 - Super Mon_ Ball 3D (Europe)(En,Fr,Ge,It,Es) (LGC).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0003%20-%20LEGO%20Star%20Wars%20III%20The%20Clone%20Wars%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29%20%28LGC%29.3ds.7z"
        >
          3DS0003 - LEGO Star Wars III The Clone Wars (Europe)(En,Fr,Ge,It,Es)
          (LGC).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0004%20-%20The%20Legend%20Of%20Zelda%20Ocarina%20Of%20Time%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0004 - The Legend Of Zelda Ocarina Of Time 3D
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0005%20-%20Super%20Mario%203D%20Land%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPo%2CRu%29.3ds.7z"
        >
          3DS0005 - Super Mario 3D Land (Europe)
          (En,Fr,Ge,It,Es,Nl,Po,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0006%20-%20StarFox%2064%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0006 - StarFox 64 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0007%20-%20PilotWings%20Resort%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0007 - PilotWings Resort (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0008%20-%20Resident%20Evil%20The%20Mercenaries%203D%20%28Europe%29%28En%2CFr%2CGe%2Cit%2CEs%29.3ds.7z"
        >
          3DS0008 - Resident Evil The Mercenaries 3D
          (Europe)(En,Fr,Ge,it,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0009%20-%20Puzzle%20Bobble%20Universe%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0009 - Puzzle Bobble Universe 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0010%20-%20Rayman%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0010 - Rayman 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0011%20-%20Super%20Street%20Fighter%20IV%20-%203D%20Edition%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29%20.3ds.7z"
        >
          3DS0011 - Super Street Fighter IV - 3D Edition (Europe)
          (En,Fr,Ge,It,Es) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0012%20-%20Dead%20or%20Alive%20-%20Dimensions%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0012 - Dead or Alive - Dimensions 3D (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0013%20-%20Splinter%20Cell%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0013 - Splinter Cell 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0014%20-%20Ridge%20Racer%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0014 - Ridge Racer 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0015%20-%20Doctor%20Lautrec%20and%20the%20Forgotten%20Knights%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0015 - Doctor Lautrec and the Forgotten Knights (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0016%20-%20Mario%20Kart%207%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPo%2CRu%29.3ds.7z"
        >
          3DS0016 - Mario Kart 7 (Europe) (En,Fr,Ge,It,Es,Nl,Po,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0017%20-%20Steel%20Diver%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0017 - Steel Diver (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0018%20-%20Tetris%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0018 - Tetris (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0019%20-%20Cubic%20Ninja%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0019 - Cubic Ninja (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0020%20-%20Asphalt%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0020 - Asphalt 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0021%20-%20Driver%20Renegate%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPo%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS0021 - Driver Renegate 3D (Europe)
          (En,Fr,Ge,It,Es,Nl,Po,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0022%20-%20LEGO%20Pirates%20of%20The%20Caribbean%20-%20The%20Video%20Game%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS0022 - LEGO Pirates of The Caribbean - The Video Game (Europe)
          (En,Fr,Ge,It,Es,Nl,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0023%20-%20The%20Sims%203%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0023 - The Sims 3 (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0024%20-%20Madden%20NFL%20Football%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0024 - Madden NFL Football (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0025%20-%20Ridge%20Racer%203D%20%28Japan%29.3ds.7z"
        >
          3DS0025 - Ridge Racer 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0026%20-%20Super%20Pokemon%20Rumble%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0026 - Super Pokemon Rumble (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0027%20-%20Tales%20of%20the%20Abyss%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0027 - Tales of the Abyss (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0028%20-%20Green%20Lantern%20-%20Rise%20of%20the%20Manhunters%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0028 - Green Lantern - Rise of the Manhunters (Europe)
          (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0029%20-%20Thor%20-%20God%20of%20Thunder%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0029 - Thor - God of Thunder (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0030%20-%20Dream%20Trigger%203D%20%28E%29.3ds.7z"
        >
          3DS0030 - Dream Trigger 3D (E).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0031%20-%20Ridge%20Racer%203D%20%28U%29.3ds.7z"
        >
          3DS0031 - Ridge Racer 3D (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0032%20-%20Pilotwings%20Resort%20%28Usa%29.3ds.7z"
        >
          3DS0032 - Pilotwings Resort (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0033%20-%20The%20Legend%20of%20Zelda%20Ocarina%20of%20Time%203D%20%28U%29.3ds.7z"
        >
          3DS0033 - The Legend of Zelda Ocarina of Time 3D (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0034%20-%20Steel%20Diver%20%28Usa%29.3ds.7z"
        >
          3DS0034 - Steel Diver (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0035%20-%20Nintendogs%20%20%20Cats%20Toy%20Poodle%20%26%20New%20Friends%20%28U%29.3ds.7z"
        >
          3DS0035 - Nintendogs Cats Toy Poodle &amp; New Friends (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0036%20-%20Tom%20Clancys%20Ghost%20Recon%20-%20Shadow%20Wars%20%28USA%29.3ds.7z"
        >
          3DS0036 - Tom Clancys Ghost Recon - Shadow Wars (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0037%20-%20Super%20Street%20Fighter%20IV%203D%20Edition%20%28U%29.3ds.7z"
        >
          3DS0037 - Super Street Fighter IV 3D Edition (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0038%20-%20BlazBlue%20Continuum%20Shift%20II%20%28Europe%29%20%28%20En%2CJp%2CCor%2CChn%29.3ds.7z"
        >
          3DS0038 - BlazBlue Continuum Shift II (Europe) ( En,Jp,Cor,Chn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0039%20-%20Cave%20Story%203D%20%28U%29.3ds.7z"
        >
          3DS0039 - Cave Story 3D (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0040%20-%20Mario%20Kart%207%20%28U%29.3ds.7z"
        >
          3DS0040 - Mario Kart 7 (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0041%20-%20Super%20Mario%203D%20Land%20%28U%29.3ds.7z"
        >
          3DS0041 - Super Mario 3D Land (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0042%20-%20Super%20Mon_%20Ball%203D%20%28U%29.3ds.7z"
        >
          3DS0042 - Super Mon_ Ball 3D (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0043%20-%20Sonic%20Generations%20%28U%29.3ds.7z"
        >
          3DS0043 - Sonic Generations (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0044%20-%20Star%20Fox%2064%203D%20%28U%29.3ds.7z"
        >
          3DS0044 - Star Fox 64 3D (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0045%20-%20Shinobi%20%28U%29.3ds.7z"
        >
          3DS0045 - Shinobi (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0046%20-%20Bit.T_%20Saga%20%28U%29.3ds.7z"
        >
          3DS0046 - Bit.T_ Saga (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0047%20-%20SpongeBob%20SquigglePants%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0047 - SpongeBob SquigglePants (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0048%20-%20Captain%20America%20Super%20Soldier%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0048 - Captain America Super Soldier (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0049%20-%20Pro%20Evolution%20Soccer%202011%20%28Europe%29%20%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0049 - Pro Evolution Soccer 2011 (Europe) (En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0050%20-%20Harvest%20Moon%203D%20The%20Tale%20of%20Two%20Towns%20%28Usa%29.3ds.7z"
        >
          3DS0050 - Harvest Moon 3D The Tale of Two Towns (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0051%20-%20Dead%20or%20Alive%20Dimensions%20%28Usa%29.3ds.7z"
        >
          3DS0051 - Dead or Alive Dimensions (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0052%20-%20The%20Adventures%20of%20Tintin%20-%20The%20Secret%20of%20the%20Unicorn%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDan%2CFin%2CSue%2CNor%2CCat%29.3ds.7z"
        >
          3DS0052 - The Adventures of Tintin - The Secret of the
          Unicorn(Europe)(En,Fr,Ge,It,Es,Nl,Dan,Fin,Sue,Nor,Cat).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0053%20-%20Shin%20Megami%20Tensei%20Devil%20Survivor%20Overclocked%20%28Usa%29.3ds.7z"
        >
          3DS0053 - Shin Megami Tensei Devil Survivor Overclocked (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0054%20-%20Tetris%20Axis%20%28Usa%29.3ds.7z"
        >
          3DS0054 - Tetris Axis (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0055%20-%20Nano%20Assault%20%28U%29.3ds.7z"
        >
          3DS0055 - Nano Assault (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0056%20-%20Shinobi%20%28Europe%29%20%28%20En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0056 - Shinobi (Europe) ( En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0057%20-%20Naruto%20Shippuden%203D%20-%20The%20New%20Era%20Europe%20%28Fr%2C%20It%29.3ds.7z"
        >
          3DS0057 - Naruto Shippuden 3D - The New Era Europe (Fr, It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0058%20-%20Sudoku%20-%20The%20Puzzle%20Game%20Collection%20%28Europe%20%29%28%20En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0058 - Sudoku - The Puzzle Game Collection (Europe )(
          En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0059%20-%20Combat%20of%20Giants%20-%20Dinosaurs%203D%20%28Europe%29%20%28%20En%2CFr%2CGe%2CIt%2CEs%2CNl%2CSw%2CNor%2CDan%29.3ds.7z"
        >
          3DS0059 - Combat of Giants - Dinosaurs 3D (Europe) (
          En,Fr,Ge,It,Es,Nl,Sw,Nor,Dan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0060%20-%20Zoo%20Resort%203d%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0060 - Zoo Resort 3d (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0061%20-%20Blazblue%20-%20Continuum%20Shift%20II%20%28Usa%29.3ds.7z"
        >
          3DS0061 - Blazblue - Continuum Shift II (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0062%20-%20Transformers%20Dark%20of%20the%20Moon%20Stealth%20Force%20Edition%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0062 - Transformers Dark of the Moon Stealth Force Edition (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0063%20-%20Ace%20Combat%20Assault%20Horizon%20Legacy%20%28Usa%29.3ds.7z"
        >
          3DS0063 - Ace Combat Assault Horizon Legacy (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0064%20-%20Layton%20Kyouju%20to%20Kiseki%20no%20Kamen%20%28Japan%29.3ds.7z"
        >
          3DS0064 - Layton Kyouju to Kiseki no Kamen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0065%20-%20Super%20Street%20Fighter%20IV%20-%203D%20Edition%20%28Japan%29.3ds.7z"
        >
          3DS0065 - Super Street Fighter IV - 3D Edition (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0066%20-%20F1%202011%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0066 - F1 2011 (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0067%20-%20Ace%20Combat%20Assault%20Horizon%20Legacy%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0067 - Ace Combat Assault Horizon Legacy
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0068%20-%20DualPenSports%20%28%20Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0068 - DualPenSports ( Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0069%20-%20Mahjong%20Cub3d%20%28Usa%29.3ds.7z"
        >
          3DS0069 - Mahjong Cub3d (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0070%20-%20Pokemon%20Rumble%20Blast%20%28Usa%29.3ds.7z"
        >
          3DS0070 - Pokemon Rumble Blast (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0071%20-%20The%20Sims%203%20Pets%20%28USA%29.3ds.7z"
        >
          3DS0071 - The Sims 3 Pets (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0072%20-%20LEGO%20Harry%20Potter%20-%20Years%205-7%20%28Usa%29.3ds.7z"
        >
          3DS0072 - LEGO Harry Potter - Years 5-7 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0073%20-%20LEGO%20Pirates%20of%20The%20Caribbean%20The%20Video%20Game%20%28Usa%29.3ds.7z"
        >
          3DS0073 - LEGO Pirates of The Caribbean The Video Game (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0074%20-%20Cartoon%20Network%20-%20Punch%20Time%20Explosion%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0074 - Cartoon Network - Punch Time Explosion (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0075%20-%20LEGO%20Star%20Wars%20III%20-%20The%20Clone%20Wars%20%28Usa%29.3ds.7z"
        >
          3DS0075 - LEGO Star Wars III - The Clone Wars (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0076%20-%20WWE%20All%20Stars%20%28Usa%29.3ds.7z"
        >
          3DS0076 - WWE All Stars (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0077%20-%20FIFA%2012%20%28Europe%29%20%28En%2CFr%2CNl%29.3ds.7z"
        >
          3DS0077 - FIFA 12 (Europe) (En,Fr,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0078%20-%20Samurai%20Warriors%20Chronicles%20%28Usa%29.3ds.7z"
        >
          3DS0078 - Samurai Warriors Chronicles (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0079%20-%20Tom%20Clancys%20Splinter%20Cell%203D%20%28Usa%29.3ds.7z"
        >
          3DS0079 - Tom Clancys Splinter Cell 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0080%20-%20Resident%20Evil%20Revelations%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2Cjp%29.3ds.7z"
        >
          3DS0080 - Resident Evil Revelations (Europe)(En,Fr,Ge,It,Es,jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0081%20-%20Fifa%20Soccer%2012%20%28Usa%29.3ds.7z"
        >
          3DS0081 - Fifa Soccer 12 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0082%20-%20Bust-A-Move%20Universe%20%28Usa%29.3ds.7z"
        >
          3DS0082 - Bust-A-Move Universe (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0083%20-%20Wipeout%202%20%28Usa%29.3ds.7z"
        >
          3DS0083 - Wipeout 2 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0084%20-%20Marvel%20Super%20Hero%20Squad%20-%20The%20Infinity%20Gauntlet%20%28Europe%29%20%28En%2CFr%2CIt%2CEs%29.3ds.7z"
        >
          3DS0084 - Marvel Super Hero Squad - The Infinity Gauntlet (Europe)
          (En,Fr,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0085%20-%20Pac-Man%20Party%203D%28Usa%29.3ds.7z"
        >
          3DS0085 - Pac-Man Party 3D(Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0086%20-%20Rabbids%20Travel%20in%20Time%203D%20%28Usa%29.3ds.7z"
        >
          3DS0086 - Rabbids Travel in Time 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0087%20-%20SpongeBob%20SquigglePants%203D%20%28Usa%29.3ds.7z"
        >
          3DS0087 - SpongeBob SquigglePants 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0088%20-%20Rabbids%20Travel%20in%20Time%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CSv%2CNo%2CPo%29.3ds.7z"
        >
          3DS0088 - Rabbids Travel in Time 3D
          (Europe)(En,Fr,Ge,It,Es,Nl,Da,Sv,No,Po).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0089%20-%20WWE%20All%20Stars%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0089 - WWE All Stars (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0090%20-%20Cooking%20Mama%204%20-%20Kitchen%20Magic%20%28Usa%29.3ds.7z"
        >
          3DS0090 - Cooking Mama 4 - Kitchen Magic (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0091%20-%20Michael%20Jackson%20-%20The%20Experience%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0091 - Michael Jackson - The Experience 3D (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0092%20-%20Generator%20Rex%20-%20Agent%20of%20Providence%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0092 - Generator Rex - Agent of Providence (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0093%20-%20Pro%20Evolution%20Soccer%202011%203D%20%28Usa%29.3ds.7z"
        >
          3DS0093 - Pro Evolution Soccer 2011 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0094%20-%20Pro%20Evolution%20Soccer%202012%203D%20%28Usa%29.3ds.7z"
        >
          3DS0094 - Pro Evolution Soccer 2012 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0095%20-%20Dream%20Trigger%203D%20%28Usa%29.3ds.7z"
        >
          3DS0095 - Dream Trigger 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0096%20-%20Nintendogs%20%20%20Cats%20Golden%20Retriever%20%26%20New%20Friends%20%28Usa%29.3ds.7z"
        >
          3DS0096 - Nintendogs Cats Golden Retriever &amp; New Friends
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0097%20-%20Cartoon%20Network%20-%20Punch%20Time%20Explosion%20%28Usa%29.3ds.7z"
        >
          3DS0097 - Cartoon Network - Punch Time Explosion (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0098%20-%20Driver%20Renegade%20%28Usa%29.3ds.7z"
        >
          3DS0098 - Driver Renegade (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0099%20-%20James%20Noirs%20Hollywood%20Crimes%20%28Usa%29.3ds.7z"
        >
          3DS0099 - James Noirs Hollywood Crimes (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0100%20-%20Pac-Man%20%26%20Galaga%20-%20Dimensions%20%28Usa%29.3ds.7z"
        >
          3DS0100 - Pac-Man &amp; Galaga - Dimensions (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0101%20-%20Skylanders%20-%20Spyros%20Adventure%20%28USA%29.3ds.7z"
        >
          3DS0101 - Skylanders - Spyros Adventure (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0102%20-%20James%20Noirs%20Hollywood%20Crimes%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0102 - James Noirs Hollywood Crimes 3D (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0103%20-%20Tekken%203D%20-%20Prime%20Edition%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0103 - Tekken 3D - Prime Edition (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0104%20-%20Mario%20and%20Sonic%20at%20the%20London%202012%20Olympic%20Games%20%28Usa%29.3ds.7z"
        >
          3DS0104 - Mario and Sonic at the London 2012 Olympic Games
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0105%20-%20Tales%20of%20the%20Abyss%20%28Usa%29.3ds.7z"
        >
          3DS0105 - Tales of the Abyss (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0106%20-%20Tekken%203D%20-%20Prime%20Edition%20%28Usa%29.3ds.7z"
        >
          3DS0106 - Tekken 3D - Prime Edition (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0107%20-%20Metal%20Gear%20Solid%203D%20Snake%20Eater%20%28Usa%29.3ds.7z"
        >
          3DS0107 - Metal Gear Solid 3D Snake Eater (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0108%20-%20Resident%20Evil%20Revelations%20%28Usa%29.3ds.7z"
        >
          3DS0108 - Resident Evil Revelations (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0109%20-%20Mario%20And%20Sonic%20at%20the%20London%202012%20Olympic%20Games%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0109 - Mario And Sonic at the London 2012 Olympic Games (Europe)
          (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0110%20-%20Asphalt%203D%20%28Usa%29.3ds.7z"
        >
          3DS0110 - Asphalt 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0111%20-%20Green%20Lantern%20Rise%20of%20the%20Manhunters%20%28Usa%29.3ds.7z"
        >
          3DS0111 - Green Lantern Rise of the Manhunters (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0112%20-%20Happy%20Feet%20Two%20%28Usa%29.3ds.7z"
        >
          3DS0112 - Happy Feet Two (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0113%20-%20Nintendogs%20and%20Cats%20French%20Bulldog%20and%20New%20Friends%20%28Usa%29.3ds.7z"
        >
          3DS0113 - Nintendogs and Cats French Bulldog and New Friends
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0114%20-%20Rayman%203D%20%28Usa%29.3ds.7z"
        >
          3DS0114 - Rayman 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0115%20-%20Resident%20Evil%20The%20Mercenaries%203D%20%28Usa%29.3ds.7z"
        >
          3DS0115 - Resident Evil The Mercenaries 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0116%20-%20DualPenSports%20%28Usa%29.3ds.7z"
        >
          3DS0116 - DualPenSports (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0117%20-%20Need%20for%20Speed%20-%20The%20Run%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0117 - Need for Speed - The Run (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0118%20-%20Cubic%20Ninja%20%28Usa%29.3ds.7z"
        >
          3DS0118 - Cubic Ninja (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0119%20-%20Madden%20NFL%20Football%20%28Usa%29.3ds.7z"
        >
          3DS0119 - Madden NFL Football (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0120%20-%20The%20Sims%203%20%28Usa%29.3ds.7z"
        >
          3DS0120 - The Sims 3 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0121%20-%20Monster%20Hunter%203G%20%28Japan%29.3ds.7z"
        >
          3DS0121 - Monster Hunter 3G (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0122%20-%20Doctor%20Lautrec%20and%20the%20Forgotten%20Knights%20%28Usa%29.3ds.7z"
        >
          3DS0122 - Doctor Lautrec and the Forgotten Knights (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0123%20-%20Nicktoons%20MLB%203D%20%28Usa%29.3ds.7z"
        >
          3DS0123 - Nicktoons MLB 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0124%20-%20Transformers%20Dark%20of%20the%20Moon%20Stealth%20Force%20Edition%20%28Usa%29.3ds.7z"
        >
          3DS0124 - Transformers Dark of the Moon Stealth Force Edition
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0125%20-%20Metal%20Gear%20Solid%20Snake%20Eater%203D%20%28Usa%29.3ds.7z"
        >
          3DS0125 - Metal Gear Solid Snake Eater 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0126%20-%20Reel%20Fishing%203D%20Paradise%20%28Usa%29.3ds.7z"
        >
          3DS0126 - Reel Fishing 3D Paradise (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0127%20-%20Combat%20of%20Giants%20Dinosaurs%203D%20%28Usa%29.3ds.7z"
        >
          3DS0127 - Combat of Giants Dinosaurs 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0128%20-%20Frogger%203D%20%28Usa%29.3ds.7z"
        >
          3DS0128 - Frogger 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0129%20-%20Petz%20Fantasy%203D%20%28Usa%29.3ds.7z"
        >
          3DS0129 - Petz Fantasy 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0130%20-%20Puzzler%20Mind%20Gym%203D%20%28Usa0.3ds.7z"
        >
          3DS0130 - Puzzler Mind Gym 3D (Usa0.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0131%20-%20Thor%20God%20of%20Thunder%20%28Usa%29.3ds.7z"
        >
          3DS0131 - Thor God of Thunder (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0132%20-%20One%20Piece%20Unlimited%20Cruise%20SP%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0132 - One Piece Unlimited Cruise SP
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0133%20-%20Nintendogs%20plus%20Cats%20Golden%20Retriever%20and%20New%20Friends%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0133 - Nintendogs plus Cats Golden Retriever and New Friends
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0134%20-%20Kid%20Icarus%20Uprising%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0134 - Kid Icarus Uprising (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0135%20-%20Deca%20Sports%20Extreme%20%28Usa%29.3ds.7z"
        >
          3DS0135 - Deca Sports Extreme (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0136%20-%20Formula%201%202011%20%28Usa%29.3ds.7z"
        >
          3DS0136 - Formula 1 2011 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0137%20-%20Brunswick%20Pro%20Bowling%20%28Usa%29.3ds.7z"
        >
          3DS0137 - Brunswick Pro Bowling (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0138%20-%20Nikolis%20Pencil%20Puzzle%20%28Usa%29.3ds.7z"
        >
          3DS0138 - Nikolis Pencil Puzzle (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0139%20-%20Pinball%20Hall%20of%20Fame%20-%20The%20Williams%20Collection%20%28Usa%29.3ds.7z"
        >
          3DS0139 - Pinball Hall of Fame - The Williams Collection (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0140%20-%20Samurai%20Warriors%20Chronicles%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0140 - Samurai Warriors Chronicles (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0141%20-%20CRUSH3D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0141 - CRUSH3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0142%20-%20Sports%20Island%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0142 - Sports Island 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0143%20-%20Kid%20Icarus%20Uprising%20%28Usa%29.3ds.7z"
        >
          3DS0143 - Kid Icarus Uprising (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0144%20-%20Crush3D%20%28Usa%29.3ds.7z"
        >
          3DS0144 - Crush3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0145%20-%20Lets%20Ride%21%20Best%20in%20Breed%203D%20%28USA%29.3ds.7z"
        >
          3DS0145 - Lets Ride! Best in Breed 3D (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0146%20-%20Paws%20and%20Claws%20-%20Pampered%20Pets%20Resort%203D%20%28Usa%29.3ds.7z"
        >
          3DS0146 - Paws and Claws - Pampered Pets Resort 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0147%20-%20Sonic%20Generations%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0147 - Sonic Generations (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0148%20-%20Centipede%20Infestation%20%28Usa%29.3ds.7z"
        >
          3DS0148 - Centipede Infestation (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0149%20-%20Carnival%20Games%20Wild%20West%203D%20%28Usa%29.3ds.7z"
        >
          3DS0149 - Carnival Games Wild West 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0150%20-%20Face%20Racers%20Photo%20Finish%20%28Usa%29.3ds.7z"
        >
          3DS0150 - Face Racers Photo Finish (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0151%20-%20Gabrielles%20Ghostly%20Groove%203D%20%28Usa%29.3ds.7z"
        >
          3DS0151 - Gabrielles Ghostly Groove 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0152%20-%20Horses%203D%20%28Usa%29.3ds.7z"
        >
          3DS0152 - Horses 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0153%20-%20JAWS%20Ultimate%20Predator%20%28Usa%29.3ds.7z"
        >
          3DS0153 - JAWS Ultimate Predator (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0154%20-%20SpiderMan%20Edge%20of%20Time%20%28Usa%29.3ds.7z"
        >
          3DS0154 - SpiderMan Edge of Time (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0155%20-%20Ben%2010%20Galactic%20Racing%20%28Usa%29.3ds.7z"
        >
          3DS0155 - Ben 10 Galactic Racing (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0156%20-%20Funky%20Barn%203D%20%28usa%29.3ds.7z"
        >
          3DS0156 - Funky Barn 3D (usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0157%20-%20Marvel%20Super%20Hero%20Squad%20The%20Infinity%20Gauntlet%20%28Usa%29.3ds.7z"
        >
          3DS0157 - Marvel Super Hero Squad The Infinity Gauntlet (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0158%20-%20The%20Oregon%20Trail%20%28Usa%29.3ds.7z"
        >
          3DS0158 - The Oregon Trail (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0159%20-%20Nintendogs%20%20%20Cats%20-%20Toy%20Poodle%20%26%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CNo%2Csv%29.3ds.7z"
        >
          3DS0159 - Nintendogs Cats - Toy Poodle &amp; New Friends (Europe)
          (En,Fr,Ge,It,Es,Nl,Da,No,sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0160%20-%20Cave%20Story%203D%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0160 - Cave Story 3D (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0161%20-%20Captain%20America%20Super%20Soldier%20%28Usa%29.3ds.7z"
        >
          3DS0161 - Captain America Super Soldier (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0162%20-%20Cars%202%20%28Usa%29.3ds.7z"
        >
          3DS0162 - Cars 2 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0163%20-%20Gem%20Smashers%20%28Usa%29.3ds.7z"
        >
          3DS0163 - Gem Smashers (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0164%20-%20Generator%20Rex%20Agent%20of%20Providence%20%28Usa%29.3ds.7z"
        >
          3DS0164 - Generator Rex Agent of Providence (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0165%20-%20Zoo%20Resort%203D%20%28Usa%29.3ds.7z"
        >
          3DS0165 - Zoo Resort 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0166%20-%20Secret%20Mysteries%20in%20London%20%28Europe%29%20%28En%2CFr%2CGe%2CNl%29.3ds.7z"
        >
          3DS0166 - Secret Mysteries in London (Europe) (En,Fr,Ge,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0167%20-%20Cars%202%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0167 - Cars 2 (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0168%20-%20Mahjong%203D%20Warriors%20of%20the%20Emperor%20%28Europe%29%20%28En%2CFr%2CGe%2CES%29.3ds.7z"
        >
          3DS0168 - Mahjong 3D Warriors of the Emperor (Europe)
          (En,Fr,Ge,ES).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0169%20-%20Michael%20Jackson%20The%20Experience%203D%20%28Usa%29.3ds.7z"
        >
          3DS0169 - Michael Jackson The Experience 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0170%20-%20The%20Adventures%20of%20Tintin%20%28Usa%29.3ds.7z"
        >
          3DS0170 - The Adventures of Tintin (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0171%20-%20Imagine%20Fashion%20Designer%20%28Usa%29.3ds.7z"
        >
          3DS0171 - Imagine Fashion Designer (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0172%20-%20DreamWorks%20Super%20Star%20Kartz%20%28Usa%29.3ds.7z"
        >
          3DS0172 - DreamWorks Super Star Kartz (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0173%20-%20Pet%20Zombies%20%28Usa%29.3ds.7z"
        >
          3DS0173 - Pet Zombies (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0174%20-%20The%20Hidden%20%28Usa%29.3ds.7z"
        >
          3DS0174 - The Hidden (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0175%20-%20The%20Sims%203%20Pets%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0175 - The Sims 3 Pets (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0176%20-%20Puzzler%20Mind%20Gym%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CES%29.3ds.7z"
        >
          3DS0176 - Puzzler Mind Gym 3D (Europe) (En,Fr,Ge,It,ES).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0177%20-%20Need%20for%20Speed%20-%20The%20Run%20%28Usa%29.3ds.7z"
        >
          3DS0177 - Need for Speed - The Run (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0178%20-%20NASCAR%20Unleashed%20%28Usa%29.3ds.7z"
        >
          3DS0178 - NASCAR Unleashed (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0179%20-%20Puppies%203D%20%28Usa%29.3ds.7z"
        >
          3DS0179 - Puppies 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0180%20-%20Shifting%20World%20%28Usa%29.3ds.7z"
        >
          3DS0180 - Shifting World (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0181%20-%20Rhythm%20Thief%20and%20the%20Emperors%20Treasure%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0181 - Rhythm Thief and the Emperors Treasure
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0182%20-%20Anglers%20Club%20-%20Ultimate%20Bass%20Fishing%203D%20%28Europe%29%20%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS0182 - Anglers Club - Ultimate Bass Fishing 3D (Europe)
          (En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0183%20-%20NCIS%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0183 - NCIS 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0184%20-%20Gundam%20-%20The%203D%20Battle%20%28Japan%29.3ds.7z"
        >
          3DS0184 - Gundam - The 3D Battle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0185%20-%20Mario%20Tennis%20Open%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPo%2CRu%29.3ds.7z"
        >
          3DS0185 - Mario Tennis Open (Europe) (En,Fr,Ge,It,Es,Nl,Po,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0186%20-%2035%20Classic%20Games%20%28Europe%29%20%28En%2CFr%2CIt%2CEs%29.3ds.7z"
        >
          3DS0186 - 35 Classic Games (Europe) (En,Fr,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0187%20-%20Bit%20T_%20Saga%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0187 - Bit T_ Saga (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0188%20-%20Pac-Man%20and%20Galaga%20Dimensions%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0188 - Pac-Man and Galaga Dimensions
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0189%20-%20Rayman%20Origins%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0189 - Rayman Origins (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0190%20-%20LEGO%20Harry%20Potter%20Years%205-7%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS0190 - LEGO Harry Potter Years 5-7
          (Europe)(En,Fr,Ge,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0191%20-%20Carnival%20Games%20Wild%20West%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0191 - Carnival Games Wild West 3D
          (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0192%20-%20Crash%20Time%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%29.3ds.7z"
        >
          3DS0192 - Crash Time 3D (Europe)(En,Fr,Ge,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0193%20-%20The%20Amazing%20Spider-Man%20%28Europe%29%28Fr%2CGe%29.3ds.7z"
        >
          3DS0193 - The Amazing Spider-Man (Europe)(Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0194%20-%20Heroes%20of%20Ruin%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0194 - Heroes of Ruin (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0195%20-%20Pro%20Evolution%20Soccer%202012%203D%20%28Europe%29%28En%2CNl%2CRu%2CSe%2CTu%29.3ds.7z"
        >
          3DS0195 - Pro Evolution Soccer 2012 3D (Europe)(En,Nl,Ru,Se,Tu).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0196%20-%20Spirit%20Camera%20-%20The%20Cursed%20Memoir%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0196 - Spirit Camera - The Cursed Memoir (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0197%20-%2050%20Classic%20Games%20%28Europe%29%20%28En%2CFr%2CIt%2CEs%29.3ds.7z"
        >
          3DS0197 - 50 Classic Games (Europe) (En,Fr,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0198%20-%20Pets%20Paradise%20Resort%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0198 - Pets Paradise Resort 3D (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0199%20-%20Nintendogs%20%20%20Cats%20-%20French%20Bulldog%20and%20New%20Friends%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPo%2CRu%29.3ds.7z"
        >
          3DS0199 - Nintendogs Cats - French Bulldog and New Friends
          (En,Fr,Ge,It,Es,Nl,Po,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0200%20-%20Pac-Man%20Party%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0200 - Pac-Man Party 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0201%20-%20Kingdom%20Hearts%203D%20-%20Dream%20Drop%20Distance%20%28Europe%29%20%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0201 - Kingdom Hearts 3D - Dream Drop Distance (Europe)
          (En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0202%20-%20Theatrhythm%20Final%20Fantasy%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0202 - Theatrhythm Final Fantasy (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0203%20-%20Dragon%20Quest%20Monsters%20Terry%20no%20Wonderland%203D%20%28Japan%29.3ds.7z"
        >
          3DS0203 - Dragon Quest Monsters Terry no Wonderland 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0204%20-%20Kingdom%20Hearts%203D%20-%20Dream%20Drop%20Distance%20%28Japan%29.3ds.7z"
        >
          3DS0204 - Kingdom Hearts 3D - Dream Drop Distance (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0205%20-%20Slime%20MoriMori%20Dragon%20Quest%203%20-%20Taikaizoku%20to%20Shippo%20Dan%20%28Japan%29.3ds.7z"
        >
          3DS0205 - Slime MoriMori Dragon Quest 3 - Taikaizoku to Shippo Dan
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0206%20-%20Zelda%20no%20Densetsu%20-%20Toki%20no%20Ocarina%203D%20%28Japan%29.3ds.7z"
        >
          3DS0206 - Zelda no Densetsu - Toki no Ocarina 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0207%20-%20New%20Super%20Mario%20Bros.%202%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPo%2CRu%29.3ds.7z"
        >
          3DS0207 - New Super Mario Bros. 2 (Europe)
          (En,Fr,Ge,It,Es,Nl,Po,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0208%20-%20Battleship%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0208 - Battleship (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0209%20-%20Spider-Man%20Aux%20Frontieres%20du%20Temps%20%28F%29.3ds.7z"
        >
          3DS0209 - Spider-Man Aux Frontieres du Temps (F).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0210%20-%20Asphalt%203D%20-%20Nitro%20Racing%20%28Japan%29.3ds.7z"
        >
          3DS0210 - Asphalt 3D - Nitro Racing (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0211%20-%20Mario%20Kart%207%20%28Japan%29.3ds.7z"
        >
          3DS0211 - Mario Kart 7 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0212%20-%20Madagascar%203%20Europes%20Most%20Wanted%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CRu%29.3ds.7z"
        >
          3DS0212 - Madagascar 3 Europes Most Wanted (Europe)
          (En,Fr,Ge,It,Es,Nl,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0213%20-%20LEGO%20Batman%202%20-%20DC%20Super%20Heroes%20%28Europe%29%20%28En%2CFr%29.3ds.7z"
        >
          3DS0213 - LEGO Batman 2 - DC Super Heroes (Europe) (En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0214%20-%20Biohazard%20-%20Revelations%20%28Japan%29.3ds.7z"
        >
          3DS0214 - Biohazard - Revelations (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0215%20-%20Fire%20Emblem%20-%20Kakusei%20%28Japan%29.3ds.7z"
        >
          3DS0215 - Fire Emblem - Kakusei (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0216%20-%20Freakyforms%20Deluxe%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0216 - Freakyforms Deluxe (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0217%20-%20Professeur%20Layton%20et%20le%20Masque%20des%20Miracles%20%28Europe%29%28Fr%29.3ds.7z"
        >
          3DS0217 - Professeur Layton et le Masque des Miracles
          (Europe)(Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0218%20-%20Hatsune%20Miku%20and%20Future%20Stars%20-%20Project%20Mirai%20%28Japan%29.3ds.7z"
        >
          3DS0218 - Hatsune Miku and Future Stars - Project Mirai (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0219%20-%20Project%20X%20Zone%20%28Japan%29.3ds.7z"
        >
          3DS0219 - Project X Zone (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0220%20-%20Sengoku%20Musou%20Chronicle%20%28Japan%29.3ds.7z"
        >
          3DS0220 - Sengoku Musou Chronicle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0221%20-%20Sengoku%20Musou%20Chronicle%202nd%20%28Japan%29.3ds.7z"
        >
          3DS0221 - Sengoku Musou Chronicle 2nd (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0222%20-%20Taiko%20no%20Tatsujin%20-%20Chibi%20Dragon%20to%20Fushigi%20na%20Orb%20%28Japan%29.3ds.7z"
        >
          3DS0222 - Taiko no Tatsujin - Chibi Dragon to Fushigi na Orb
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0223%20-%20Mensa%20Academy%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0223 - Mensa Academy (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0224%20-%20Mario%20wa%20Sonic%20London%20Olympic%20%28Kor%29.3ds.7z"
        >
          3DS0224 - Mario wa Sonic London Olympic (Kor).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0225%20-%20Super%20Mario%203D%20Land%20%28Kor%29.3ds.7z"
        >
          3DS0225 - Super Mario 3D Land (Kor).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0226%20-%20Super%20Mario%203D%20Land%20%28Japan%29.3ds.7z"
        >
          3DS0226 - Super Mario 3D Land (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0227%20-%20Biohazard%20Revelations%20%28Cn%29.3ds.7z"
        >
          3DS0227 - Biohazard Revelations (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0228%20-%20Mario%20Kart%207%20%28Cn%29.3ds.7z"
        >
          3DS0228 - Mario Kart 7 (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0229%20-%20Naruto%20SD%20Powerful%20Shippuden%20%28Cn%29.3ds.7z"
        >
          3DS0229 - Naruto SD Powerful Shippuden (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0230%20-%20The%20Legend%20of%20Zelda%20-%20Ocarina%20of%20Time%203D%28Cn%29.3ds.7z"
        >
          3DS0230 - The Legend of Zelda - Ocarina of Time 3D(Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0231%20-%20Rabbids%20Rumble%20%28Europe%29.3ds.7z"
        >
          3DS0231 - Rabbids Rumble (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0232%20-%20Combat%20of%20Giants%20Dinosaurs%203D%20%28Japan%29.3ds.7z"
        >
          3DS0232 - Combat of Giants Dinosaurs 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0233%20-%20Nano%20Assault%20%28Japan%29.3ds.7z"
        >
          3DS0233 - Nano Assault (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0234%20-%20Senran%20Kagura%20Burst%20-%20Guren%20no%20Shoujotachi%20%28Japan%29.3ds.7z"
        >
          3DS0234 - Senran Kagura Burst - Guren no Shoujotachi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0235%20-%20Tales%20Of%20the%20Abyss%20%28Japan%29.3ds.7z"
        >
          3DS0235 - Tales Of the Abyss (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0236%20-%20Monosugoku%20Nou%20wo%20Kitaeru%205%20funkan%20no%20Oni%20Training%20%28Japan%29.3ds.7z"
        >
          3DS0236 - Monosugoku Nou wo Kitaeru 5 funkan no Oni Training
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0237%20-%20Sekaiju%20no%20Meikyuu%20IV%20Denshou%20no%20Kyoshin%20%28Japan%29.3ds.7z"
        >
          3DS0237 - Sekaiju no Meikyuu IV Denshou no Kyoshin (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0238%20-%20Paper%20Mario%20-%20Sticker%20Star%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0238 - Paper Mario - Sticker Star (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0239%20-%20Luigis%20Mansion%202%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS0239 - Luigis Mansion 2(Europe)(En,Fr,Ge,It,Es,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0240%20-%20Monster%20Hunter%203%20Ultimate%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0240 - Monster Hunter 3 Ultimate (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0241%20-%20Donkey%20Kong%20Country%20Returns%203D%20%28E%29.3ds.7z"
        >
          3DS0241 - Donkey Kong Country Returns 3D (E).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0242%20-%20Blazblue%20-%20Continuum%20Shift%20II%20%28Japan%29.3ds.7z"
        >
          3DS0242 - Blazblue - Continuum Shift II (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0243%20-%20Culdcept%20%28Japan%29.3ds.7z"
        >
          3DS0243 - Culdcept (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0244%20-%20Fire%20Emblem%20-%20Awakening%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0244 - Fire Emblem - Awakening (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0245%20-%20Disney%20Epic%20Mic_%20-%20The%20Power%20of%20Illusion%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPort%29.3ds.7z"
        >
          3DS0245 - Disney Epic Mic_ - The Power of Illusion
          (Europe)(En,Fr,Ge,It,Es,Nl,Port).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0246%20-%20Donkey%20Kong%20Country%20Returns%203D%20%28U%29.3ds.7z"
        >
          3DS0246 - Donkey Kong Country Returns 3D (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0247%20-%20Fire%20Emblem%20-%20Awakening%20%28Usa%29.3ds.7z"
        >
          3DS0247 - Fire Emblem - Awakening (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0248%20-%20Etrian%20Odyssey%20IV%20-%20Legends%20of%20the%20Titan%20%28Usa%29.3ds.7z"
        >
          3DS0248 - Etrian Odyssey IV - Legends of the Titan (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0249%20-%20Pokemon%20Mystery%20Dungeon%20-%20Gates%20to%20Infinity%20%28U%29.3ds.7z"
        >
          3DS0249 - Pokemon Mystery Dungeon - Gates to Infinity (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0250%20-%20Zero%20Escape%20-%20Virtues%20Last%20Reward%20%28USA%29.3ds.7z"
        >
          3DS0250 - Zero Escape - Virtues Last Reward (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0251%20-%20Rhythm%20Thief%20%26%20the%20Emperors%20Treasure%20%28USA%29.3ds.7z"
        >
          3DS0251 - Rhythm Thief &amp; the Emperors Treasure (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0252%20-%20Rayman%20Origins%20%28Usa%29.3ds.7z"
        >
          3DS0252 - Rayman Origins (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0253%20-%20Professor%20Layton%20and%20the%20Miracle%20Mask%20%28Usa%29.3ds.7z"
        >
          3DS0253 - Professor Layton and the Miracle Mask (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0254%20-%20Paper%20Mario%20-%20Sticker%20Star%20%28Usa%29.3ds.7z"
        >
          3DS0254 - Paper Mario - Sticker Star (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0255%20-%20New%20Super%20Mario%20Bros.%202%20%29%28Usa%29.3ds.7z"
        >
          3DS0255 - New Super Mario Bros. 2 )(Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0256%20-%20Monster%20Hunter%203%20Ultimate%20%28Usa%29.3ds.7z"
        >
          3DS0256 - Monster Hunter 3 Ultimate (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0257%20-%20Mario%20Tennis%20Open%20%28Usa%29.3ds.7z"
        >
          3DS0257 - Mario Tennis Open (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0258%20-%20Kingdom%20Hearts%203D%20-%20Dream%20Drop%20Distance%20%28Usa%29.3ds.7z"
        >
          3DS0258 - Kingdom Hearts 3D - Dream Drop Distance (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0259%20-%20Heroes%20of%20Ruin%20%28Usa%29.3ds.7z"
        >
          3DS0259 - Heroes of Ruin (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0260%20-%20Castlevania%20Lords%20of%20Shadow%20-%20Mirror%20of%20Fate%20%28Usa%29.3ds.7z"
        >
          3DS0260 - Castlevania Lords of Shadow - Mirror of Fate (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0261%20-%20Castlevania%20Lords%20of%20Shadow%20-%20Mirror%20of%20Fate%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0261 - Castlevania Lords of Shadow - Mirror of Fate
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0262%20-%20LEGO%20City%20Undercover%20The%20Chase%20Begins%20%28Usa%29.3ds.7z"
        >
          3DS0262 - LEGO City Undercover The Chase Begins (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0263%20-%20Disney%20Epic%20Mic_%20Power%20of%20Illusion%20%28Usa%29.3ds.7z"
        >
          3DS0263 - Disney Epic Mic_ Power of Illusion (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0264%20-%20LEGO%20Batman%202%20DC%20Super%20Heroes%20%28Usa%29.3ds.7z"
        >
          3DS0264 - LEGO Batman 2 DC Super Heroes (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0265%20-%20Code%20of%20Princess%20%28Usa%29.3ds.7z"
        >
          3DS0265 - Code of Princess (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0266%20-%20Harvest%20Moon%203D%20A%20New%20Beginning%20%28Usa%29.3ds.7z"
        >
          3DS0266 - Harvest Moon 3D A New Beginning (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0267%20-%20Riding%20Stables%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CNl%2CDa%2CNo%2CSw%29.3ds.7z"
        >
          3DS0267 - Riding Stables 3D (Europe)(En,Fr,Ge,It,Nl,Da,No,Sw).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0268%20-%20Sherlock%20Holmes%20-%20The%20Mystery%20of%20the%20Frozen%20City%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0268 - Sherlock Holmes - The Mystery of the Frozen City
          (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0269%20-%20Survivor%203D%20-%20The%20Ultimate%20Adventure%20%28Europe%29%28En%2CFr%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0269 - Survivor 3D - The Ultimate Adventure
          (Europe)(En,Fr,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0270%20-%20Animal%20Crossing%20-%20New%20Leaf%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29%28Proper%29.3ds.7z"
        >
          3DS0270 - Animal Crossing - New Leaf
          (Europe)(En,Fr,Ge,It,Es)(Proper).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0271%20-%20Gabrielles%20Ghostly%20Groove%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%29.3ds.7z"
        >
          3DS0271 - Gabrielles Ghostly Groove 3D (Europe) (En,Fr,De,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0272%20-%20Myst%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0272 - Myst (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0273%20-%20New%20Art%20Academy%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS0273 - New Art Academy (Europe)(En,Fr,Ge,It,Es,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0274%20-%20Schlag%20den%20Raab%20-%20Das%202.%20Spiel%20%28Europe%29%28Ge%29.3ds.7z"
        >
          3DS0274 - Schlag den Raab - Das 2. Spiel (Europe)(Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0275%20-%20Project%20X%20Zone%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0275 - Project X Zone (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0276%20-%20Frogger%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0276 - Frogger 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0277%20-%20Ben%2010%20Galactic%20Racing%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0277 - Ben 10 Galactic Racing (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0278%20-%20Imagine%20-%20Fashion%20Designer%20%28Europe%29%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CNo%2CSv%29.3ds.7z"
        >
          3DS0278 - Imagine - Fashion Designer
          (Europe))(En,Fr,Ge,It,Es,Nl,Da,No,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0279%20-%20Monster%204x4%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0279 - Monster 4x4 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0280%20-%20Order%20Up%21%21%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0280 - Order Up!! (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0281%20-%20Pinball%20Hall%20of%20Fame%203D%20-%20The%20Williams%20Collection%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0281 - Pinball Hall of Fame 3D - The Williams Collection
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0282%20-%20Reel%20Fishing%20Paradise%203D%20%28Europe%28En%2CFr%2CGe%2CIt%2CEs%2CPt%29.3ds.7z"
        >
          3DS0282 - Reel Fishing Paradise 3D (Europe(En,Fr,Ge,It,Es,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0283%20-%20RollerCoaster%20Tycoon%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0283 - RollerCoaster Tycoon 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0284%20-%20Shifting%20World%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS0284 - Shifting World (Europe)(En,Fr,Ge,It,Es,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0285%20-%20Transformers%20Prime%20-%20The%20Game%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CSv%29.3ds.7z"
        >
          3DS0285 - Transformers Prime - The Game
          (Europe)(En,Fr,Ge,It,Es,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0286%20-%20Winter%20Sports%202012%20-%20Feel%20the%20Spirit%20%28Europe%29%28En%2CFr%2CGe%2CIt%29.3ds.7z"
        >
          3DS0286 - Winter Sports 2012 - Feel the Spirit
          (Europe)(En,Fr,Ge,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0287%20-%20Word%20Wizard%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0287 - Word Wizard 3D (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0288%20-%20The%20Croods%20-%20Prehistoric%20Party%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0288 - The Croods - Prehistoric Party
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0289%20-%20LEGO%20Legends%20of%20Chima%20-%20Lavals%20Journey%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0289 - LEGO Legends of Chima - Lavals Journey (Europe)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0290%20-%20Professor%20Layton%20und%20die%20Maske%20der%20Wunder%20%28Europe%29%28Ge%29.3ds.7z"
        >
          3DS0290 - Professor Layton und die Maske der Wunder
          (Europe)(Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0291%20-%20Zero%20Escape%20-%20Virtues%20Last%20Reward%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0291 - Zero Escape - Virtues Last Reward (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0292%20-%20Sonic%20%26%20All-Stars%20Racing%20Transformed%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0292 - Sonic &amp; All-Stars Racing Transformed
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0293%20-%20Happy%20Feet%20Two%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0293 - Happy Feet Two (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0294%20-%20Naruto%20Shippuden%203D%20-%20The%20New%20Era%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0294 - Naruto Shippuden 3D - The New Era
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0295%20-%20Mario%20%26%20Luigi%20-%20Dream%20Team%20Bros.%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS0295 - Mario &amp; Luigi - Dream Team Bros.
          (Europe)(En,Fr,Ge,It,Es,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0296%20-%20My%20Foal%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0296 - My Foal 3D (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0297%20-%20Cats%20%26%20Dogs%20Pets%20At%20Play%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0297 - Cats &amp; Dogs Pets At Play (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0298%20-LEGO%20The%20Lord%20of%20the%20Rings%20%28Usa%29%20.3ds.7z"
        >
          3DS0298 -LEGO The Lord of the Rings (Usa) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0299%20-%20Rabbids%20Rumble%20%28Usa%29.3ds.7z"
        >
          3DS0299 - Rabbids Rumble (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0300%20-%20Transformers%20Prime%20-%20The%20Game%20%28Usa%29.3ds.7z"
        >
          3DS0300 - Transformers Prime - The Game (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0301%20-%20Funky%20Barn%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt.Es%29.3ds.7z"
        >
          3DS0301 - Funky Barn 3D (Europe)(En,Fr,Ge,It.Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0302%20-%20LEGO%20The%20Lord%20of%20the%20Rings%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS0302 - LEGO The Lord of the Rings
          (Europe)(En,Fr,Ge,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0303%20-%20Spider-Man%20Edge%20of%20Time%20%28Europe%29%28En%2CGe%2CIt%2CEs%29%29.3ds.7z"
        >
          3DS0303 - Spider-Man Edge of Time (Europe)(En,Ge,It,Es)).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0304%20-%20DreamWorks%20Super%20Star%20Kartz%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CSv%29.3ds.7z"
        >
          3DS0304 - DreamWorks Super Star Kartz (Europe)
          (En,Fr,Ge,It,Es,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0305%20-%20Hotel%20Transylvania%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0305 - Hotel Transylvania (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0306%20-%20Monster%20High%20-%20Skultimate%20Roller%20Maze%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CFi%2CNo%2CSv%29.3ds.7z"
        >
          3DS0306 - Monster High - Skultimate Roller Maze
          (Europe)(En,Fr,Ge,It,Es,Nl,Da,Fi,No,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0307%20-%20Pippi%20Longstocking%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0307 - Pippi Longstocking 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0308%20-%20Ice%20Age%20Continental%20Drift%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0308 - Ice Age Continental Drift (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0309%20-%20Angry%20Birds%20Trilogy%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0309 - Angry Birds Trilogy (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0310%20-%20Shin%20Megami%20Tensei%20-%20Devil%20Survivor%20Overclocked%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0310 - Shin Megami Tensei - Devil Survivor Overclocked
          (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0311%20-%20Jewel%20Master%20-%20Atlantis%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0311 - Jewel Master - Atlantis 3D
          (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0312%20-%20Arcade%20Classics%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0312 - Arcade Classics 3D (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0313%20-%20Bella%20Sara%20-%20The%20Magical%20Horse%20Adventures%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CFi%2CNo%2CPt%2CSv.3ds.7z"
        >
          3DS0313 - Bella Sara - The Magical Horse Adventures
          (Europe)(En,Fr,Ge,It,Es,Nl,Da,Fi,No,Pt,Sv.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0314%20-%20Gummy%20Bears%20Magical%20Medallion%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0314 - Gummy Bears Magical Medallion
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0315%20-%20Spy%20Hunter%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0315 - Spy Hunter (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0316%20-%20Puppies%20World%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0316 - Puppies World 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0317%20-%20Imagine%20-%20Babies%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0317 - Imagine - Babies 3D (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0318%20-%20Imagine%20-%20Champion%20Rider%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CNo%2CSw%29.3ds.7z"
        >
          3DS0318 - Imagine - Champion Rider 3D
          (Europe)(En,Fr,Ge,It,Es,Nl,Da,No,Sw).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0319%20-%20Imagine%20-%20Fashion%20World%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CNo%2CSw%29.3ds.7z"
        >
          3DS0319 - Imagine - Fashion World 3D
          (Europe)(En,Fr,Ge,It,Es,Nl,Da,No,Sw).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0320%20-%20Naruto%20Powerful%20Shippuden%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0320 - Naruto Powerful Shippuden (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0321%20-%20Pro%20Evolution%20Soccer%202012%203D%20%28Europe%29%28Fr%2CGe%29.3ds.7z"
        >
          3DS0321 - Pro Evolution Soccer 2012 3D (Europe)(Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0322%20-%20Pro%20Evolution%20Soccer%202013%203D%20%28Europe%29%28Fr%2CGe%29.3ds.7z"
        >
          3DS0322 - Pro Evolution Soccer 2013 3D (Europe)(Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0323%20-%20Azada%20%28Europe%29%28En%2CFr%2CGe%2CNl%29.3ds.7z"
        >
          3DS0323 - Azada (Europe)(En,Fr,Ge,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0324%20-%20LEGO%20City%20Undercover%20-%20The%20Chase%20Begins%20Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CPt%2CRu%29.3ds.7z"
        >
          3DS0324 - LEGO City Undercover - The Chase Begins
          Europe)(En,Fr,Ge,It,Es,Nl,Da,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0325%20-%20LEGO%20Batman%202%20-%20DC%20Super%20Heroes%20%28Europe%29%28En%2CGe%29.3ds.7z"
        >
          3DS0325 - LEGO Batman 2 - DC Super Heroes (Europe)(En,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0326%20-%20Pokemon%20Mystery%20Dungeon%20-%20Gates%20to%20Infinity%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0326 - Pokemon Mystery Dungeon - Gates to Infinity (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0327%20-%20Petz%20Fantasy%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CNo%2CSw%29%29.3ds.7z"
        >
          3DS0327 - Petz Fantasy 3D (Europe)(En,Fr,Ge,It,Es,Nl,Da,No,Sw)).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0328%20-%20Farming%20Simulator%202012%203D%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0328 - Farming Simulator 2012 3D (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0329%20-%20Mahjong%20Mysteries%20Ancient%20Athena%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0329 - Mahjong Mysteries Ancient Athena 3D
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0330%20-%20Junior%20Games%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0330 - Junior Games 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0331%20-%20Jewel%20Master%20Cradle%20of%20Rome%202%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0331 - Jewel Master Cradle of Rome 2
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0332%20-%20Bowling%20Bonanza%203D%20%28Europe%29%28En%2CFr%2CGe%2Cit%2CEs%29.3ds.7z"
        >
          3DS0332 - Bowling Bonanza 3D (Europe)(En,Fr,Ge,it,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0333%20-%20My%20Riding%20Stables%203D%20Jumping%20for%20the%20Team%20%28Europe%29%28En%2CFr%2CGe%2CEs%2CNl%29.3ds.7z"
        >
          3DS0333 - My Riding Stables 3D Jumping for the Team
          (Europe)(En,Fr,Ge,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0334%20-%20Rise%20of%20the%20Guardians%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0334 - Rise of the Guardians (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0335%20-%20Ben%2010%20-%20Omniverse%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CRu%29.3ds.7z"
        >
          3DS0335 - Ben 10 - Omniverse (Europe)(En,Fr,Ge,It,Es,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0336%20-%20Profesor%20Layton%20y%20la%20Mascara%20de%20los%20Prodigios%2C%20El%20%28Spain%29.3ds.7z"
        >
          3DS0336 - Profesor Layton y la Mascara de los Prodigios, El
          (Spain).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0337%20-%20Harvest%20Moon%203D%20-%20The%20Tale%20of%20Two%20Towns%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0337 - Harvest Moon 3D - The Tale of Two Towns
          (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0338%20-%20Il%20Layton%20e%20la%20maschera%20dei%20Miracoli%20%28europe%29%28It%29.3ds.7z"
        >
          3DS0338 - Il Layton e la maschera dei Miracoli (europe)(It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0339%20-%20Disney%20Planes%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0339 - Disney Planes (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0340%20-%20Fast%20%26%20Furious%20Showdown%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0340 - Fast &amp; Furious Showdown (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0341%20-%20Disney%20Princess%20My%20Fairytale%20Adventure%20%28Europe%29%28Fr%2CGe%2CIt%29.3ds.7z"
        >
          3DS0341 - Disney Princess My Fairytale Adventure
          (Europe)(Fr,Ge,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0342%20-%2050%20Classic%20Games%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0342 - 50 Classic Games 3D (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0343%20-%20One%20Piece%20Unlimited%20Cruise%20SP2%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0343 - One Piece Unlimited Cruise SP2
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0344%20-%20Theatrhythm%20Final%20Fantasy%20%28Usa%29.3ds.7z"
        >
          3DS0344 - Theatrhythm Final Fantasy (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0345%20-%20Scribblenauts%20Unlimited%20%28Usa%29.3ds.7z"
        >
          3DS0345 - Scribblenauts Unlimited (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0346%20-%20Luigis%20Mansion%20Dark%20Moon%20%28Usa%29.3ds.7z"
        >
          3DS0346 - Luigis Mansion Dark Moon (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0347%20-%20Nintendogs%20Plus%20Cats%20-%20Toy%20Poodle%20And%20New%20Friends%20%28Japan%29.3ds.7z"
        >
          3DS0347 - Nintendogs Plus Cats - Toy Poodle And New Friends
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0348%20-%20Biohazard%20-%20The%20Mercenaries%203D%20%28Japan%29%20.3ds.7z"
        >
          3DS0348 - Biohazard - The Mercenaries 3D (Japan) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0349%20-%20Theatrhythm%20Final%20Fantasy%20%28Japan%29.3ds.7z"
        >
          3DS0349 - Theatrhythm Final Fantasy (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0350%20-%20Shin%20Hikari%20Shinwa%20-%20Parutena%20no%20Kagami%20%28Japan%29%20.3ds.7z"
        >
          3DS0350 - Shin Hikari Shinwa - Parutena no Kagami (Japan) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0351%20-%20My%20Western%20Horse%203D%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0351 - My Western Horse 3D (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0352%20-%20Viking%20Invasion%202%20-%20Tower%20Defence%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0352 - Viking Invasion 2 - Tower Defence
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0353%20-%20Tobidase%20Doubutsu%20no%20Mori%20%28Japan%29.3ds.7z"
        >
          3DS0353 - Tobidase Doubutsu no Mori (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0354%20-%20Gyakuten%20Saiban%205%20%28Japan%29.3ds.7z"
        >
          3DS0354 - Gyakuten Saiban 5 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0355%20-%20Bravely%20Default%20Flying%20Fairy%20%28Japan%29.3ds.7z"
        >
          3DS0355 - Bravely Default Flying Fairy (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0356%20-%20AKB48%20Me%20%28Japan%29.3ds.7z"
        >
          3DS0356 - AKB48 Me (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0357%20-%20New%20Love%20Plus%20%28Japan%29.3ds.7z"
        >
          3DS0357 - New Love Plus (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0358%20-%20Castlevania%20Lords%20of%20Shadow%20-%20Sadame%20no%20Makyou%20%28Japan%29.3ds.7z"
        >
          3DS0358 - Castlevania Lords of Shadow - Sadame no Makyou
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0359%20-%20Super%20Robot%20Taisen%20UX%20%28Japan%29.3ds.7z"
        >
          3DS0359 - Super Robot Taisen UX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0360%20-%20Dragon%20Quest%20VII%20Eden%20no%20Sen_achi%20%28Japan%29.3ds.7z"
        >
          3DS0360 - Dragon Quest VII Eden no Sen_achi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0361%20-%20Fantasy%20Life%20%28Japan%29.3ds.7z"
        >
          3DS0361 - Fantasy Life (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0362%20-%20Pokemon%20no%20Fushigi%20no%20Dungeon%20Magnagate%20to%20Mugendai%20no%20Meikyuu%20%28Japan%29.3ds.7z"
        >
          3DS0362 - Pokemon no Fushigi no Dungeon Magnagate to Mugendai no
          Meikyuu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0363%20-%20Labyrinth%20no%20Kanata%20%28Japan%29.3ds.7z"
        >
          3DS0363 - Labyrinth no Kanata (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0364%20-%20Ken%20to%20Mahou%20to%20Gakuen%20Mono.%203D%20%28Japan%29.3ds.7z"
        >
          3DS0364 - Ken to Mahou to Gakuen Mono. 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0365%20-%20Bokujou%20Monogatari%20Hajimari%20no%20Daichi%20%28Japan%29.3ds.7z"
        >
          3DS0365 - Bokujou Monogatari Hajimari no Daichi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0366%20-%20Naruto%20Shippuden%20Shinobi%20Rittai%20Emaki%21%20Saikyou%20Ninkai%20Kessen%21%21%20%28Japan%29.3ds.7z"
        >
          3DS0366 - Naruto Shippuden Shinobi Rittai Emaki! Saikyou Ninkai
          Kessen!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0367%20-%20Luigis%20Mansion%202%20%28Japan%29.3ds.7z"
        >
          3DS0367 - Luigis Mansion 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0368%20-%20Super%20Pokemon%20Scramble%20%28Japan%29.3ds.7z"
        >
          3DS0368 - Super Pokemon Scramble (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0369%20-%20Steel%20Diver%20%28Japan%29.3ds.7z"
        >
          3DS0369 - Steel Diver (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0370%20-%20Cubic%20Ninja%20%28Japan%29.3ds.7z"
        >
          3DS0370 - Cubic Ninja (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0371%20-%20Rabbits%20Time%20Travel%20%28Japan%29.3ds.7z"
        >
          3DS0371 - Rabbits Time Travel (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0372%20-%20Touch%21%20Double%20Pen%20Sports%20%28Japan%29.3ds.7z"
        >
          3DS0372 - Touch! Double Pen Sports (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0373%20-%20Shin%20Megami%20Tensei%20IV%20%28Cn%29.3ds.7z"
        >
          3DS0373 - Shin Megami Tensei IV (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0374%20-%20Nintendogs%20%20%20Cats%20-%20French%20Bulldog%20and%20New%20Friends%20%28Cn%29.3ds.7z"
        >
          3DS0374 - Nintendogs Cats - French Bulldog and New Friends (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0375%20-%20Nintendogs%20%20%20Cats%20-%20Shiba%20and%20New%20Friends%20%28Cn%29.3ds.7z"
        >
          3DS0375 - Nintendogs Cats - Shiba and New Friends (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0376%20-%20Nintendogs%20%20%20Cats%20-%20Toy%20Poodle%20%26%20New%20Friends%20%28Cn%29.3ds.7z"
        >
          3DS0376 - Nintendogs Cats - Toy Poodle &amp; New Friends (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0377%20-%20Mario%20Tennis%20Open%20%28Cn%29.3ds.7z"
        >
          3DS0377 - Mario Tennis Open (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0378%20-%20New%20Super%20Mario%20Brothers%202%20%28Cn%29.3ds.7z"
        >
          3DS0378 - New Super Mario Brothers 2 (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0379%20-%20Super%20Mario%203D%20Land%20%28Cn%29.3ds.7z"
        >
          3DS0379 - Super Mario 3D Land (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0380%20-%20StarFox%2064%203D%20%28Cn%29.3ds.7z"
        >
          3DS0380 - StarFox 64 3D (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0381%20-%20Tohoku%20Daigaku%20Karei%20Igaku%20Kenkyuusho%20-%20Monosugoku%20Nou%20wo%20Kitaeru%205-Funkan%20no%20Oni%20Training%20%28Cn%29.3ds.7z"
        >
          3DS0381 - Tohoku Daigaku Karei Igaku Kenkyuusho - Monosugoku Nou wo
          Kitaeru 5-Funkan no Oni Training (Cn).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0382%20-%20Luigis%20Mansion%202%20%28Taiwan%29.3ds.7z"
        >
          3DS0382 - Luigis Mansion 2 (Taiwan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0383%20-%20Etrian%20Odyssey%20IV%20Legends%20of%20the%20Titan%20%28Europe%29%28En%2CGe%29.3ds.7z"
        >
          3DS0383 - Etrian Odyssey IV Legends of the Titan
          (Europe)(En,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0384%20-%20Purr%20Pals%20Purrfection%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0384 - Purr Pals Purrfection (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0385%20-%20Rune%20Factory%204%20%28Japan%29.3ds.7z"
        >
          3DS0385 - Rune Factory 4 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0386%20-%20Code%20of%20Princess%20%28Japan%29.3ds.7z"
        >
          3DS0386 - Code of Princess (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0387%20-%20Crayon%20Shin-Chan%20-%20Uchuu%20de%20Achoo%21%20Yuujou%20no%20Oba-Karate%21%21%20%28Japan%29%20.3ds.7z"
        >
          3DS0387 - Crayon Shin-Chan - Uchuu de Achoo! Yuujou no Oba-Karate!!
          (Japan) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0388%20-%20Doctor%20Lautrec%20to%20Boukyaku%20no%20Kishidan%20%28Japan%29.3ds.7z"
        >
          3DS0388 - Doctor Lautrec to Boukyaku no Kishidan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0389%20-%20E.X.%20Troopers%20%28Japan%29.3ds.7z"
        >
          3DS0389 - E.X. Troopers (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0390%20-%20Inazuma%20Eleven%20Go%20-%20Shine%20%28Japan%29.3ds.7z"
        >
          3DS0390 - Inazuma Eleven Go - Shine (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0391%20-%20Inazuma%20Eleven%20Go%202%20-%20Chrono%20Stone%20-%20Neppuu%20%28Japan%29.3ds.7z"
        >
          3DS0391 - Inazuma Eleven Go 2 - Chrono Stone - Neppuu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0392%20-%20One%20Piece%20-%20Unlimited%20Cruise%20SP%20%28Japan%29.3ds.7z"
        >
          3DS0392 - One Piece - Unlimited Cruise SP (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0393%20-%20Layton%20Kyouju%20to%20Choubunmei%20A%20no%20Isan%20%28Japan%29.3ds.7z"
        >
          3DS0393 - Layton Kyouju to Choubunmei A no Isan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0394%20-%20SD%20Gundam%20G%20Generation%203D%20%28Japan%29.3ds.7z"
        >
          3DS0394 - SD Gundam G Generation 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0395%20-%20Shin%20Sangoku%20Musou%20VS%20%28Japan%29.3ds.7z"
        >
          3DS0395 - Shin Sangoku Musou VS (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0396%20-%20Time%20Travelers%20%28Japan%29.3ds.7z"
        >
          3DS0396 - Time Travelers (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0397%20-%20Dead%20or%20Alive%20-%20Dimensions%20%28Japan%29.3ds.7z"
        >
          3DS0397 - Dead or Alive - Dimensions (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0398%20-%20Deca%20Sporta%20-%203D%20Sports%20%28Japan%29.3ds.7z"
        >
          3DS0398 - Deca Sporta - 3D Sports (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0399%20-%20Guild01%20%28Japan%29.3ds.7z"
        >
          3DS0399 - Guild01 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0400%20-%203D%20Game%20Collection%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0400 - 3D Game Collection (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0401%20-%20Puzzler%20-%20Brain%20Games%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0401 - Puzzler - Brain Games (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0402%20-%20Puzzler%20-%20World%202012%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0402 - Puzzler - World 2012 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0403%20-%20FIFA%2013%20%28Europe%29%28En%2CFr%2CNl%29.3ds.7z"
        >
          3DS0403 - FIFA 13 (Europe)(En,Fr,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0404%20-%20Animal%20Hospital%20%28Europe%29%28En%2CGe%29.3ds.7z"
        >
          3DS0404 - Animal Hospital (Europe)(En,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0405%20-%20Pac-Man%20%26%20Galaga%20Dimensions%20%28Japan%29.3ds.7z"
        >
          3DS0405 - Pac-Man &amp; Galaga Dimensions (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0406%20-%20Nicola%20Kanshuu%20-%20Model%20Oshare%20Audition%202%20%28Japan%29.3ds.7z"
        >
          3DS0406 - Nicola Kanshuu - Model Oshare Audition 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0407%20-%20Nikoli%20no%20Sudoku%203D%20-%208-tsu%20no%20Puzzle%20de%201000-mon%20%28Japan%29.3ds.7z"
        >
          3DS0407 - Nikoli no Sudoku 3D - 8-tsu no Puzzle de 1000-mon
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0408%20-%20Around%20the%20World%20with%20Hello%20Kitty%20and%20Friends%20%28Europe%29%20%28En%2CFr%2CGe%2CEs%2CIt%29%20.3ds.7z"
        >
          3DS0408 - Around the World with Hello Kitty and Friends (Europe)
          (En,Fr,Ge,Es,It) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0409%20-%20Monster%20Hunter%204%20%28Japan%29.3ds.7z"
        >
          3DS0409 - Monster Hunter 4 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0410%20-%203D%20MahJongg%20%28Europe%29%28En%2CFr%2CGe%2CNl%7D.3ds.7z"
        >
          3DS0410 - 3D MahJongg (Europe)(En,Fr,Ge,Nl}.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0411%20-%20Jewel%20Master%20-%20Cradle%20of%20Egypt%202%203D%20%28Europe%29%28En%2CFr%2CIt%2CEs%29.3ds.7z"
        >
          3DS0411 - Jewel Master - Cradle of Egypt 2 3D
          (Europe)(En,Fr,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0412%20-%20Gardenscapes%20%28Europe%29%28En%2CFr%2CGe%2CNl%29.3ds.7z"
        >
          3DS0412 - Gardenscapes (Europe)(En,Fr,Ge,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0413%20-%20LEGO%20The%20Lord%20of%20the%20Rings%20%28Europe%29%28En%2CFr%2CGr%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS0413 - LEGO The Lord of the Rings
          (Europe)(En,Fr,Gr,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0414%20-%20Shin%20Megami%20Tensei%20IV%203DS%20%28Usa%29.3ds.7z"
        >
          3DS0414 - Shin Megami Tensei IV 3DS (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0415%20-%20Boulder%20Dash%20XL%203D%20%28Usa%29.3ds.7z"
        >
          3DS0415 - Boulder Dash XL 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0416%20-%20Style%20Savvy%20Trendsetters%20%28Usa%29.3ds.7z"
        >
          3DS0416 - Style Savvy Trendsetters (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0417%20-%20Real%20Heroes%20-%20Firefighter%203D%20%28Usa%29.3ds.7z"
        >
          3DS0417 - Real Heroes - Firefighter 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0418%20-%20Brain%20Age%20Concentration%20Training%20%28Usa%29.3ds.7z"
        >
          3DS0418 - Brain Age Concentration Training (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0419%20-%20Skylanders%20Giants%20%28Usa%29.3ds.7z"
        >
          3DS0419 - Skylanders Giants (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0420%20-%20Animal%20Resort%20-%20Dobutsuen%20o%20Tsukurou%21%21%20%28Japan%29.3ds.7z"
        >
          3DS0420 - Animal Resort - Dobutsuen o Tsukurou!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0421%20-%20Yuugen%20Gaisha%20-%20Brave%20Company%20%28Japan%29.3ds.7z"
        >
          3DS0421 - Yuugen Gaisha - Brave Company (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0422%20-%20StarFox%2064%203D%20%28Japan%29.3ds.7z"
        >
          3DS0422 - StarFox 64 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0423%20-%20Real%203D%20Bass%20Fishing%20-%20Fish%20On%20%28Japan%29.3ds.7z"
        >
          3DS0423 - Real 3D Bass Fishing - Fish On (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0424%20-%20Pro%20Yakyuu%20Famista%202011%20%28Japan%29.3ds.7z"
        >
          3DS0424 - Pro Yakyuu Famista 2011 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0425%20-%20Minna%20no%20Ennichi%20%28Japan%29.3ds.7z"
        >
          3DS0425 - Minna no Ennichi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0426%20-%20Hana%20to%20Ikimono%20Rittai%20Zukan%20%28Japan%29.3ds.7z"
        >
          3DS0426 - Hana to Ikimono Rittai Zukan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0427%20-%20Heavy%20Fire%20-%20The%20Chosen%20Few%203D%20%28Usa%29.3ds.7z"
        >
          3DS0427 - Heavy Fire - The Chosen Few 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0428%20-%20Turbo%20-%20Super%20Stunt%20Squad%20%28Usa%29.3ds.7z"
        >
          3DS0428 - Turbo - Super Stunt Squad (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0429%20-%20Shin%20Megami%20Tensei%20-%20Devil%20Summoner%20-%20Soul%20Hackers%20%28Usa%29.3ds.7z"
        >
          3DS0429 - Shin Megami Tensei - Devil Summoner - Soul Hackers
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0430%20-%20Kokuga%20%28Japan%29.3ds.7z"
        >
          3DS0430 - Kokuga (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0431%20-%20Shinobi%203D%20%28Japan%29.3ds.7z"
        >
          3DS0431 - Shinobi 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0432%20-%20My%20Pet%20Puppy%203D%20%28Usa%29.3ds.7z"
        >
          3DS0432 - My Pet Puppy 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0433%20-%20Spirit%20Camera%20The%20Cursed%20Memoir%20%28Usa%29.3ds.7z"
        >
          3DS0433 - Spirit Camera The Cursed Memoir (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0434%20-%20Etrian%20Odyssey%20Untold%20-%20The%20Millennium%20Girl%20%28Usa%29.3ds.7z"
        >
          3DS0434 - Etrian Odyssey Untold - The Millennium Girl (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0435%20-%20Alien%20Chaos%203D%20%28Usa%29.3ds.7z"
        >
          3DS0435 - Alien Chaos 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0436%20-%20Crosswords%20Plus%20%28Usa%29.3ds.7z"
        >
          3DS0436 - Crosswords Plus (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0437%20-%20Rune%20Factory%204%20%28Usa%29.3ds.7z"
        >
          3DS0437 - Rune Factory 4 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0438%20-%20Monster%204x4%203D%20%28Usa%29.3ds.7z"
        >
          3DS0438 - Monster 4x4 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0439%20-%20Junior%20Classic%20Games%203D%20%28Usa%29.3ds.7z"
        >
          3DS0439 - Junior Classic Games 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0440%20-%20Myst%20%28Usa%29.3ds.7z"
        >
          3DS0440 - Myst (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0441%20-%20Hotel%20Transylvania%20%28Usa%29.3ds.7z"
        >
          3DS0441 - Hotel Transylvania (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0442%20-%20Art%20Academy%20Lessons%20For%20Everyone%20%28Usa%29.3ds.7z"
        >
          3DS0442 - Art Academy Lessons For Everyone (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0443%20-%20The%20Amazing%20Spider-Man%20%28Usa%29.3ds.7z"
        >
          3DS0443 - The Amazing Spider-Man (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0444%20-%20The%20Trash%20Pack%20%28Usa%29.3ds.7z"
        >
          3DS0444 - The Trash Pack (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0445%20-%20Top%20Trumps%20NBA%20All%20Stars%20%28Usa%29.3ds.7z"
        >
          3DS0445 - Top Trumps NBA All Stars (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0446%20-%20Adventure%20Time%20-%20Hey%20Ice%20King%21%20Whyd%20You%20Steal%20Our%20Garbage%21%21%20%28USA%29.3ds.7z"
        >
          3DS0446 - Adventure Time - Hey Ice King! Whyd You Steal Our Garbage!!
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0447%20-%20Hakuoki%20-%20Memories%20of%20the%20Shinsengumi%20%28Usa%29.3ds.7z"
        >
          3DS0447 - Hakuoki - Memories of the Shinsengumi (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0448%20-%20Wreck-It%20Ralph%20%28Usa%29.3ds.7z"
        >
          3DS0448 - Wreck-It Ralph (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0449%20-%20Hot%20Wheels%20-%20Worlds%20Best%20Driver%20%28USA%29.3ds.7z"
        >
          3DS0449 - Hot Wheels - Worlds Best Driver (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0450%20-%20Pokemon%20X%20%28Europe%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS0450 - Pokemon X (Europe) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0451%20-%20Pokemon%20Y%20%28Europe%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS0451 - Pokemon Y (Europe) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0452%20-%20LEGO%20Batman%202%20-%20DC%20Super%20Heroes%20%28Europe%29%28En%2CFr%29.3ds.7z"
        >
          3DS0452 - LEGO Batman 2 - DC Super Heroes (Europe)(En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0453%20-%20Boulder%20Dash-XL%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0453 - Boulder Dash-XL 3D (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0454%20-%20Jewel%20Master%20-%20Cradle%20of%20Rome%202%20%28Europe%29%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS0454 - Jewel Master - Cradle of Rome 2 (Europe)(En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0455%20-%20FIFA%2014%20%28Europe%29%28En%2CFr%2CNl%29.3ds.7z"
        >
          3DS0455 - FIFA 14 (Europe)(En,Fr,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0456%20-%20Pro%20Evolution%20Soccer%202013%203D%20%28Usa%29.3ds.7z"
        >
          3DS0456 - Pro Evolution Soccer 2013 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0457%20-%20Wipeout%203%20%28Usa%29.3ds.7z"
        >
          3DS0457 - Wipeout 3 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0458%20-%20Finding%20Nemo%20-%20Escape%20To%20The%20Big%20Blue%20-%20Special%20Edition%20%28Usa%29.3ds.7z"
        >
          3DS0458 - Finding Nemo - Escape To The Big Blue - Special Edition
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0459%20-%20Disney%20Princess%20-%20My%20Fairytale%20Adventure%20%28Usa%29.3ds.7z"
        >
          3DS0459 - Disney Princess - My Fairytale Adventure (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0460%20-%20Lalaloopsy%20-%20Carnival%20of%20Friends%20%28Usa%29.3ds.7z"
        >
          3DS0460 - Lalaloopsy - Carnival of Friends (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0461%20-%20Scribblenauts%20Unmasked%20-%20A%20DC%20Comics%20Adventure%20%28Usa%29.3ds.7z"
        >
          3DS0461 - Scribblenauts Unmasked - A DC Comics Adventure (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0462%20-%20Wipeout%20-%20Create%20and%20Crash%20%28Usa%29.3ds.7z"
        >
          3DS0462 - Wipeout - Create and Crash (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0463%20-%20Games%20Festival%201%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPt%2CDa%2CFi%2CNo%2CSw%29%2C.3ds.7z"
        >
          3DS0463 - Games Festival 1
          (Europe)(En,Fr,Ge,It,Es,Nl,Pt,Da,Fi,No,Sw),.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0464%20-%20LEGO%20Marvel%20Super%20Heroes%20-%20Universe%20in%20Peril%20%28Usa%29.3ds.7z"
        >
          3DS0464 - LEGO Marvel Super Heroes - Universe in Peril (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0465%20-%20Teenage%20Mutant%20Ninja%20Turtles%20%28Usa%29.3ds.7z"
        >
          3DS0465 - Teenage Mutant Ninja Turtles (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0466%20-%20Imagine%20-%20Fashion%20Life%20%28Usa%29.3ds.7z"
        >
          3DS0466 - Imagine - Fashion Life (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0467%20-%20Ice%20Age%20-%20Continental%20Drift%20-%20Arctic%20Games%20%28Usa%29.3ds.7z"
        >
          3DS0467 - Ice Age - Continental Drift - Arctic Games (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0468%20-%20Batman%20-%20Arkham%20Origins%20Blackgate%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0468 - Batman - Arkham Origins Blackgate
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0469%20-%20Hometown%20Story%20%28Usa%29.3ds.7z"
        >
          3DS0469 - Hometown Story (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0470%20-%20Sonic%20-%20Lost%20World%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0470 - Sonic - Lost World (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0471%20-%20Skylanders%20-%20Swap%20Force%20%28Usa%29.3ds.7z"
        >
          3DS0471 - Skylanders - Swap Force (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0472%20-%20SpongeBob%20SquarePants%20-%20Planktons%20Robotic%20Revenge%20%28USA%29.3ds.7z"
        >
          3DS0472 - SpongeBob SquarePants - Planktons Robotic Revenge
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0473%20-%20Regular%20Show%20-%20Mordecai%20and%20Rigby%20in%208-bit%20Land%20%28Usa%29.3ds.7z"
        >
          3DS0473 - Regular Show - Mordecai and Rigby in 8-bit Land (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0474%20-%20Angry%20Birds%20Star%20Wars%20%28Usa%29.3ds.7z"
        >
          3DS0474 - Angry Birds Star Wars (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0475%20-%20Beyblade%20Evolution%20%28Usa%29.3ds.7z"
        >
          3DS0475 - Beyblade Evolution (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0476%20-%20Inazuma%20Eleven%203%20-%20Bomb%20Blast%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0476 - Inazuma Eleven 3 - Bomb Blast (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0477%20-%20Inazuma%20Eleven%203%20Lightning%20Bolt%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0477 - Inazuma Eleven 3 Lightning Bolt (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0478%20-%20Ben%2010%20-%20Omniverse%202%20%28Usa%29.3ds.7z"
        >
          3DS0478 - Ben 10 - Omniverse 2 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0479%20-%20Monster%20High%20-%2013%20Wishes%20%28Usa%29.3ds.7z"
        >
          3DS0479 - Monster High - 13 Wishes (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0480%20-%20PacMan%20and%20the%20Ghostly%20Adventures%20%28Usa%29.3ds.7z"
        >
          3DS0480 - PacMan and the Ghostly Adventures (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0481%20-%20Power%20Rangers%20-%20Megaforce%20%28Usa%29.3ds.7z"
        >
          3DS0481 - Power Rangers - Megaforce (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0482%20-%20Mahjong%20Mysteries%20-%20Ancient%20Athena%203D%20%28Europe%29%28En%2CFr%2CIt%2CEs%29.3ds.7z"
        >
          3DS0482 - Mahjong Mysteries - Ancient Athena 3D
          (Europe)(En,Fr,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0483%20-%20Secrets%20of%20the%20Titanic%20%28Europe%29%28En%2CFr%2CGe%29.3ds.7z"
        >
          3DS0483 - Secrets of the Titanic (Europe)(En,Fr,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0484%20-%2050%20Classic%20Games%203D%20%28Usa%29.3ds.7z"
        >
          3DS0484 - 50 Classic Games 3D (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0485%20-%20Mario%20%26%20Luigi%20-%20Dream%20Team%20%28Usa%29.3ds.7z"
        >
          3DS0485 - Mario &amp; Luigi - Dream Team (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0486%20-%20Jewel%20Quest%20Mysteries%20-%20The%20Seventh%20Gate%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS0486 - Jewel Quest Mysteries - The Seventh Gate
          (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0487%20-%20The%20Legend%20of%20Zelda%20-%20A%20Link%20Between%20Worlds%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0487 - The Legend of Zelda - A Link Between Worlds
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0488%20-%20Moshi%20Monsters%20-%20Katsuma%20Unleashed%20%28Usa%29.3ds.7z"
        >
          3DS0488 - Moshi Monsters - Katsuma Unleashed (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0489%20-%20Phineas%20%26%20Ferb%20-%20Quest%20for%20Cool%20Stuff%20%28Usa%29.3ds.7z"
        >
          3DS0489 - Phineas &amp; Ferb - Quest for Cool Stuff (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0490%20-%20Adventure%20Time%20-%20Explore%20the%20Dungeon%20Because%20I%20DONT%20KNOW%21%20%28USA%29.3ds.7z"
        >
          3DS0490 - Adventure Time - Explore the Dungeon Because I DONT KNOW!
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0491%20-%20Young%20Justice%20Legacy%20%28Usa%29.3ds.7z"
        >
          3DS0491 - Young Justice Legacy (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0492%20-%20Mario%20Party%20-%20Island%20Tour%20%28Usa%29.3ds.7z"
        >
          3DS0492 - Mario Party - Island Tour (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0493%20-%20Animal%20Crossing%20-%20New%20Leaf%20%28Usa%29.3ds.7z"
        >
          3DS0493 - Animal Crossing - New Leaf (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0494%20-%20The%20Legend%20of%20Zelda%20-%20A%20Link%20Between%20Worlds%20%28Usa%29.3ds.7z"
        >
          3DS0494 - The Legend of Zelda - A Link Between Worlds (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0495%20-%20Crash%20City%20Mayhem%203DS%20%28Usa%29.3ds.7z"
        >
          3DS0495 - Crash City Mayhem 3DS (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0496%20-%20Barbie%20Dreamhouse%20Party%20%28Usa%29.3ds.7z"
        >
          3DS0496 - Barbie Dreamhouse Party (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0497%20-%20Bravely%20Default%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CJapan%29.3ds.7z"
        >
          3DS0497 - Bravely Default (Europe)(En,Fr,Ge,It,Es,Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0498%20-%20Angry%20Birds%20Trilogy%20%28Usa%29.3ds.7z"
        >
          3DS0498 - Angry Birds Trilogy (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0499%20-%20Battleship%20%28Usa%29.3ds.7z"
        >
          3DS0499 - Battleship (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0500%20-%20Purr%20Pals%20-%20Purrfection%20%28Usa%29.3ds.7z"
        >
          3DS0500 - Purr Pals - Purrfection (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0501%20-%20Madagascar%203%20%28Usa%29.3ds.7z"
        >
          3DS0501 - Madagascar 3 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0502%20-%20FIFA%20Soccer%2013%20%28Usa%29.3ds.7z"
        >
          3DS0502 - FIFA Soccer 13 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0503%20-%20Shin%20Egokoro%20Kyou_su%20%28Japan%29.3ds.7z"
        >
          3DS0503 - Shin Egokoro Kyou_su (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0504%20-%20Shinrei%20Camera%20-%20Tsuiteru%20Techou%20%28Japan%29.3ds.7z"
        >
          3DS0504 - Shinrei Camera - Tsuiteru Techou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0505%20-%20Moe%20Moe%20Daisensou%20-%20Gendaiban%203D%20%28Japan%29.3ds.7z"
        >
          3DS0505 - Moe Moe Daisensou - Gendaiban 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0506%20-%20Meitantei%20Conan%20-%20Marionette%20Symphony%20%28Japan%29.3ds.7z"
        >
          3DS0506 - Meitantei Conan - Marionette Symphony (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0507%20-%20One%20Piece%20-%20Romance%20Dawn%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0507 - One Piece - Romance Dawn (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0508%20-%20Mario%20and%20Sonic%20at%20the%20London%202012%20Olympic%20Games%20%28Japan%29.3ds.7z"
        >
          3DS0508 - Mario and Sonic at the London 2012 Olympic Games
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0509%20-%20Magi%20-%20Hajimari%20no%20Meikyuu%20%28Japan%29.3ds.7z"
        >
          3DS0509 - Magi - Hajimari no Meikyuu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0510%20-%20Pokemon%20Y%20%28USA%29.3ds.7z"
        >
          3DS0510 - Pokemon Y (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0511%20-%20Pokemon%20X%20%28USA%29.3ds.7z"
        >
          3DS0511 - Pokemon X (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0512%20-%20Chousoku%20Henkei%20Gyrozetter%20-%20Albatross%20no%20Tsubasa%20%28Japan%29.3ds.7z"
        >
          3DS0512 - Chousoku Henkei Gyrozetter - Albatross no Tsubasa
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0513%20-%20Danball%20Senki%20Baku%20Boost%20%28Japan%29.3ds.7z"
        >
          3DS0513 - Danball Senki Baku Boost (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0514%20-%20Hello%20Kitty%20Picnic%20with%20Sanrio%20Friends%20%28Usa%29.3ds.7z"
        >
          3DS0514 - Hello Kitty Picnic with Sanrio Friends (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0515%20-%20Classic%20Games%20Overload%20Card%20and%20Puzzle%20Edition%20%28usa%29.3ds.7z"
        >
          3DS0515 - Classic Games Overload Card and Puzzle Edition (usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0516%20-%20Moshi%20Monsters%20Moshlings%20Theme%20Park%20%28Usa%29.3ds.7z"
        >
          3DS0516 - Moshi Monsters Moshlings Theme Park (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0517%20-%20Hello%20Kitty%20Picnic%20with%20Sanrio%20Friends%20%28rev01%29%28Usa%29.3ds.7z"
        >
          3DS0517 - Hello Kitty Picnic with Sanrio Friends (rev01)(Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0518%20-%20Disney%20Magic%20Castle%20-%20My%20Happy%20Life%20%28JP%29%20.3ds.7z"
        >
          3DS0518 - Disney Magic Castle - My Happy Life (JP) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0519%20-%20Doraemon%20and%20Nobita%20Number%20Adventure%20CHT%20%28JP%29%20.3ds.7z"
        >
          3DS0519 - Doraemon and Nobita Number Adventure CHT (JP) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0520%20-%20Professor%20Layton%20und%20das%20Vermaechtnis%20von%20Aslant%20GER.3ds.7z"
        >
          3DS0520 - Professor Layton und das Vermaechtnis von Aslant GER.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0521%20-%20Hatsune%20Miku%20-%20Project%20Mirai%202%20%28jp%29.3ds.7z"
        >
          3DS0521 - Hatsune Miku - Project Mirai 2 (jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0522%20-%20One%20Piece%20-%20Unlimited%20World%20Red%20%28jp%29.3ds.7z"
        >
          3DS0522 - One Piece - Unlimited World Red (jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0523%20-%20Onna%20no%20Ko%20to%20Mis_su%20ni%20Itara%20shichau%20Kamoshirenai%20%28JP%29.3ds.7z"
        >
          3DS0523 - Onna no Ko to Mis_su ni Itara shichau Kamoshirenai
          (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0524%20-%20Paper%20Mario%20-%20Super%20Seal%20CHT%20%28Usa%29.3ds.7z"
        >
          3DS0524 - Paper Mario - Super Seal CHT (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0525%20-%20Doodle%20Jump%20Adventures%20%28USA%29%20.3ds.7z"
        >
          3DS0525 - Doodle Jump Adventures (USA) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0526%20-%20Super%20Black%20Bass%203D%20%28USA%29.3ds.7z"
        >
          3DS0526 - Super Black Bass 3D (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0527%20-%20Batman%20Arkham%20Origins%20Blackgate%20%28USA%29.3ds.7z"
        >
          3DS0527 - Batman Arkham Origins Blackgate (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0528%20-%20Pokemon%20X%20%28JP%29.3ds.7z"
        >
          3DS0528 - Pokemon X (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0529%20-%20Pokemon%20Y%20%28JP%29.3ds.7z"
        >
          3DS0529 - Pokemon Y (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0530%20-%20Yu-Gi-Oh_Zexal_%20_Gekitotsu%20Duel%20Carnival_%28JP%29.3ds.7z"
        >
          3DS0530 - Yu-Gi-Oh_Zexal_ _Gekitotsu Duel Carnival_(JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0531%20-%20Shingeki%20no%20Kyoujin%20-%20Jinrui%20Saigo%20no%20Tsubasa%20%28JP%29.3ds.7z"
        >
          3DS0531 - Shingeki no Kyoujin - Jinrui Saigo no Tsubasa (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0532%20-%20Fantasy%20Life%20Link%20%28JP%29.3ds.7z"
        >
          3DS0532 - Fantasy Life Link (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0533%20-%20Yokai%20Watch%20%28JP%29.3ds.7z"
        >
          3DS0533 - Yokai Watch (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0534%20-%20Nintendo.3DS.Guide.Louvre%20%28Europe%20%29%20%28eng%29.3ds.7z"
        >
          3DS0534 - Nintendo.3DS.Guide.Louvre (Europe ) (eng).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0535%20-%20Nintendo.3DS.Guide.Louvre.%28Europe%29%20%28Fr%29.3ds.7z"
        >
          3DS0535 - Nintendo.3DS.Guide.Louvre.(Europe) (Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0536%20-%20Nintendo.3DS.Guide.Louvre.%28Europe%29%20%28Sp%29.3ds.7z"
        >
          3DS0536 - Nintendo.3DS.Guide.Louvre.(Europe) (Sp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0537%20-%20Nintendo.3DS.Guide.Louvre.%28europe%29%20%28ge%29.3ds.7z"
        >
          3DS0537 - Nintendo.3DS.Guide.Louvre.(europe) (ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0538%20-%20Nintendo.3DS.Guide.Louvre%20%28Europe%20%29%20%28It%29.3ds.7z"
        >
          3DS0538 - Nintendo.3DS.Guide.Louvre (Europe ) (It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0539%20-%20Nintendo.3DS.Guide.Louvre.%28JPN%29.3ds.7z"
        >
          3DS0539 - Nintendo.3DS.Guide.Louvre.(JPN).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0540%20-%20Nintendo.3DS.Guide.Louvre%28KOR%29.3ds.7z"
        >
          3DS0540 - Nintendo.3DS.Guide.Louvre(KOR).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0541%20-%20Wreck.It.Ralph.%28Europe%29.3ds.7z"
        >
          3DS0541 - Wreck.It.Ralph.(Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0542%20-%20LEGO.Batman.2.DC.Super.Heroes.%28Europe%29.3ds.7z"
        >
          3DS0542 - LEGO.Batman.2.DC.Super.Heroes.(Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0543%20-%20LEGO.The.Lord.of.the.Rings.%28Europe%29.3ds.7z"
        >
          3DS0543 - LEGO.The.Lord.of.the.Rings.(Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0544%20-%20Skylanders.Spyros.Adventure.%28%20Europe%29.3ds.7z"
        >
          3DS0544 - Skylanders.Spyros.Adventure.( Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0545%20-%20Lego%20Friends%28Europe%29%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0545 - Lego Friends(Europe)(En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0546%20-%20My%20Exotic%20Farm%20%28Europe%29.3ds.7z"
        >
          3DS0546 - My Exotic Farm (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0547%20-%20Outdoors%20Unleashed%20Africa%203D%20%28Europe%29.3ds.7z"
        >
          3DS0547 - Outdoors Unleashed Africa 3D (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0548%20-%20My%20Vet%20Practice%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0548 - My Vet Practice 3D (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0549%20-%20FIFA%2013%20%28Europe%29%20%28Es%2CDe%2CIt%29.3ds.7z"
        >
          3DS0549 - FIFA 13 (Europe) (Es,De,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0550%20-%20Turbo%20Super%20Stunt%20Squad%20%28Europe%29%20%28En%2CFr%2CGe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0550 - Turbo Super Stunt Squad (Europe) (En,Fr,Ge,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0551%20-%20Metal_Max%204%20-%20Gekkou%20no%20Diva%28JPN%29.3ds.7z"
        >
          3DS0551 - Metal_Max 4 - Gekkou no Diva(JPN).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0552%20-%20Daigasso_Band%20Brothers%20%28JP%29.3ds.7z"
        >
          3DS0552 - Daigasso_Band Brothers (JP).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0553%20-%20Danball%20Senki%20W_Chou%20Custom%20JON%203DS%20%28jp%29.3ds.7z"
        >
          3DS0553 - Danball Senki W_Chou Custom JON 3DS (jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0554%20-%20Danball%20Senki%20Wars%20%28Japan%29.3ds.7z"
        >
          3DS0554 - Danball Senki Wars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0555%20-%20Digimon%20World%20ReDigtize%20Decode%20%28jp%29.3ds.7z"
        >
          3DS0555 - Digimon World ReDigtize Decode (jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0556%20-%20Highschool.DxD%20%28.JPN%29.3ds.7z"
        >
          3DS0556 - Highschool.DxD (.JPN).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0557%20-%20Adventure.Time.Explore.the.Dungeon.Because.I.DONT.KNOW%20%28Europe%29%20%28%20en%2Cfr%2Cge%2Cit%2Ces%20%29.3ds.7z"
        >
          3DS0557 - Adventure.Time.Explore.the.Dungeon.Because.I.DONT.KNOW
          (Europe) ( en,fr,ge,it,es ).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0558%20-%20Hakuoki%20Memories%20of.the.Shinsengumi.%28Eurppe%29%20%28En%29.3ds.7z"
        >
          3DS0558 - Hakuoki Memories of.the.Shinsengumi.(Eurppe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0559%20-%20FIFA%2014%20%28Europe%29.3ds.7z"
        >
          3DS0559 - FIFA 14 (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0560%20-%20Angry%20Birds%20Star%20Wars%20%28Europe%29.3ds.7z"
        >
          3DS0560 - Angry Birds Star Wars (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0561%20-%20Teenage%20Mutant%20Ninja%20Turtles%20%28Europe%29.3ds.7z"
        >
          3DS0561 - Teenage Mutant Ninja Turtles (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0562%20-%20Tobidasu%20Puzzle%20Bobble%203D%20%28JPN%29.3ds.7z"
        >
          3DS0562 - Tobidasu Puzzle Bobble 3D (JPN).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0563%20-%20Super%20Mon_%20Ball%203D%20%28JPN%29.3ds.7z"
        >
          3DS0563 - Super Mon_ Ball 3D (JPN).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0564%20-%20Bratz%20-%20Fashion%20Boutique%28Usa%29.3ds.7z"
        >
          3DS0564 - Bratz - Fashion Boutique(Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0565%20-%20Virus%20Shooter%20XX%20%28JPN%29%20.3ds.7z"
        >
          3DS0565 - Virus Shooter XX (JPN) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0566%20-%20Bikkuriman%20Kanjuku%20Haou%20Sanmi%20Douran%20Sensouki%20%28jp%29.3ds.7z"
        >
          3DS0566 - Bikkuriman Kanjuku Haou Sanmi Douran Sensouki (jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0567%20-%20Bibi%20Blocksberg%20Das%20grosse%20Hexenbesenrennen%202%20%28Europe%29%20GER.3ds.7z"
        >
          3DS0567 - Bibi Blocksberg Das grosse Hexenbesenrennen 2 (Europe)
          GER.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0568%20-%20Beyblade.Evolution.%28Europe%29%20%28En%2CFr%2CGe%2CEs%29%20.3ds.7z"
        >
          3DS0568 - Beyblade.Evolution.(Europe) (En,Fr,Ge,Es) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0569%20-%20Regular.Show.Mordecai.and.Rigby.in.8Bit.Land.%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0569 - Regular.Show.Mordecai.and.Rigby.in.8Bit.Land.(Europe)
          (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0570%20-%20Games%20Festival%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%2CFi%29.3ds.3ds.7z"
        >
          3DS0570 - Games Festival 2 (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da,Fi).3ds.3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0571%20-%20LEGO%20Marvel%20Super%20Heroes%20Universe%20in%20Peril%28Europe%29%28En%2CFr%2CGe%2CEs%2CNl%2CDa%2CIt%29.3ds.7z"
        >
          3DS0571 - LEGO Marvel Super Heroes Universe in
          Peril(Europe)(En,Fr,Ge,Es,Nl,Da,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0572%20-%20Harvest%20Moon%203D%20-%20A%20New%20Beginning%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0572 - Harvest Moon 3D - A New Beginning (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0573%20-%20Power%20Rangers%20-%20Megaforce%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0573 - Power Rangers - Megaforce (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0574%20-%20My%20Little%20Baby%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0574 - My Little Baby 3D (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0575%20-%20Seisou%20no%20Amazones%20%28Japan%29.3ds.7z"
        >
          3DS0575 - Seisou no Amazones (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0576%20-%20Tomodachi%20Collection%20-%20Shin%20Seikatsu%20%28Japan%29.3ds.7z"
        >
          3DS0576 - Tomodachi Collection - Shin Seikatsu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0577%20-%20Professor%20Layton%20and%20the%20Azran%20Legacy%28Europe%29%28En%29.3ds.7z"
        >
          3DS0577 - Professor Layton and the Azran Legacy(Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0578%20-%20Mystery%20Case%20Files%20-%20Dire%20Groves%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0578 - Mystery Case Files - Dire Groves (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0579%20-%20SpongeBob%20SquarePants%20-%20Planktons%20Robotic%20Revenge%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CS%20v%29.3ds.7z"
        >
          3DS0579 - SpongeBob SquarePants - Planktons Robotic Revenge (Europe)
          (En,Fr,De,Es,It,Nl,S v).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0580%20-%20Monster%20High%20-%2013%20Wishes%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CS%20v%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0580 - Monster High - 13 Wishes (Europe) (En,Fr,De,Es,It,Nl,S
          v,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0581%20-%20Mario%20Party%20-%20Island%20Tour%20%28europe%29%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt.Ru%29.3ds.7z"
        >
          3DS0581 - Mario Party - Island Tour
          (europe)(En,Fr,De,Es,It,Nl,Pt.Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0582%20-%20Professor%20Layton%20and%20the%20Miracle%20Mask%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0582 - Professor Layton and the Miracle Mask (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0583%20-%20Disney%20Infinity%20%28Europe%29%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0583 - Disney Infinity (Europe)(En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0584%20-%20Ben%2010%20-%20Omniverse%202%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0584 - Ben 10 - Omniverse 2 (Europe) (En,Fr,De,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0585%20-%20Skylanders%20Swap%20Force%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CSv%2CNb%2CFi%29.3ds.7z"
        >
          3DS0585 - Skylanders Swap
          Force(Europe)(En,Fr,Ge,It,Es,Nl,Da,Sv,Nb,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0586%20-%20Skylanders%20Giants%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0586 - Skylanders Giants (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0587%20-%20Senran%20Kagura%20-%20Shoujotachi%20no%20Shinei%20%28Japan%29.3ds.7z"
        >
          3DS0587 - Senran Kagura - Shoujotachi no Shinei (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0588%20-%20Disney%20Frozen%20-%20Olafs%20Quest%28Europe%29%28En%2CFr%2CGe%2CIt%2CNl%29.3ds.7z"
        >
          3DS0588 - Disney Frozen - Olafs Quest(Europe)(En,Fr,Ge,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0589%20-%20Professeur%20Layton%20et%20lHeritage%20des%20Aslantes%20%28Europe%29%20%28Fr%29.3ds.7z"
        >
          3DS0589 - Professeur Layton et lHeritage des Aslantes (Europe)
          (Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0590%20-%20I.Love.my.Pets.%28%20Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CP%20t%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0590 - I.Love.my.Pets.( Europe) (En,Fr,De,Es,It,Nl,P
          t,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0591%20-%20Luxor.%20%28Europe%29%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0591 - Luxor. (Europe)(En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0592%20-%20Jewel%20Quest%20IV%20Heritage.%28%20Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0592 - Jewel Quest IV Heritage.( Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0593%20-%20Cloudy%20with%20a%20Chance%20of%20Meatballs%202%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CNl%29.3ds.7z"
        >
          3DS0593 - Cloudy with a Chance of Meatballs 2 (Europe)
          (En,Fr,De,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0594%20-%20Medarot%207%20-%20Kabuto%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS0594 - Medarot 7 - Kabuto Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0595%20-%20Medarot%207%20-%20Kuwagata%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS0595 - Medarot 7 - Kuwagata Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0596%20-%20Medarot%20Dual%20-%20Kabuto%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS0596 - Medarot Dual - Kabuto Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0597%20-%20American%20Mensa%20Academy%28Usa%29.3ds.7z"
        >
          3DS0597 - American Mensa Academy(Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0598%20-%20My%20Farm%203D%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%2CSv%2CNb%2CFi%2CPt%29.3ds.7z"
        >
          3DS0598 - My Farm 3D(Europe)(En,Fr,Ge,It,Es,Nl,Da,Sv,Nb,Fi,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0599%20-%20LEGO%20Legends%20of%20Chima%20-%20Lavals%20Journey%28USA%29.3ds.7z"
        >
          3DS0599 - LEGO Legends of Chima - Lavals Journey(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0600%20-%20Super%20Black%20Bass%203D%28Europe%29%20%28En%2CFr%2CDe%29.3ds.7z"
        >
          3DS0600 - Super Black Bass 3D(Europe) (En,Fr,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0601%20-%20Medarot%20Dual%20-%20Kuwagata%20Ver.%28Japan%29.3ds.7z"
        >
          3DS0601 - Medarot Dual - Kuwagata Ver.(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0602%20-%20Project%20X%20Zone%28USA%29.3ds.7z"
        >
          3DS0602 - Project X Zone(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0603%20-%20Finding%20Nemo%20-%20Escape%20to%20the%20Big%20Blue%20-%20Special%20Edition%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0603 - Finding Nemo - Escape to the Big Blue - Special
          Edition(Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0604%20-%20Puzzler%20World%202013%20%28USA%29.3ds.7z"
        >
          3DS0604 - Puzzler World 2013 (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0605%20-%20Scribblenauts%20Unlimited%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29%20%28Rev%201%29.3ds.7z"
        >
          3DS0605 - Scribblenauts Unlimited (Europe) (En,Fr,De,Es,It,Nl) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0606%20-%20Disney%20Planes%28Usa%29.3ds.7z"
        >
          3DS0606 - Disney Planes(Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0607%20-%20Toushin%20Toshi%20-%20Girls%20Gift%20RPG%28Japan%29.3ds.7z"
        >
          3DS0607 - Toushin Toshi - Girls Gift RPG(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0608%20-%20Disney%20Frozen%20-%20Olafs%20Quest%28USA%29.3ds.7z"
        >
          3DS0608 - Disney Frozen - Olafs Quest(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0609%20-%20The%20LEGO%20Movie%20Videogame%28USA%29.3ds.7z"
        >
          3DS0609 - The LEGO Movie Videogame(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0610%20-%20Fast%20%26%20Furious%20-%20Showdown%28USA%29.3ds.7z"
        >
          3DS0610 - Fast &amp; Furious - Showdown(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0611%20-%20Bravely%20Default%28USA%29.3ds.7z"
        >
          3DS0611 - Bravely Default(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0612%20-%20Sonic%20-%20Lost%20World%28USA%29.3ds.7z"
        >
          3DS0612 - Sonic - Lost World(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0613%20-%20Brain%20Training%203D%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0613 - Brain Training 3D(Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0614%20-%20PazuDora%20Z%20-%20Puzzle%20%26%20Dragons%28Japan%29.3ds.7z"
        >
          3DS0614 - PazuDora Z - Puzzle &amp; Dragons(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0615%20-%20Freakyforms%20Deluxe%20-%20Your%20Creations%20Alive%21%28USA%29.3ds.7z"
        >
          3DS0615 - Freakyforms Deluxe - Your Creations Alive!(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0616%20-%20Rise%20of%20the%20Guardians%28USA%29.3ds.7z"
        >
          3DS0616 - Rise of the Guardians(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0617%20-%20Travel%20Adventures%20with%20Hello%20Kitty%28USA%29.3ds.7z"
        >
          3DS0617 - Travel Adventures with Hello Kitty(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0618%20-%20Ben%2010%20-%20Omniverse%28USA%29.3ds.7z"
        >
          3DS0618 - Ben 10 - Omniverse(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0619%20-%20Girls%20Fashion%20Shoot%28USA%29.3ds.7z"
        >
          3DS0619 - Girls Fashion Shoot(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0620%20-%20LEGO%20Friends%28USA%29.3ds.7z"
        >
          3DS0620 - LEGO Friends(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0621%20-%20Puzzler%20World%202012%203D%28USA%29.3ds.7z"
        >
          3DS0621 - Puzzler World 2012 3D(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0622%20-%20RollerCoaster%20Tycoon%203D%28USA%29.3ds.7z"
        >
          3DS0622 - RollerCoaster Tycoon 3D(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0623%20-%20Inazuma%20Eleven%203%20-%20Team%20Ogre%20Attacks%28EUR%29%28En%2CFr%2CDe%29.3ds.7z"
        >
          3DS0623 - Inazuma Eleven 3 - Team Ogre Attacks(EUR)(En,Fr,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0624%20-%20Disney%20Infinity%20-%20Toy%20Box%20Challenge%28USA%29.3ds.7z"
        >
          3DS0624 - Disney Infinity - Toy Box Challenge(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0625%20-%20Shin%20Megami%20Tensei%20-%20Devil%20Summoner%20-%20Soul%20Hackers%28Europe%29%28En%29.3ds.7z"
        >
          3DS0625 - Shin Megami Tensei - Devil Summoner - Soul
          Hackers(Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0626%20-%202in1%20-%20Horses%203D%28Europe%29%28En%2CFr%2CDe%2CIt%2CEs%2CNl%2CDk.Se%2CNo%29.3ds.7z"
        >
          3DS0626 - 2in1 - Horses 3D(Europe)(En,Fr,De,It,Es,Nl,Dk.Se,No).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0627%20-%20Girls%20Fashion%20Shoot%28Europe%29%28En%2CDe%29.3ds.7z"
        >
          3DS0627 - Girls Fashion Shoot(Europe)(En,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0628%20-%20Murder%20on%20the%20Titanic%28Europe%29%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0628 - Murder on the Titanic(Europe)(En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0629%20-%20Monster%20High%20-%20Skultimate%20Roller%20Maze%28USA%29.3ds.7z"
        >
          3DS0629 - Monster High - Skultimate Roller Maze(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0630%20-%20NCIS%203D%28USA%29.3ds.7z"
        >
          3DS0630 - NCIS 3D(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0631%20-%20Jewel%20Master%20-%20Cradle%20of%20Egypt%202%203D%28USA%29.3ds.7z"
        >
          3DS0631 - Jewel Master - Cradle of Egypt 2 3D(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0632%20-%20One%20Piece%20-%20Romance%20Dawn%28USA%29.3ds.7z"
        >
          3DS0632 - One Piece - Romance Dawn(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0633%20-%20Spy%20Hunter%28USA%29.3ds.7z"
        >
          3DS0633 - Spy Hunter(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0634%20-%20The%20Croods%20-%20Prehistoric%20Party%21%28USA%29.3ds.7z"
        >
          3DS0634 - The Croods - Prehistoric Party!(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0635%20-%20Mahjong%203D%20-%20Warriors%20of%20the%20Emperor%28USA%29.3ds.7z"
        >
          3DS0635 - Mahjong 3D - Warriors of the Emperor(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0636%20-%20MapleStory%20-%20The%20Girls%20Fate%28KOR%29.3ds.7z"
        >
          3DS0636 - MapleStory - The Girls Fate(KOR).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0637%20-%20Imagine%20Babyz%28USA%29.3ds.7z"
        >
          3DS0637 - Imagine Babyz(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0638%20-%20Naruto%20Powerful%20Shippuden%28USA%29.3ds.7z"
        >
          3DS0638 - Naruto Powerful Shippuden(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0639%20-%20Sonic%20%26%20All-Stars%20Racing%20Transformed%28USA%29.3ds.7z"
        >
          3DS0639 - Sonic &amp; All-Stars Racing Transformed(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0640%20-%20Cloudy%20with%20a%20Chance%20of%20Meatballs%202%28USA%29.3ds.7z"
        >
          3DS0640 - Cloudy with a Chance of Meatballs 2(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0641%20-%20Doodle%20Jump%20Adventures%28Europe%29%28En%2CFr%2CDe%2CIt%2CNl%29.3ds.7z"
        >
          3DS0641 - Doodle Jump Adventures(Europe)(En,Fr,De,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0642%20-%20Jewel%20Master%20-%20Cradle%20of%20Rome%202%28USA%29.3ds.7z"
        >
          3DS0642 - Jewel Master - Cradle of Rome 2(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0643%20-%20Senran%20Kagura%20Burst%28Europe%29%28En%29.3ds.7z"
        >
          3DS0643 - Senran Kagura Burst(Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0644%20-%20Bibi%20%26%20Tina%20-%20Das%20Spiel%20zum%20Film%28Germany%29%28De%29.3ds.7z"
        >
          3DS0644 - Bibi &amp; Tina - Das Spiel zum Film(Germany)(De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0645%20-%20FIFA%2014%28USA%29.3ds.7z"
        >
          3DS0645 - FIFA 14(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0646%20-%20Deer%20Drive%20Legends%28USA%29.3ds.7z"
        >
          3DS0646 - Deer Drive Legends(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0647%20-%20Professor%20Layton%20and%20the%20Azran%20Legacy%28USA%29.3ds.7z"
        >
          3DS0647 - Professor Layton and the Azran Legacy(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0648%20-%20Sternenschweif%203D%20-%20Das%20Geheimnis%20im%20Zauberwald%20%28Europe%29%28Ge%29.3ds.7z"
        >
          3DS0648 - Sternenschweif 3D - Das Geheimnis im Zauberwald
          (Europe)(Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0649%20-%20Life%20with%20Horses%203D%28Europe%29%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0649 - Life with Horses 3D(Europe)(En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0650%20-%20Yoshis%20New%20Island%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS0650 - Yoshis New Island (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0651%20-%20FIFA%2012%20%28Germany%29%20%28De%2CEs%2CIt%29.3ds.7z"
        >
          3DS0651 - FIFA 12 (Germany) (De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0652%20-%20Hoshi%20no%20Kirby%20T_le%20Deluxe%20%28jp%29.3ds.7z"
        >
          3DS0652 - Hoshi no Kirby T_le Deluxe (jp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0653%20-%20Magi%20-%20Aratanaru%20Sekai%20%28Japan%29.3ds.7z"
        >
          3DS0653 - Magi - Aratanaru Sekai (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0654%20-%20Dragon%20Quest%20Monsters%202%20-%20Iru%20to%20Ruka%20no%20Fushigi%20na%20Fushigi%20na%20Kagi%20%28Japan%29.3ds.7z"
        >
          3DS0654 - Dragon Quest Monsters 2 - Iru to Ruka no Fushigi na Fushigi
          na Kagi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0655%20-%20Yoshis%20New%20Island%20%28USA%29.3ds.7z"
        >
          3DS0655 - Yoshis New Island (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0656%20-%20Disney%20Epic%20Mic_%20Power%20of%20Illusion%20%28Europe%29%28En%20%2CDa%2CSv%2CNb%29.3ds.7z"
        >
          3DS0656 - Disney Epic Mic_ Power of Illusion (Europe)(En
          ,Da,Sv,Nb).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0657%20-%20Disney%20Planes%20%28europe%29%20%28En%2CFi%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS0657 - Disney Planes (europe) (En,Fi,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0658%20-%20Pro%20Evolution%20Soccer%202013%203D%20%28Europe%29%20%28En%2CRu%2CTu%2CNo%2CSv%29.3ds.7z"
        >
          3DS0658 - Pro Evolution Soccer 2013 3D (Europe)
          (En,Ru,Tu,No,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0659%20-%20Finding%20Nemo%20-%20Escape%20to%20the%20Big%20Blue%20-%20Special%20Edition%20%28europe%29%20%28En%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS0659 - Finding Nemo - Escape to the Big Blue - Special Edition
          (europe) (En,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0660%20-%20Cars%202%20%28europe%29%20%28En%2CFi%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS0660 - Cars 2 (europe) (En,Fi,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0661%20-%204%20Elements%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0661 - 4 Elements (Europe) (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0662%20-%20Madagascar%203%20-%20The%20Croods%20Combo%20Pack%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS0662 - Madagascar 3 - The Croods Combo Pack
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0663%20-%20Cut%20the%20RopeT_le%20Treat%20%28Usa%29.3ds.7z"
        >
          3DS0663 - Cut the RopeT_le Treat (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0664%20-%20Professor%20Layton%20vs%20Phoenix%20Wright%20-%20Ace%20Attorney%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0664 - Professor Layton vs Phoenix Wright - Ace Attorney (Europe)
          (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0665%20-%20Kuma%20Tomo%20%28Japan%29.3ds.7z"
        >
          3DS0665 - Kuma Tomo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0666%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Haneda%20with%20JAL%20%28Japan%29.3ds.7z"
        >
          3DS0666 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Haneda with JAL
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0667%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Naha%20Premium%20%28Japan%29.3ds.7z"
        >
          3DS0667 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Naha Premium
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0668%20-%20Puzzler%20World%202013%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0668 - Puzzler World 2013 (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0669%20-%20Hot%20Wheels%20-%20Worlds%20Best%20Driver%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0669 - Hot Wheels - Worlds Best Driver (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0670%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Nagaragawa%20Tetsudou%20Hen%20%28Japan%29.3ds.7z"
        >
          3DS0670 - Tetsudou Nippon! Rosen Tabi - Nagaragawa Tetsudou Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0671%20-%20Frogger%203D%20%28Japan%29.3ds.7z"
        >
          3DS0671 - Frogger 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0672%20-%20Bokujou%20Monogatari%20-%20Tsunagaru%20Shin%20Tenchi%20%28Japan%29.3ds.7z"
        >
          3DS0672 - Bokujou Monogatari - Tsunagaru Shin Tenchi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0673%20-%20Legend%20of%20Zelda%2C%20The%20-%20Kamigami%20no%20Triforce%202%20%28Japan%29.3ds.7z"
        >
          3DS0673 - Legend of Zelda, The - Kamigami no Triforce 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0674%20-%20Runabout%203D%20-%20Drive%20Impossible%20%28Japan%29.3ds.7z"
        >
          3DS0674 - Runabout 3D - Drive Impossible (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0675%20-%20Inazuma%20Eleven%20Go%20-%20Dark%20%28Japan%29.3ds.7z"
        >
          3DS0675 - Inazuma Eleven Go - Dark (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0676%20-%20Pro%20Yakyuu%20Spirits%202011%20%28Japan%29.3ds.7z"
        >
          3DS0676 - Pro Yakyuu Spirits 2011 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0677%20-%20Shin%20Sekaiju%20no%20Meikyuu%20-%20Millennium%20no%20Shoujo%20%28Japan%29.3ds.7z"
        >
          3DS0677 - Shin Sekaiju no Meikyuu - Millennium no Shoujo
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0678%20-%20Cave%20Story%203D%20%28Japan%29.3ds.7z"
        >
          3DS0678 - Cave Story 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0679%20-%20Mario%20Tennis%20Open%20%28Japan%29.3ds.7z"
        >
          3DS0679 - Mario Tennis Open (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0680%20-%20New%20Love%20Plus%20Plus%20%28Japan%29.3ds.7z"
        >
          3DS0680 - New Love Plus Plus (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0681%20-%20Gon%20-%20BakuBaku%20BakuBaku%20Adventure%20%28Japan%29.3ds.7z"
        >
          3DS0681 - Gon - BakuBaku BakuBaku Adventure (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0682%20-%20One%20Piece%20-%20Romance%20Dawn%20%28Japan%29.3ds.7z"
        >
          3DS0682 - One Piece - Romance Dawn (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0683%20-%20Tantei%20Jinguuji%20Saburou%20-%20Fukushuu%20no%20Rondo%20%28Japan%29.3ds.7z"
        >
          3DS0683 - Tantei Jinguuji Saburou - Fukushuu no Rondo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0684%20-%20Tetris%20%28japan%29.3ds.7z"
        >
          3DS0684 - Tetris (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0685%20-%20Tousouchuu%20-%20Shijou%20Saikyou%20no%20HunterTachi%20Kara%20Nigekire%20%28japan%29.3ds.7z"
        >
          3DS0685 - Tousouchuu - Shijou Saikyou no HunterTachi Kara Nigekire
          (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0686%20-%20Nobunaga%20no%20Yabou%20%28Japan%29.3ds.7z"
        >
          3DS0686 - Nobunaga no Yabou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0687%20-%20Sangokushi%20%28Japan%29.3ds.7z"
        >
          3DS0687 - Sangokushi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0688%20-%20Pocket%20Soccer%20League%20-%20Calciobit%20%28Japan%29.3ds.7z"
        >
          3DS0688 - Pocket Soccer League - Calciobit (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0689%20-%20Winning%20Eleven%203DSoccer%20%28Japan%29.3ds.7z"
        >
          3DS0689 - Winning Eleven 3DSoccer (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0690%20-%20Ace%20Combat%203D%20-%20Cross%20Rumble%20%28Japan%29.3ds.7z"
        >
          3DS0690 - Ace Combat 3D - Cross Rumble (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0691%20-%20Nazo%20waku%20Yakata%20-%20Oto%20no%20Ma%20ni%20Ma%20ni%20%28Japan%29.3ds.7z"
        >
          3DS0691 - Nazo waku Yakata - Oto no Ma ni Ma ni (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0692%20-%20Layton%20Kyouju%20vs%20Gyakuten%20Saiban%20%28japan%29.3ds.7z"
        >
          3DS0692 - Layton Kyouju vs Gyakuten Saiban (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0693%20-%20Cooking%20Mama%204%20%28Japan%29.3ds.7z"
        >
          3DS0693 - Cooking Mama 4 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0694%20-%20Shanghai%203D%20Cube%20%28Japan%29.3ds.7z"
        >
          3DS0694 - Shanghai 3D Cube (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0695%20-%20Sherlock%20Holmes%20-%20Puzzle%20City%20%28Japan%29.3ds.7z"
        >
          3DS0695 - Sherlock Holmes - Puzzle City (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0696%20-%20Sonic%20Generations%20-%20Ao%20no%20Bouken%20%28Japan%29.3ds.7z"
        >
          3DS0696 - Sonic Generations - Ao no Bouken (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0697%20-%20Zyuden%20Sentai%20Kyoryuger%20-%20Game%20de%20Gaburincho%20%28japan%29.3ds.7z"
        >
          3DS0697 - Zyuden Sentai Kyoryuger - Game de Gaburincho (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0698%20-%20Professor%20Layton%20en%20het%20Masker%20der%20Wonderen%20%28Netherlands%29.3ds.7z"
        >
          3DS0698 - Professor Layton en het Masker der Wonderen
          (Netherlands).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0699%20-%20Wagamama%20Fashion%20-%20Girls%20Mode%20-%20Yokubari%20Sengen%20%28Japan%29.3ds.7z"
        >
          3DS0699 - Wagamama Fashion - Girls Mode - Yokubari Sengen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0700%20-%20Pretty%20Rhythm%20-%20My%20Deco%20Rainbow%20Wedding%20%28Japan%29.3ds.7z"
        >
          3DS0700 - Pretty Rhythm - My Deco Rainbow Wedding (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0701%20-%20Mario%20and%20Luigi%20RPG%204%20-%20Dream%20Adventure%20%28Japan%29.3ds.7z"
        >
          3DS0701 - Mario and Luigi RPG 4 - Dream Adventure (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0702%20-%20Hero%20Bank%20%28Japan%29.3ds.7z"
        >
          3DS0702 - Hero Bank (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0703%20-%20Puyo%20Puyo%20Tetris%20%28Japan%29.3ds.7z"
        >
          3DS0703 - Puyo Puyo Tetris (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0704%20-%20New%20Style%20Boutique%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0704 - New Style Boutique (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0705%20-%20Pachipara%203D%20Deluxe%20Umi%20Monogatari%20Pachipro%20Fuunroku%20%28Japan%29.3ds.7z"
        >
          3DS0705 - Pachipara 3D Deluxe Umi Monogatari Pachipro Fuunroku
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0706%20-%20Kuukan%20Sagashimono%20kei%20-%20Nouryoku%20Kaihatsu%203D%20Nou%20Training%20%28Japan%29.3ds.7z"
        >
          3DS0706 - Kuukan Sagashimono kei - Nouryoku Kaihatsu 3D Nou Training
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0707%20-%20Mameshiba%20%28Japan%29.3ds.7z"
        >
          3DS0707 - Mameshiba (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0708%20-%20Nintendogs%20%20%20Cats%20-%20French%20Bull%20and%20New%20Friends%20%28Japan%29.3ds.7z"
        >
          3DS0708 - Nintendogs Cats - French Bull and New Friends (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0709%20-%20Paper%20Mario%20-%20Super%20Seal%20%28Japan%29.3ds.7z"
        >
          3DS0709 - Paper Mario - Super Seal (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0710%20-%20New%20Super%20Mario%20Bros.%202%20%28Japan%29.3ds.7z"
        >
          3DS0710 - New Super Mario Bros. 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0711%20-%20Pilotwings%20Resort%20%28Japan%29.3ds.7z"
        >
          3DS0711 - Pilotwings Resort (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0712%20-%20Kawaii%20Koinu%203D%20%28Japan%29.3ds.7z"
        >
          3DS0712 - Kawaii Koinu 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0713%20-%20Dangerous%20Jiisan%20to%201000-nin%20no%20Otomodachi%20Ja%20%28Japan%29.3ds.7z"
        >
          3DS0713 - Dangerous Jiisan to 1000-nin no Otomodachi Ja (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0714%20-%20The%20Sims%203%20-%20Pets%20%28Japan%29.3ds.7z"
        >
          3DS0714 - The Sims 3 - Pets (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0715%20-%20Disney%20Epic%20Mic_%20-%20Mic_%20no%20Fushigi%20na%20Bouken%20%28Japan%29.3ds.7z"
        >
          3DS0715 - Disney Epic Mic_ - Mic_ no Fushigi na Bouken (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0716%20-%20Earthpedia%20%28Japan%29.3ds.7z"
        >
          3DS0716 - Earthpedia (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0717%20-%20ESSE%20RakuRaku%20Kakeibo%20%28Japan%29.3ds.7z"
        >
          3DS0717 - ESSE RakuRaku Kakeibo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0718%20-%20Kobito%20Dukan%20-%20Kobito%20Kansatsu%20Set%20%28Japan%29.3ds.7z"
        >
          3DS0718 - Kobito Dukan - Kobito Kansatsu Set (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0719%20-%20Mamegoma%20-%20Yoi%20Ko%20Marui%20Ko%20Genki%20na%20Ko%20%28Japan%29.3ds.7z"
        >
          3DS0719 - Mamegoma - Yoi Ko Marui Ko Genki na Ko (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0720%20-%20Nekketsu%20Kouha%20Kunio-Kun%20Special%20%28Japan%29.3ds.7z"
        >
          3DS0720 - Nekketsu Kouha Kunio-Kun Special (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0721%20-%20Tekken%203D%20Prime%20Edition%20%28Japan%29.3ds.7z"
        >
          3DS0721 - Tekken 3D Prime Edition (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0722%20-%20Donkey%20Kong%20Returns%203D%20%28Japan%29.3ds.7z"
        >
          3DS0722 - Donkey Kong Returns 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0723%20-%20Inazuma%20Eleven%20Go%202%20-%20Chrono%20Stone%20-%20Raimei%20%28Japan%29.3ds.7z"
        >
          3DS0723 - Inazuma Eleven Go 2 - Chrono Stone - Raimei (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0724%20-%20Oshare%20de%20Kawaii%21%20Koinu%20to%20Asobo%21%20Machi-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0724 - Oshare de Kawaii! Koinu to Asobo! Machi-Hen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0725%20-%20Oshare%20de%20Kawaii%21%20Koinu%20to%20Asobo%21%20Umi-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0725 - Oshare de Kawaii! Koinu to Asobo! Umi-Hen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0726%20-%20Aikatsu%20Cinderella%20Lesson%20%28Japan%29.3ds.7z"
        >
          3DS0726 - Aikatsu Cinderella Lesson (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0727%20-%20Girls%20Fashion%203D%20-%20Mezase%21%20Top%20Stylist%20%28Japan%29.3ds.7z"
        >
          3DS0727 - Girls Fashion 3D - Mezase! Top Stylist (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0728%20-%20Puyo%20Puyo%20%28Japan%29.3ds.7z"
        >
          3DS0728 - Puyo Puyo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0729%20-%20Toriko%20-%20Ultimate%20Survival%20%28Japan%29.3ds.7z"
        >
          3DS0729 - Toriko - Ultimate Survival (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0730%20-%20Simple%20Series%20for%20Nintendo%203DS%20Vol.%201%20-%20The%20Mahjong%20%28Japan%29.3ds.7z"
        >
          3DS0730 - Simple Series for Nintendo 3DS Vol. 1 - The Mahjong
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0731%20-%20Nekketsu%20Kouha%20Kunio-Kun%20SP%20-%20Rantou%20Kyousoukyoku%20%28Japan%29.3ds.7z"
        >
          3DS0731 - Nekketsu Kouha Kunio-Kun SP - Rantou Kyousoukyoku
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0732%20-%20Spot%20the%20Differences%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0732 - Spot the Differences (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0733%20-%20Metal%20Gear%20Solid%20-%20Snake%20Eater%203D%20%28Japan%29.3ds.7z"
        >
          3DS0733 - Metal Gear Solid - Snake Eater 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0734%20-%20Gaist%20Crusher%20%28Japan%29.3ds.7z"
        >
          3DS0734 - Gaist Crusher (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0735%20-%20Inazuma%20Eleven%20Go%20Galaxy%20-%20Supernova%20%28Japan%29.3ds.7z"
        >
          3DS0735 - Inazuma Eleven Go Galaxy - Supernova (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0736%20-%20Hakuouki%203D%20%28Japan%29.3ds.7z"
        >
          3DS0736 - Hakuouki 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0737%20-%20Farming%20Simulator%203D%20-%20Pocket%20Nouen%20%28Japan%29.3ds.7z"
        >
          3DS0737 - Farming Simulator 3D - Pocket Nouen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0738%20-%20Oshaberi%20Usagi%20Mecha%20Kawa%20Oshare%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS0738 - Oshaberi Usagi Mecha Kawa Oshare Collection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0739%20-%20Cut%20the%20Rope%20-%20T_le%20Treat%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0739 - Cut the Rope - T_le Treat (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0740%20-%20Jewel%20Master%20-%20Cradle%20of%20Egypt%202%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0740 - Jewel Master - Cradle of Egypt 2 3D (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0741%20-%20Skylanders%20Spyro%20no%20Daibouken%20%28Japan%29.3ds.7z"
        >
          3DS0741 - Skylanders Spyro no Daibouken (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0742%20-%20Inazuma%20Eleven%201-2-3%20-%20Endou%20Mamoru%20Densetsu%20%28Japan%29.3ds.7z"
        >
          3DS0742 - Inazuma Eleven 1-2-3 - Endou Mamoru Densetsu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0743%20-%20Nintendogs%20%20%20Cats%20-%20Shiba%20Inu%20%26%20New%20Friends%20%28Japan%29.3ds.7z"
        >
          3DS0743 - Nintendogs Cats - Shiba Inu &amp; New Friends (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0744%20-%20Inazuma%20Eleven%20Go%20-%20Galaxy%20Big%20Bang%20%28Japan%29.3ds.7z"
        >
          3DS0744 - Inazuma Eleven Go - Galaxy Big Bang (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0745%20-%20G1%20Grand%20Prix%20%28Japan%29.3ds.7z"
        >
          3DS0745 - G1 Grand Prix (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0746%20-%20Kobitodzukan%20-%20Kobito%20no%20Fushigi%20-%20Jikken%20Set%20%28Japan%29.3ds.7z"
        >
          3DS0746 - Kobitodzukan - Kobito no Fushigi - Jikken Set (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0747%20-%20Bikkuri%21%20Tobidasu%21%20Mahou%20no%20Pen%20%28Japan%29.3ds.7z"
        >
          3DS0747 - Bikkuri! Tobidasu! Mahou no Pen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0748%20-%20UnchainBlades%20EXXiV%20%28Japan%29.3ds.7z"
        >
          3DS0748 - UnchainBlades EXXiV (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0749%20-%20Profesor%20Layton%20y%20El%20Legado%20De%20Los%20Ashalanti%2C%20El%20%28Spain%29.3ds.7z"
        >
          3DS0749 - Profesor Layton y El Legado De Los Ashalanti, El
          (Spain).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0750%20-%20professor%20Layton%20e%20l%20eredita%20degli%20Aslant%2C%20Il%20%28Italy%29.3ds.7z"
        >
          3DS0750 - professor Layton e l eredita degli Aslant, Il (Italy).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0751%20-%20Dragon%20Ball%20Heroes%20-%20Ultimate%20Mission%20%28Japan%29.3ds.7z"
        >
          3DS0751 - Dragon Ball Heroes - Ultimate Mission (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0752%20-%20Mushibugyo%20%28Japan%29.3ds.7z"
        >
          3DS0752 - Mushibugyo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0753%20-%20Professor%20Layton%20vs.%20Phoenix%20Wright%20-%20Ace%20Attorney%20%28Europe%29%20%28En%2CEs%2CIt%29.3ds.7z"
        >
          3DS0753 - Professor Layton vs. Phoenix Wright - Ace Attorney (Europe)
          (En,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0754%20-%20F1%202011%20%28Japan%29.3ds.7z"
        >
          3DS0754 - F1 2011 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0755%20-%20Pretty%20Rhythm%20-%20Rainbow%20Live%20Kira%20Kira%20My%20Design%20%28Japan%29.3ds.7z"
        >
          3DS0755 - Pretty Rhythm - Rainbow Live Kira Kira My Design
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0756%20-%20Doki%20Doki%20Precure%20-%20Narikiri%20Life%20%28Japan%29.3ds.7z"
        >
          3DS0756 - Doki Doki Precure - Narikiri Life (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0757%20-%20Doraemon%20-%20Nobita%20no%20Himitsu%20Dougu%20Hakubutsukan%20%28Japan%29.3ds.7z"
        >
          3DS0757 - Doraemon - Nobita no Himitsu Dougu Hakubutsukan
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0758%20-%20Heavy%20Fire%20-%20The%20Chosen%20Few%20%28Japan%29.3ds.7z"
        >
          3DS0758 - Heavy Fire - The Chosen Few (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0759%20-%20LEGO%20Marvel%20Super%20Heroes%20-%20Universe%20in%20Peril%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0759 - LEGO Marvel Super Heroes - Universe in Peril (Europe)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0760%20-%20Best%20of%20Board%20Games%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0760 - Best of Board Games (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0761%20-%20Mario%20Party%20-%20Island%20Tour%20%28japan%29.3ds.7z"
        >
          3DS0761 - Mario Party - Island Tour (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0762%20-%20Mario%20Kart%207%20%28v01%29%20%28Europe%29%28Du%2CEn%2CFr%2CEs%2CGe%2CIt%2CPt%2CRu%29.3ds.7z"
        >
          3DS0762 - Mario Kart 7 (v01) (Europe)(Du,En,Fr,Es,Ge,It,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0763%20-%20Game%20Center%20CX%20-%203-Choume%20no%20Arino%20%28Japan%29.3ds.7z"
        >
          3DS0763 - Game Center CX - 3-Choume no Arino (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0764%20-%20Poyo%20Poyo%20Kansatsu%20Nikki%20%28Japan%29.3ds.7z"
        >
          3DS0764 - Poyo Poyo Kansatsu Nikki (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0765%20-%20Tom%20Clancys%20Ghost%20Recon%20-%20Shadow%20Wars%20%28Japan%29.3ds.7z"
        >
          3DS0765 - Tom Clancys Ghost Recon - Shadow Wars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0766%20-%20Toriko%20Gurume%20Monsters%20%28Japan%29.3ds.7z"
        >
          3DS0766 - Toriko Gurume Monsters (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0767%20-%20Toriko%20Gurumega%20Battle%20%28Japan%29.3ds.7z"
        >
          3DS0767 - Toriko Gurumega Battle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0768%20-%20Farmscapes%20%28Europe%29%20%28Fr%2CNl%29.3ds.7z"
        >
          3DS0768 - Farmscapes (Europe) (Fr,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0769%20-%20Devil%20Survivor%20-%20Over%20Clock%20%28Japan%29.3ds.7z"
        >
          3DS0769 - Devil Survivor - Over Clock (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0770%20-%20LEGO%20The%20Hobbit%20%28USA%29.3ds.7z"
        >
          3DS0770 - LEGO The Hobbit (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0771%20-%20Real%20Heroes%20-%20Firefighter%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0771 - Real Heroes - Firefighter 3D (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0772%20-%20Devil%20Summoner%20-%20Soul%20Hackers%20%28Japan%29.3ds.7z"
        >
          3DS0772 - Devil Summoner - Soul Hackers (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0773%20-%20Shin%20Megami%20Tensei%20IV%20%28Japan%29.3ds.7z"
        >
          3DS0773 - Shin Megami Tensei IV (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0774%20-%20Acrylic%20Palette%20-%20Irodori%20Cafe%20-%20Cheers%20%28Japan%29.3ds.7z"
        >
          3DS0774 - Acrylic Palette - Irodori Cafe - Cheers (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0775%20-%20Beast%20Saga%20-%20Saikyou%20Gekiotsu%20Coliseum%20%28Japan%29.3ds.7z"
        >
          3DS0775 - Beast Saga - Saikyou Gekiotsu Coliseum (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0776%20-%20Conception%20II%20%28Japan%29.3ds.7z"
        >
          3DS0776 - Conception II (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0777%20-%20Hello%20Kitty%20to%20Issho%21%20Block%20Crash%20Z%20%28Japan%29.3ds.7z"
        >
          3DS0777 - Hello Kitty to Issho! Block Crash Z (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0778%20-%20Hime%20Girl%20Paradise%20-%20Mechikawa%21%20Agemori%20Sensation%21%20%28Japan%29.3ds.7z"
        >
          3DS0778 - Hime Girl Paradise - Mechikawa! Agemori Sensation!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0779%20-%20Naruto%20SD%20Powerful%20Shippuden%20%28Japan%29.3ds.7z"
        >
          3DS0779 - Naruto SD Powerful Shippuden (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0780%20-%20Osawari%20Tantei%20Nameko%20Daihanshoku%20%28Japan%29.3ds.7z"
        >
          3DS0780 - Osawari Tantei Nameko Daihanshoku (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0781%20-%20Penguin%20no%20Mondai%20-%20The%20Wars%20%28Japan%29.3ds.7z"
        >
          3DS0781 - Penguin no Mondai - The Wars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0782%20-%20Bandai%20Namco%20Games%20Presents%20J%20Legend%20Retsuden%20%28Japan%29.3ds.7z"
        >
          3DS0782 - Bandai Namco Games Presents J Legend Retsuden (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0783%20-%20Cardfight%21%21%20Vanguard%20-%20Ride%20to%20Victory%21%21%20%28Japan%29.3ds.7z"
        >
          3DS0783 - Cardfight!! Vanguard - Ride to Victory!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0784%20-%20Hello%20Kitty%20to%20Sekai%20Ryokou%21%20Iron%20na%20Kuni%20e%20Odekake%20Shimasho%20%28Japan%29.3ds.7z"
        >
          3DS0784 - Hello Kitty to Sekai Ryokou! Iron na Kuni e Odekake Shimasho
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0785%20-%20Hometown%20Story%20%28Japan%29.3ds.7z"
        >
          3DS0785 - Hometown Story (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0786%20-%20Rhythm%20Kaitou%20R%20-%20Koutei%20Napoleon%20no%20Isan%20%28Japan%29.3ds.7z"
        >
          3DS0786 - Rhythm Kaitou R - Koutei Napoleon no Isan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0787%20-%20FabStyle%20%28Japan%29.3ds.7z"
        >
          3DS0787 - FabStyle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0788%20-%20Kamen%20Rider%20-%20Travelers%20Senki%20%28Japan%29.3ds.7z"
        >
          3DS0788 - Kamen Rider - Travelers Senki (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0789%20-%20Tamagotchi%20no%20Doki%20Doki%20Dream%20Omisetchi%20%28Japan%29.3ds.7z"
        >
          3DS0789 - Tamagotchi no Doki Doki Dream Omisetchi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0790%20-%20Disney%20Infinity%20%28Japan%29.3ds.7z"
        >
          3DS0790 - Disney Infinity (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0791%20-%20Exstetra%20%28Japan%29.3ds.7z"
        >
          3DS0791 - Exstetra (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0792%20-%20Koneko%20no%20Album%20-%20My%20Little%20Cat%20%28Japan%29.3ds.7z"
        >
          3DS0792 - Koneko no Album - My Little Cat (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0793%20-%20Nuigurumi%20no%20Ca_asan%20-%20Mahou%20no%20Patissiere%20%28Japan%29.3ds.7z"
        >
          3DS0793 - Nuigurumi no Ca_asan - Mahou no Patissiere (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0794%20-%20Sentouchu%20-%20Densetsu%20no%20Shinobi%20to%20Survival%20Battle%21%20%28Japan%29.3ds.7z"
        >
          3DS0794 - Sentouchu - Densetsu no Shinobi to Survival Battle!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0795%20-%20Barbie%20Dreamhouse%20Party%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0795 - Barbie Dreamhouse Party (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0796%20-%20Doodle%20Jump%20Adventures%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0796 - Doodle Jump Adventures (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0797%20-%20Horrid%20Henry%20-%20The%20Good%20The%20Bad%20and%20The%20Bugly%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0797 - Horrid Henry - The Good The Bad and The Bugly (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0798%20-%20Pac-Man%20and%20The%20Ghostly%20Adventures%20HD%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0798 - Pac-Man and The Ghostly Adventures HD (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0799%20-%20The%20Whitakers%20present%20-%20Milton%20and%20Friends%203D%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0799 - The Whitakers present - Milton and Friends 3D
          (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0800%20-%20Azito%203D%20%28Japan%29.3ds.7z"
        >
          3DS0800 - Azito 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0801%20-%20A-Ressha%20de%20Ikou%203D%20%28Japan%29.3ds.7z"
        >
          3DS0801 - A-Ressha de Ikou 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0802%20-%20CharaPet%20Tsukutte%21%20Sodatete%21%20Character%20Shougakkou%20%28Japan%29.3ds.7z"
        >
          3DS0802 - CharaPet Tsukutte! Sodatete! Character Shougakkou
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0803%20-%20Doraemon%20-%20Shin%20Nobita%20no%20Daimakyou%20%28Japan%29.3ds.7z"
        >
          3DS0803 - Doraemon - Shin Nobita no Daimakyou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0804%20-%20Hoppechan%20-%20Tsukutte%21%20Asonde%21%20Punipuni%20Town%21%21%20%28Japan%29.3ds.7z"
        >
          3DS0804 - Hoppechan - Tsukutte! Asonde! Punipuni Town!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0805%20-%20Karous%20-%20The%20Beast%20of%20Re-Eden%20%28Japan%29.3ds.7z"
        >
          3DS0805 - Karous - The Beast of Re-Eden (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0806%20-%20Conception%20II%20-%20Children%20of%20the%20Seven%20Stars%20%28USA%29.3ds.7z"
        >
          3DS0806 - Conception II - Children of the Seven Stars (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0807%20-%20Kaseki%20Horider%20-%20Mugen%20Gear%20%28Japan%29.3ds.7z"
        >
          3DS0807 - Kaseki Horider - Mugen Gear (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0808%20-%20LEGO%20Movie%20Videogame%2C%20The%20%28German%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0808 - LEGO Movie Videogame, The (German)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0809%20-%20LEGO%20The%20Hobbit%20%28German%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0809 - LEGO The Hobbit (German) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0810%20-%20Crayon%20Shin%20Chan%20-%20Arashi%20wo%20Yobu%20Kasukabe%20Eiga%20Stars%21%20%28Japan%29.3ds.7z"
        >
          3DS0810 - Crayon Shin Chan - Arashi wo Yobu Kasukabe Eiga Stars!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0811%20-%20Sudoku%20-%207%20Other%20Complex%20Puzzles%20By%20Nikoli%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0811 - Sudoku - 7 Other Complex Puzzles By Nikoli
          (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0812%20-%20Sonic%20-%20Lost%20World%20%28Japan%29.3ds.7z"
        >
          3DS0812 - Sonic - Lost World (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0813%20-%20Sayonara%20Umihara%20Kawase%20%28Japan%29.3ds.7z"
        >
          3DS0813 - Sayonara Umihara Kawase (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0814%20-%20Sims%203%2C%20The%20%28Japan%29.3ds.7z"
        >
          3DS0814 - Sims 3, The (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0815%20-%20Bravely%20Default%20-%20For%20the%20Sequel%20%28Japan%29.3ds.7z"
        >
          3DS0815 - Bravely Default - For the Sequel (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0816%20-%20Disney%20Magical%20World%20%28Usa%29%20.3ds.7z"
        >
          3DS0816 - Disney Magical World (Usa) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0817%20-%20Gardening%20Mama%20-%20Mama%20to%20Mori%20no%20Nakama%20Tachi%20%28Japan%29.3ds.7z"
        >
          3DS0817 - Gardening Mama - Mama to Mori no Nakama Tachi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0818%20-%20Metal%20Fight%20Beyblade%204D%20x%20ZEROG%20Ultimate%20Tournament%20%28Japan%29.3ds.7z"
        >
          3DS0818 - Metal Fight Beyblade 4D x ZEROG Ultimate Tournament
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0819%20-%20Professor%20Layton%20en%20de%20Erfenis%20van%20de%20Azran%20%28Netherlands%29.3ds.7z"
        >
          3DS0819 - Professor Layton en de Erfenis van de Azran
          (Netherlands).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0820%20-%20Kyokugen%20Dasshutsu%20ADV%20-%20Zennin%20Shiboudesu%20%28Japan%29.3ds.7z"
        >
          3DS0820 - Kyokugen Dasshutsu ADV - Zennin Shiboudesu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0821%20-%20Anpanman%20to%20Asobo%20-%20New%20Aiueo%20Kyou_su%20%28Japan%29.3ds.7z"
        >
          3DS0821 - Anpanman to Asobo - New Aiueo Kyou_su (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0822%20-%20Pro%20Evolution%20Soccer%202013%203D%20%28Italy%29%20%28Es%2CIt%2CPt%2CEl%29.3ds.7z"
        >
          3DS0822 - Pro Evolution Soccer 2013 3D (Italy) (Es,It,Pt,El).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0823%20-%20World%20Soccer%20Winning%20Eleven%202012%20%28Japan%29.3ds.7z"
        >
          3DS0823 - World Soccer Winning Eleven 2012 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0824%20-%20World%20Soccer%20Winning%20Eleven%202013%20%28Japan%29.3ds.7z"
        >
          3DS0824 - World Soccer Winning Eleven 2013 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0825%20-%20World%20Soccer%20Winning%20Eleven%202014%20%28Japan%29.3ds.7z"
        >
          3DS0825 - World Soccer Winning Eleven 2014 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0826%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Honolulu%20%28Japan%29.3ds.7z"
        >
          3DS0826 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Honolulu
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0827%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Narita%20with%20ANA%20%28Japan%29.3ds.7z"
        >
          3DS0827 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Narita with ANA
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0828%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Shin%20Chitose%20with%20JAL%28Japan%29.3ds.7z"
        >
          3DS0828 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Shin Chitose
          with JAL(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0829%20-%20Tamagotchi%21%20Session%20no%20Dream%20School%20%28Japan%29.3ds.7z"
        >
          3DS0829 - Tamagotchi! Session no Dream School (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0830%20-%20Moshi%20Monsters%20-%20Moshlings%20Theme%20Park%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0830 - Moshi Monsters - Moshlings Theme Park (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0831%20-%20Hello%20Kitty%20to%20Mahou%20no%20Apron%20-%20Rhythm%20Cooking%20%28Japan%29.3ds.7z"
        >
          3DS0831 - Hello Kitty to Mahou no Apron - Rhythm Cooking
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0832%20-%20Jewel%20Link%20-%20Legends%20of%20Atlantis%20%28Europe%29%28En%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS0832 - Jewel Link - Legends of Atlantis
          (Europe)(En,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0833%20-%20Funfair%20Party%20Games%20%28Germany%29%20%28De%2CFr%29.3ds.7z"
        >
          3DS0833 - Funfair Party Games (Germany) (De,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0834%20-%20Gyakuten%20Saiban%20123%20-%20Naruhodou%20Selection%20%28Japan%29.3ds.7z"
        >
          3DS0834 - Gyakuten Saiban 123 - Naruhodou Selection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0835%20-%20Meitantei%20Conan%20-%20Phantom%20Rhapsody%20%28Japan%29.3ds.7z"
        >
          3DS0835 - Meitantei Conan - Phantom Rhapsody (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0836%20-%20Nicola%20Kanshuu%20-%20Model%20Oshare%20Audition%20Platina%20%28Japan%29.3ds.7z"
        >
          3DS0836 - Nicola Kanshuu - Model Oshare Audition Platina
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0837%20-%20Hollywood%20Fame%20-%20Hidden%20Object%20Adventure%20%28Europe%29.3ds.7z"
        >
          3DS0837 - Hollywood Fame - Hidden Object Adventure (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0838%20-%20Nankou%20Furaku%20Sangokuden%20-%20Shu%20to%20Toki%20no%20Doujaku%20%28Japan%29.3ds.7z"
        >
          3DS0838 - Nankou Furaku Sangokuden - Shu to Toki no Doujaku
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0839%20-%20Nikoli%20no%20Sudoku%203D%20Dai-ni-Shuu%20-%208-tsu%20no%20Puzzle%20de%201000-Mon%20%28Japan%29.3ds.7z"
        >
          3DS0839 - Nikoli no Sudoku 3D Dai-ni-Shuu - 8-tsu no Puzzle de
          1000-Mon (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0840%20-%20Sudoku%20to%203-Tsu%20no%20Puzzle%20-%20Nikoli%20no%20Puzzle%20Variety%20%28Japan%29.3ds.7z"
        >
          3DS0840 - Sudoku to 3-Tsu no Puzzle - Nikoli no Puzzle Variety
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0841%20-%20LEGO%20Star%20Wars%20III%20-%20The%20Clone%20Wars%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CDa%29%20%28Rev%201%29.3ds.7z"
        >
          3DS0841 - LEGO Star Wars III - The Clone Wars (Europe)
          (En,Fr,De,Es,It,Da) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0842%20-%20Kodomo%20ni%20Anshin%20Shite%20Ataerareru%20Game%20Series%20-%20Sekai%20Meisaku%20Douwa%20-%20Oyako%20de%20Yomeru%20Game%20Ehon%20Bouken-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0842 - Kodomo ni Anshin Shite Ataerareru Game Series - Sekai
          Meisaku Douwa - Oyako de Yomeru Game Ehon Bouken-Hen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0843%20-%20Kodomo%20ni%20Anshin%20Shite%20Ataerareru%20Game%20Series%20-%20Sekai%20Meisaku%20Douwa%20-%20Oyako%20de%20Yomeru%20Game%20Ehon%20Princess-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0843 - Kodomo ni Anshin Shite Ataerareru Game Series - Sekai
          Meisaku Douwa - Oyako de Yomeru Game Ehon Princess-Hen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0844%20-%20Starry%20Sky%20-%20In%20Spring%203D%20%28Japan%29.3ds.7z"
        >
          3DS0844 - Starry Sky - In Spring 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0845%20-%20Starry%20Sky%20-%20In%20Summer%203D%20%28Japan%29.3ds.7z"
        >
          3DS0845 - Starry Sky - In Summer 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0846%20-%20Starry%20Sky%20-%20In%20Autumn%203D%20%28Japan%29.3ds.7z"
        >
          3DS0846 - Starry Sky - In Autumn 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0847%20-%20Starry%20Sky%20-%20In%20Winter%203D%20%28Japan%29.3ds.7z"
        >
          3DS0847 - Starry Sky - In Winter 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0848%20-%20Rayman%20Origins%20%28Japan%29.3ds.7z"
        >
          3DS0848 - Rayman Origins (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0849%20-%20Model%20Oshare%20Audition%20-%20Dream%20Girl%20%28Japan%29.3ds.7z"
        >
          3DS0849 - Model Oshare Audition - Dream Girl (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0850%20-%20Mamegoma%20Happy%21%20Sweets%20Farm%20%28Japan%29.3ds.7z"
        >
          3DS0850 - Mamegoma Happy! Sweets Farm (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0851%20-%20Penguin%20no%20Mondai%20Plus%20Bakushou%20Roulette%20Battle%20%28JPN%29.3ds.7z"
        >
          3DS0851 - Penguin no Mondai Plus Bakushou Roulette Battle (JPN).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0852%20-%20Mario%20Kart%207%20%28USA%29%20%28Rev%201%29.3ds.7z"
        >
          3DS0852 - Mario Kart 7 (USA) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0853%20-%20Tongari%20Boushi%20to%20Mahou%20no%20Machi%20%28Japan%29.3ds.7z"
        >
          3DS0853 - Tongari Boushi to Mahou no Machi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0854%20-%20Makai%20Ouji%20-%20Devils%20and%20Realist%20-%20Dairiou%20no%20Hihou%20%28Japan%29.3ds.7z"
        >
          3DS0854 - Makai Ouji - Devils and Realist - Dairiou no Hihou
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0855%20-%20Inazuma%20Eleven%203%20-%20Bomb%20Blast%20%28Europe%29%28Eng%2CSpa%2CIta%29.3ds.7z"
        >
          3DS0855 - Inazuma Eleven 3 - Bomb Blast (Europe)(Eng,Spa,Ita).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0856%20-%20Aikatsu%21%202-nin%20no%20My%20Princess%20%28Japan%29.3ds.7z"
        >
          3DS0856 - Aikatsu! 2-nin no My Princess (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0857%20-%20Jewel%20Master%20%28Japan%29.3ds.7z"
        >
          3DS0857 - Jewel Master (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0858%20-%20Lost%20Heroes%20%28Japan%29.3ds.7z"
        >
          3DS0858 - Lost Heroes (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0859%20-%20LEGO%20The%20Hobbit%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0859 - LEGO The Hobbit (Europe) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0860%20-%20Senran%20Kagura%20Burst%20-%20Guren%20no%20Shoujotachi%20%28Rev%201%29%28Japan%29.3ds.7z"
        >
          3DS0860 - Senran Kagura Burst - Guren no Shoujotachi (Rev
          1)(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0861%20-%20LEGO%20Movie%20Videogame%2C%20The%20%28Europe%29%20%28En%2CFr%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS0861 - LEGO Movie Videogame, The (Europe)
          (En,Fr,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0862%20-%20Disney%20Princess%20-%20My%20Fairytale%20Adventure%20%28Europe%29%20%28Sv%2CNo%2CDa%29.3ds.7z"
        >
          3DS0862 - Disney Princess - My Fairytale Adventure (Europe)
          (Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0863%20-%20Skylanders%20Giants%20%28Europe%29%20%28Sv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0863 - Skylanders Giants (Europe) (Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0864%20-%20Disney%20Infinity%20%28Europe%29%20%28En%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0864 - Disney Infinity (Europe) (En,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0865%20-%20Monster%20High%20-%2013%20Wishes%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0865 - Monster High - 13 Wishes (Europe)
          (En,Fr,De,Es,It,Nl,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0866%20-%20Guruguru%20Tamagotchi%21%20%28Japan%29.3ds.7z"
        >
          3DS0866 - Guruguru Tamagotchi! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0867%20-%20Minna%20de%20Auto%20Racing%203D%20%28Japan%29.3ds.7z"
        >
          3DS0867 - Minna de Auto Racing 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0868%20-%20Maple%20Story%20-%20Unmei%20no%20Shoujo%20%28Japan%29.3ds.7z"
        >
          3DS0868 - Maple Story - Unmei no Shoujo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0869%20-%20Theatrhythm%20Final%20Fantasy%20-%20Curtain%20Call%20%28Japan%29.3ds.7z"
        >
          3DS0869 - Theatrhythm Final Fantasy - Curtain Call (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0870%20-%20Zoo%20Keeper%203D%20%28Japan%29.3ds.7z"
        >
          3DS0870 - Zoo Keeper 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0871%20-%20Fishing%203D%20%28%20Japan%29.3ds.7z"
        >
          3DS0871 - Fishing 3D ( Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0872%20-%20DoraKazu%20-%20Nobita%20no%20Suuji%20Daibouken%20%28Japan%29.3ds.7z"
        >
          3DS0872 - DoraKazu - Nobita no Suuji Daibouken (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0873%20-%20Kawaii%20Koneko%203D%28Japan%29.3ds.7z"
        >
          3DS0873 - Kawaii Koneko 3D(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0874%20-%20Kira%20Meki%20Oshare%20Salon%21%20Watashi%20no%20Shigoto%20wa%20Biyoushi-San%20%28Japan%29.3ds.7z"
        >
          3DS0874 - Kira Meki Oshare Salon! Watashi no Shigoto wa Biyoushi-San
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0875%20-%20Oshare%20na%20Koinu%203D%20%28Japan%29.3ds.7z"
        >
          3DS0875 - Oshare na Koinu 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0876%20-%20UnchainBlades%20ReXX%20%28Japan%29.3ds.7z"
        >
          3DS0876 - UnchainBlades ReXX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0877%20-%20Mystery%20Case%20Files%20-%20Return%20to%20Ravenhearst%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0877 - Mystery Case Files - Return to Ravenhearst (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0878%20-%20Jewel%20Match%203%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0878 - Jewel Match 3 (Europe) (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0879%20-%20Jewel%20Quest%20-%20The%20Sapphire%20Dragon%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0879 - Jewel Quest - The Sapphire Dragon (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0880%20-%20Secret%20Agent%20Files%20-%20Miami%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0880 - Secret Agent Files - Miami (Europe) (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0881%20-%20Cocoto%20-%20Alien%20Brick%20Breaker%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0881 - Cocoto - Alien Brick Breaker (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0882%20-%20Elminage%20Gothic%203DS%20Remix%20-%20Ulm%20Zakir%20to%20Yami%20no%20Gishiki%20%28Japan%29.3ds.7z"
        >
          3DS0882 - Elminage Gothic 3DS Remix - Ulm Zakir to Yami no Gishiki
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0883%20-%20PachiPara%203D%20-%20Ooumi%20Monogatari%202%20-%20Pachi%20Pro%20Fuuunroku%20Hana%20-%20Kibou%20to%20Uragiri%20no%20Gakuen%20Seikatsu%20%28Japan%29.3ds.7z"
        >
          3DS0883 - PachiPara 3D - Ooumi Monogatari 2 - Pachi Pro Fuuunroku Hana
          - Kibou to Uragiri no Gakuen Seikatsu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0884%20-%20Ocha-Ken%20to%20Itsumo%20Nakayoshi%20%28Japan%29.3ds.7z"
        >
          3DS0884 - Ocha-Ken to Itsumo Nakayoshi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0885%20-%20SPEC%20-%20Kan%20%28Japan%29.3ds.7z"
        >
          3DS0885 - SPEC - Kan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0886%20-%20Mario%20Golf%20-%20World%20Tour%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS0886 - Mario Golf - World Tour (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0887%20-%20Ouchi%20Mainichi%20Tamagotchi%20%28Japan%29.3ds.7z"
        >
          3DS0887 - Ouchi Mainichi Tamagotchi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0888%20-%20Cho%20Rich%20Tamagotchi%20no_Puchi%20Puchi%20Omisetchi%20%28Japan%29.3ds.7z"
        >
          3DS0888 - Cho Rich Tamagotchi no_Puchi Puchi Omisetchi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0889%20-%20Mario%20Golf%20World%20Tour%20%28Japan%29.3ds.7z"
        >
          3DS0889 - Mario Golf World Tour (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0890%20-%20Wagamama%20Fashion%20-%20GirlsMode%20-%20Yokubari%20Sengen%21%20Tokimeki%20Up%21%20%28Japan%29.3ds.7z"
        >
          3DS0890 - Wagamama Fashion - GirlsMode - Yokubari Sengen! Tokimeki Up!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0891%20-%20JS%20Girl%20Doki%20Doki%20Model%20Challenge%28Japan%29.3ds.7z"
        >
          3DS0891 - JS Girl Doki Doki Model Challenge(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0892%20-%20Hidden%20Expedition%20-%20Titanic%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0892 - Hidden Expedition - Titanic (Europe) (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0893%20-%20Osawari%20Tantei%20Ozawa%20Rina%20Rising%203_Nameko%20wa%20Banana%20no%20Yume%20wo%20Miruka%20%28%20Japan%29.3ds.7z"
        >
          3DS0893 - Osawari Tantei Ozawa Rina Rising 3_Nameko wa Banana no Yume
          wo Miruka ( Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0894%20-%20Hometown%20Story%20%28Europe%20%29%20%28En%2CGe%2CFr%29.3ds.7z"
        >
          3DS0894 - Hometown Story (Europe ) (En,Ge,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0895%20-%20Shanghai%20Mahjong%20%28Europe%29%20%28Eng%29%29.3ds.7z"
        >
          3DS0895 - Shanghai Mahjong (Europe) (Eng)).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0896%20-%20Best%20of%20Arcade%20Games%28%20Europe%29%20%28%20En%2CGe%2CFr%2CSp%2CIt%2CNl%29.3ds.7z"
        >
          3DS0896 - Best of Arcade Games( Europe) ( En,Ge,Fr,Sp,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0897%20-%20Best_of%20Casual%20Games%20%28Europe%20%29%20%28%20En%2CGe%2CFr%2CSp%2CNl%2CIt%29%29.3ds.7z"
        >
          3DS0897 - Best_of Casual Games (Europe ) ( En,Ge,Fr,Sp,Nl,It)).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0898%20-%20Amazing%20Spider-Man%202%2C%20The%20%28USA%29.3ds.7z"
        >
          3DS0898 - Amazing Spider-Man 2, The (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0899%20-%20Kirby%20T_le%20Deluxe%203DS%20%28USA%29%20.3ds.7z"
        >
          3DS0899 - Kirby T_le Deluxe 3DS (USA) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0900%20-%20Neratte%21%20Toba_e%21%20Rilakkuma%20Guragura%20Sweets%20Tower%20%28Japan%29.3ds.7z"
        >
          3DS0900 - Neratte! Toba_e! Rilakkuma Guragura Sweets Tower
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0901%20-%20Kumamon%20Bomber%20Puzzle%20de%20Kumamon%20Taisou%20%28Japan%29.3ds.7z"
        >
          3DS0901 - Kumamon Bomber Puzzle de Kumamon Taisou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0902%20-%20Amazing%20Spider%20Man%202%20%2CThe%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0902 - Amazing Spider Man 2 ,The (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0903%20-%20Girls%20RPG%20-%20Cinderellife%20%28Japan%29.3ds.7z"
        >
          3DS0903 - Girls RPG - Cinderellife (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0904%20-%20Happy%20Animal%20Bokujou%20%28Japan%29.3ds.7z"
        >
          3DS0904 - Happy Animal Bokujou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0905%20-%20Madagascar%203%20%28Japan%29.3ds.7z"
        >
          3DS0905 - Madagascar 3 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0906%20-%20Smile%20Precure%21%20Lets%20Go%21%20Marchen%20World%20%28Japan%29.3ds.7z"
        >
          3DS0906 - Smile Precure! Lets Go! Marchen World (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0907%20-%20Wan%20Nyan%20Doubutsu%20Byouin%202%20%28Japan%29.3ds.7z"
        >
          3DS0907 - Wan Nyan Doubutsu Byouin 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0908%20-%20Kuroko%20no%20Basuke%20-%20Shouri%20e%20no%20Kiseki%20%28Japan%29.3ds.7z"
        >
          3DS0908 - Kuroko no Basuke - Shouri e no Kiseki (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0909%20-%20Splinter%20Cell%203D%20%28Japan%29.3ds.7z"
        >
          3DS0909 - Splinter Cell 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0910%20-%20Gardening%20Mama%202%20-%20Forest%20Friends%20%28USA%29.3ds.7z"
        >
          3DS0910 - Gardening Mama 2 - Forest Friends (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0911%20-%20Mario%20Golf%20World%20Tour%20%28Usa%29.3ds.7z"
        >
          3DS0911 - Mario Golf World Tour (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0912%20-%20Choujin%20Ultra%20Baseball%20Action%20Card%20Battle%20%28Japan%29.3ds.7z"
        >
          3DS0912 - Choujin Ultra Baseball Action Card Battle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0913%20-%20Etrian%20Odyssey%20Untold%20The%20Millennium%20Girl%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0913 - Etrian Odyssey Untold The Millennium Girl (Europe)
          (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0914%20-%20Ushimitsu%20Monstruo%20-%20Linze%20to%20Mahou%20no%20Rhythm%20%28Japan%29.3ds.7z"
        >
          3DS0914 - Ushimitsu Monstruo - Linze to Mahou no Rhythm (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0915%20-%20Legends%20of%20Oz%20-%20Dorothys%20Return%20%28Usa%29.3ds.7z"
        >
          3DS0915 - Legends of Oz - Dorothys Return (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0916%20-%20TOEIC%20Test%20Chousoku%20Training%20%28%20Japan%29.3ds.7z"
        >
          3DS0916 - TOEIC Test Chousoku Training ( Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0917%20-%20Pac-Man%20Party%203D%20%28Japan%29.3ds.7z"
        >
          3DS0917 - Pac-Man Party 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0918%20-%20Barbie%20-%20Fun%20%26%20Fashion%20Dogs%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0918 - Barbie - Fun &amp; Fashion Dogs (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0919%20-%20Me%20and%20My%20Furry%20Patients%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0919 - Me and My Furry Patients 3D (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0920%20-%203D%20Game%20Collection%20%28Europe%29%20%28Fr%2CDe%29.3ds.7z"
        >
          3DS0920 - 3D Game Collection (Europe) (Fr,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0921%20-%20Chevrolet%20Camaro%20Wild%20-%20Ride%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS0921 - Chevrolet Camaro Wild - Ride 3D (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0922%20-%20Chibi%20Devi%20%28Japan%29.3ds.7z"
        >
          3DS0922 - Chibi Devi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0923%20-%20My%20Baby%20Pet%20Hotel%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0923 - My Baby Pet Hotel 3D (Europe) (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0924%20-%20Jewel%20Pet%20-%20Mahou%20no%20Rhythm%20de%20Ieie%21%20%28Japan%29.3ds.7z"
        >
          3DS0924 - Jewel Pet - Mahou no Rhythm de Ieie! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0925%20-%20Jewel%20Pet%20-%20Mahou%20de%20Oshare%20ni%20Dance%20Deco%21%20%28Japan%29.3ds.7z"
        >
          3DS0925 - Jewel Pet - Mahou de Oshare ni Dance Deco! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0926%20-%20Jewel%20Pet%20-%20Cafe%20de%20Mahou%20no%20Cooking%21%20%28Japan%29.3ds.7z"
        >
          3DS0926 - Jewel Pet - Cafe de Mahou no Cooking! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0927%20-%20Mystery%20Case%20Files%20-%20Ravenhearst%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS0927 - Mystery Case Files - Ravenhearst (Europe)
          (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0928%20-%20Candy%20Match%203%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%2CDu%29.3ds.7z"
        >
          3DS0928 - Candy Match 3 (Europe)(En,Ge,Fr,Es,It,Du).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0929%20-%20Cooking%20Mama%204%20Kitchen%20Magic%20%28Europe%29%28En%2CGe%2CFr%2CSp%2CIt%29.3ds.7z"
        >
          3DS0929 - Cooking Mama 4 Kitchen Magic (Europe)(En,Ge,Fr,Sp,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0930%20-%20Kirby%20Triple%20Deluxe%20%20%28Europe%29%28En%2CGe%2CFr%2CSp%2CIt%29.3ds.7z"
        >
          3DS0930 - Kirby Triple Deluxe (Europe)(En,Ge,Fr,Sp,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0931%20-%20Doraemon%20-%20Nobita%20to%20Kiseki%20no%20Shima%20%28Japan%29.3ds.7z"
        >
          3DS0931 - Doraemon - Nobita to Kiseki no Shima (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0932%20-%20Putty%20Squad%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS0932 - Putty Squad (Europe)(En,Ge,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0933%20-%20Vitamin%20X%20-%20Evolution%20Plus%20%28Japan%29.3ds.7z"
        >
          3DS0933 - Vitamin X - Evolution Plus (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0934%20-%20Nightmare%20Puzzle%20-%20Crush%203D%20%28Japan%29.3ds.7z"
        >
          3DS0934 - Nightmare Puzzle - Crush 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0935%20-%20Real%20Heroes%20-%20Firefighter%203D%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS0935 - Real Heroes - Firefighter 3D (Europe)(En,Ge,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0936%20-%20Heavy%20Fire%20-%20The%20Chosen%20Few%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0936 - Heavy Fire - The Chosen Few 3D (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0937%20-%20Dora-Chie%20-%20Mini-Dora%20Ongakutai%20to%207-tsu%20no%20Chie%20%28Japan%29.3ds.7z"
        >
          3DS0937 - Dora-Chie - Mini-Dora Ongakutai to 7-tsu no Chie
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0938%20-%20Cube%2C%20The%20%28Europe%29%20%28En%2CIt%2CEs%29.3ds.7z"
        >
          3DS0938 - Cube, The (Europe) (En,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0939%20-%20Super%20Black%20Bass%203D%20Fight%20%28Japan%29.3ds.7z"
        >
          3DS0939 - Super Black Bass 3D Fight (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0940%20-%20Mystery%20Murders%20Jack%20The%20_per%20%28Europe%29%20%28En%2CFr%29.3ds.7z"
        >
          3DS0940 - Mystery Murders Jack The _per (Europe) (En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0941%20-%20World%20Soccer%20Winning%20Eleven%202014%20-%20Aoki%20Samurai%20no%20Chousen%20%28Japan%29.3ds.7z"
        >
          3DS0941 - World Soccer Winning Eleven 2014 - Aoki Samurai no Chousen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0942%20-%20Cooking%20Mama%205%20%28Japan%29.3ds.7z"
        >
          3DS0942 - Cooking Mama 5 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0943%20-%20Fish%20Eyes%203D%20%28Japan%29.3ds.7z"
        >
          3DS0943 - Fish Eyes 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0944%20-%20Oshigoto%20Theme%20Park%202%20%28Japan%29.3ds.7z"
        >
          3DS0944 - Oshigoto Theme Park 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0945%20-%20Putty%20Squad%20%28USA%29.3ds.7z"
        >
          3DS0945 - Putty Squad (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0946%20-%20Oshare%20Hamster%20to%20Kurasou%20-%20Issho%20ni%20Odekake%20%28Japan%29.3ds.7z"
        >
          3DS0946 - Oshare Hamster to Kurasou - Issho ni Odekake (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0947%20-%20Need%20for%20Speed%20-%20The%20Run%20%28Japan%29.3ds.7z"
        >
          3DS0947 - Need for Speed - The Run (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0948%20-%20Oresama%20Kingdom%20-%20Ikemen%20Kareshi%20o%20Get%20Shiyo%21%20Moe%20Kyun%20School%20Days%20%28Japan%29.3ds.7z"
        >
          3DS0948 - Oresama Kingdom - Ikemen Kareshi o Get Shiyo! Moe Kyun
          School Days (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0949%20-%20Ninja%20JaJaMaru-kun%20%28Japan%29.3ds.7z"
        >
          3DS0949 - Ninja JaJaMaru-kun (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0950%20-%20Tintin%20no%20Bouken%20-%20Unicorn-Gou%20no%20Himitsu%20%28Japan%29.3ds.7z"
        >
          3DS0950 - Tintin no Bouken - Unicorn-Gou no Himitsu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0951%20-%20Funfair%20Party%20Games%20%28Europe%29%20%28En%2CFr%29.3ds.7z"
        >
          3DS0951 - Funfair Party Games (Europe) (En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0952%20-%20Ryoume%20de%20Unou%20o%20Kitaeru%20-%203D%20Sokudoku%20Jutsu%20%28Japan%29.3ds.7z"
        >
          3DS0952 - Ryoume de Unou o Kitaeru - 3D Sokudoku Jutsu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0953%20-%20Shoshinsha%20kara%20Nippon%20Ichi%20made%20-%20Soroban%2C%20Anzan%2C%20Flash%20Anzan%20%28Japan%29.3ds.7z"
        >
          3DS0953 - Shoshinsha kara Nippon Ichi made - Soroban, Anzan, Flash
          Anzan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0954%20-%20Disney%20Princess%20-%20My%20Fairytale%20Adventure%20%28Europe%29%20%28En%2CFr%2CNl%29.3ds.7z"
        >
          3DS0954 - Disney Princess - My Fairytale Adventure (Europe)
          (En,Fr,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0955%20-%20Cardfight%21%21%20Vanguard%20-%20Lock%20On%20Victory%21%21%20%28Japan%29.3ds.7z"
        >
          3DS0955 - Cardfight!! Vanguard - Lock On Victory!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0956%20-%20Persona%20Q%20-%20Shadow%20of%20the%20Labyrinth%20%28Japan%29.3ds.7z"
        >
          3DS0956 - Persona Q - Shadow of the Labyrinth (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0957%20-%20Farming%20Simulator%2014%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CPt%2CRu%29.3ds.7z"
        >
          3DS0957 - Farming Simulator 14 (Europe) (En,Fr,De,Es,It,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0958%20-%20Tomodachi%20Life%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0958 - Tomodachi Life (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0959%20-%20Legends%20of%20Oz%20-%20Dorothys%20Return%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0959 - Legends of Oz - Dorothys Return (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0960%20-%20Cloudy%20With%20a%20Chance%20of%20Meatballs%202%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS0960 - Cloudy With a Chance of Meatballs 2 (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0961%20-%20Tomodachi%20Life%20%28Usa%29.3ds.7z"
        >
          3DS0961 - Tomodachi Life (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0962%20-%20Dog%20School%20-%20Lovely%20Puppy%20%28Japan%29.3ds.7z"
        >
          3DS0962 - Dog School - Lovely Puppy (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0963%20-%20Kipper%20no%20Eigo%20Kyou_su%20-%20Floppys%20Phonics%20Vol%201%20-%20Kipper-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0963 - Kipper no Eigo Kyou_su - Floppys Phonics Vol 1 - Kipper-Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0964%20-%20Kipper%20no%20Eigo%20Kyou_su%20-%20Floppys%20Phonics%20Vol%202%20-%20Biff-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0964 - Kipper no Eigo Kyou_su - Floppys Phonics Vol 2 - Biff-Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0965%20-%20Kipper%20no%20Eigo%20Kyou_su%20-%20Floppys%20Phonics%20Vol%203%20-%20Chip-Hen%20%28Japan%29.3ds.7z"
        >
          3DS0965 - Kipper no Eigo Kyou_su - Floppys Phonics Vol 3 - Chip-Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0966%20-%20Pika%20Pika%20Nurse%20Monogatari%202%20%28Japan%29.3ds.7z"
        >
          3DS0966 - Pika Pika Nurse Monogatari 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0967%20-%20Inazuma%20Eleven%20Go%20-%20Light%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS0967 - Inazuma Eleven Go - Light (Europe)(En,Ge,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0968%20-%20Inazuma%20Eleven%20Go%20-%20Shadow%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS0968 - Inazuma Eleven Go - Shadow (Europe)(En,Ge,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0969%20-%20How%20to%20Train%20Your%20Dragon%202%20%28USA%29.3ds.7z"
        >
          3DS0969 - How to Train Your Dragon 2 (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0970%20-%20Pokemon%20Art%20Academy%20%28Japan%29.3ds.7z"
        >
          3DS0970 - Pokemon Art Academy (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0971%20-%20Konchu%20Monster%20-%20Super%20Battle%20%28japan%29.3ds.7z"
        >
          3DS0971 - Konchu Monster - Super Battle (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0972%20-%20Moshi%20Monsters%20-%20Katsuma%20Unleashed%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS0972 - Moshi Monsters - Katsuma Unleashed (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0973%20-%20PachiPara%203D%20-%20Ooumi%20Monogatari%202%20with%20Agnes%20Lum%20%28Japan%29.3ds.7z"
        >
          3DS0973 - PachiPara 3D - Ooumi Monogatari 2 with Agnes Lum
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0974%20-%20PachiPara%203D%20-%20Premium%20Umi%20Monogatari%20%28Japan%29.3ds.7z"
        >
          3DS0974 - PachiPara 3D - Premium Umi Monogatari (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0975%20-%20Shifting%20World%20-%20Shiro%20to%20Kuro%20no%20Meikyuu%20%28Japan%29.3ds.7z"
        >
          3DS0975 - Shifting World - Shiro to Kuro no Meikyuu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0976%20-%20Taiko%20no%20Tatsujin%20-%20Don%20to%20Katsu%20no%20Jikuu%20Daibouken%20%28Japan%29.3ds.7z"
        >
          3DS0976 - Taiko no Tatsujin - Don to Katsu no Jikuu Daibouken
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0977%20-%20Girls%20Fashion%20Shoot%20%28Europe%29%20%28En%2CFr%29.3ds.7z"
        >
          3DS0977 - Girls Fashion Shoot (Europe) (En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0978%20-%20Mysterious%20Cities%20of%20Gold%20-%20Secret%20Paths%2C%20The%20%28Europe%29%28En%2CFr%2CDe%2CIt%2CEs%2CAr%2CPt%2CSe%2CNo%2CPl%29.3ds.7z"
        >
          3DS0978 - Mysterious Cities of Gold - Secret Paths, The
          (Europe)(En,Fr,De,It,Es,Ar,Pt,Se,No,Pl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0979%20-%20Hello%20Kitty%20Picnic%20with%20Sanrio%20Characters%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0979 - Hello Kitty Picnic with Sanrio Characters (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0980%20-%20Pac-World%20%28japan%29.3ds.7z"
        >
          3DS0980 - Pac-World (japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0981%20-%20One%20Piece%20-%20Unlimited%20World%20Red%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS0981 - One Piece - Unlimited World Red
          (Europe)(En,Ge,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0982%20-%20Amazing%20Spider-Man%2C%20The%20%28Europe%29%20%28En%2CEs%2CIt%29.3ds.7z"
        >
          3DS0982 - Amazing Spider-Man, The (Europe) (En,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0983%20-%20Kirameki%20Waku%20Waku%20Sweets%20%28Japan%29.3ds.7z"
        >
          3DS0983 - Kirameki Waku Waku Sweets (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0984%20-%20Driver%20-%20Renegade%203D%20%28Japan%29.3ds.7z"
        >
          3DS0984 - Driver - Renegade 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0985%20-%20Farming%20Simulator%2014%20%28Usa%29.3ds.7z"
        >
          3DS0985 - Farming Simulator 14 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0986%20-%20Transformers%20-%20Rise%20of%20the%20Dark%20Spark%20%28Usa%29.3ds.7z"
        >
          3DS0986 - Transformers - Rise of the Dark Spark (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0987%20-%20Transformers%20-%20Rise%20of%20the%20Dark%20Spark%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0987 - Transformers - Rise of the Dark Spark (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0988%20-%20Pro%20Evolution%20Soccer%202011%203D%20%28Europe%29%20%28Es%2CIt%29.3ds.7z"
        >
          3DS0988 - Pro Evolution Soccer 2011 3D (Europe) (Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0989%20-%20Vitamin%20Z%20Revolution%20%28Japan%29.3ds.7z"
        >
          3DS0989 - Vitamin Z Revolution (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0990%20-%20Jukugo%20-%20Sokubiki%20Jiten%20Yomenai%20Kanji%20mo%20Raku%20Raku%20Yomeru%20%28Japan%29.3ds.7z"
        >
          3DS0990 - Jukugo - Sokubiki Jiten Yomenai Kanji mo Raku Raku Yomeru
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0991%20-%20Bella%20Sara%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS0991 - Bella Sara 2 (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0992%20-%20Pokemon%20Art%20Academy%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0992 - Pokemon Art Academy (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0993%20-%20Island%20Days%20%28Japan%29.3ds.7z"
        >
          3DS0993 - Island Days (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0994%20-%20Yu-Gi-Oh%21%20Zexal%20-%20World%20Duel%20Carnival%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0994 - Yu-Gi-Oh! Zexal - World Duel Carnival (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0995%20-%20How%20to%20Train%20Your%20Dragon%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CPt%29.3ds.7z"
        >
          3DS0995 - How to Train Your Dragon 2 (Europe)
          (En,Fr,De,Es,It,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0996%20-%20Phineas%20and%20Ferb%20-%20Quest%20for%20Cool%20Stuff%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS0996 - Phineas and Ferb - Quest for Cool Stuff (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0997%20-%20One%20Piece%20-%20Unlimited%20World%20Red%20%28USA%29.3ds.7z"
        >
          3DS0997 - One Piece - Unlimited World Red (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0998%20-%20Youkai%20Watch%202%20-%20Ganso%20%28Japan%29.3ds.7z"
        >
          3DS0998 - Youkai Watch 2 - Ganso (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS0999%20-%20Youkai%20Watch%202%20-%20Honke%20%28Japan%29.3ds.7z"
        >
          3DS0999 - Youkai Watch 2 - Honke (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1000%20-%20Scooby-Doo%20and%20Looney%20Tunes%20Cartoon%20Universe%20-%20Adventure%20%28Usa%29.3ds.7z"
        >
          3DS1000 - Scooby-Doo and Looney Tunes Cartoon Universe - Adventure
          (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1001%20-%20Chibi%20Devi%202%20Mahou%20no%20Yume%20Ehon%20%28Japan%29.3ds.7z"
        >
          3DS1001 - Chibi Devi 2 Mahou no Yume Ehon (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1002%20-%20Nanami%20to%20Issho%20ni%20Manabo%21%20English%20Joutatsu%20no%20Kotsu%20%28Japan%29.3ds.7z"
        >
          3DS1002 - Nanami to Issho ni Manabo! English Joutatsu no Kotsu
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1003%20-%20Around%20the%20World%20with%20Hello%20Kitty%20and%20Friends%20%28Europe%29%20%28En%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS1003 - Around the World with Hello Kitty and Friends (Europe)
          (En,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1004%20-%20Gundam%20Try%20Age%20SP%20%28Japan%29.3ds.7z"
        >
          3DS1004 - Gundam Try Age SP (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1005%20-%20Hamatora%20-%20Look%20at%20Smoking%20World%20%28Japan%29.3ds.7z"
        >
          3DS1005 - Hamatora - Look at Smoking World (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1006%20-%20Hoppechan%20Minna%20de%20Odekake%21%20Waku%20Waku%20Hoppeland%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1006 - Hoppechan Minna de Odekake! Waku Waku Hoppeland!!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1007%20-%20Yoshis%20New%20Island%20%28Japan%29.3ds.7z"
        >
          3DS1007 - Yoshis New Island (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1008%20-%20Riding%20Stables%203D%20-%20Rivals%20in%20the%20Saddle%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CNl%2CSv%2CNo%2CDa%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1008 - Riding Stables 3D - Rivals in the Saddle (Europe)
          (En,Fr,De,It,Nl,Sv,No,Da) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1009%20-%20Happiness%20Charge%20PreCure%21%20Kawarun%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS1009 - Happiness Charge PreCure! Kawarun Collection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1010%20-%20LEGO%20Ninjago%20Nindroids%20%28Germany%29%20%28En%2CFr%2CDe%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1010 - LEGO Ninjago Nindroids (Germany) (En,Fr,De,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1011%20-%20LEGO%20Ninjago%20Nindroids%20%28USA%29.3ds.7z"
        >
          3DS1011 - LEGO Ninjago Nindroids (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1012%20-%20Thomas%20to%20Asonde%20Oboeru%20Kotoba%20to%20Kazu%20to%20ABC%20%28Japan%29.3ds.7z"
        >
          3DS1012 - Thomas to Asonde Oboeru Kotoba to Kazu to ABC (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1013%20-%20Fate%20Kaleid%20Liner%20-%20Prisma%20Illya%20%28Japan%29.3ds.7z"
        >
          3DS1013 - Fate Kaleid Liner - Prisma Illya (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1014%20-%20Soni%20Pro%20%28Japan%29.3ds.7z"
        >
          3DS1014 - Soni Pro (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1015%20-%20Teenage%20Mutant%20Ninja%20Turtles%20%28USA%29.3ds.7z"
        >
          3DS1015 - Teenage Mutant Ninja Turtles (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1016%20-%20Dragon%20Ball%20Heroes%20-%20Ultimate%20Mission%202%20%28Japan%29.3ds.7z"
        >
          3DS1016 - Dragon Ball Heroes - Ultimate Mission 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1017%20-%20Me%20%26%20My%20Pets%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1017 - Me &amp; My Pets 3D (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1018%20-%20Senran%20Kagura%202%20-%20Shinku%20%28Japan%29.3ds.7z"
        >
          3DS1018 - Senran Kagura 2 - Shinku (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1019%20-%20Farming%20Simulator%2014%20-%20Pocket%20Nouen%202%20%28Japan%29.3ds.7z"
        >
          3DS1019 - Farming Simulator 14 - Pocket Nouen 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1020%20-%20Pro%20Evolution%20Soccer%202012%203D%20%28Europe%29%20%28Es%2CIt%2CPt%2CEl%29.3ds.7z"
        >
          3DS1020 - Pro Evolution Soccer 2012 3D (Europe) (Es,It,Pt,El).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1021%20-%20LEGO%20Ninjago%20Nindroids%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1021 - LEGO Ninjago Nindroids (Europe)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1022%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Kashima%20Rinkai%20Tetsudou%20Hen%20%28Japan%29.3ds.7z"
        >
          3DS1022 - Tetsudou Nippon! Rosen Tabi - Kashima Rinkai Tetsudou Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1023%20-%20Atlantic%20Quest%20%28Europe%29%28En%2CGe%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS1023 - Atlantic Quest (Europe)(En,Ge,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1024%20-%20Teenage%20Mutant%20Ninja%20Turtles%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%29.3ds.7z"
        >
          3DS1024 - Teenage Mutant Ninja Turtles (Europe)
          (En,Fr,De,Es,It,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1025%20-%20Medarot%208%20-%20Kabuto%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS1025 - Medarot 8 - Kabuto Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1026%20-%20Medarot%208%20-%20Kuwagata%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS1026 - Medarot 8 - Kuwagata Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1027%20-%20Disney%20Frozen%20-%20Olaf%27s%20Quest%20%28Europe%29.3ds.7z"
        >
          3DS1027 - Disney Frozen - Olaf's Quest (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1028%20-%20Professor%20Layton%20vs%20Phoenix%20Wright%20Ace%20Attorney%20%28USA%29.3ds.7z"
        >
          3DS1028 - Professor Layton vs Phoenix Wright Ace Attorney (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1029%20-%20Gaist%20Crusher%20God%20%28Japan%29.3ds.7z"
        >
          3DS1029 - Gaist Crusher God (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1030%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Kankuu%20Sky%20Story%20%28Japan%29.3ds.7z"
        >
          3DS1030 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Kankuu Sky
          Story (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1031%20-%20Dairantou%20Smash%20Bros.%20for%20Nintendo%203DS%20%28Japan%29.3ds.7z"
        >
          3DS1031 - Dairantou Smash Bros. for Nintendo 3DS (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1032%20-%20Phonics%20Fun%20with%20Biff%2C%20Chip%20%26%20Kipper%20Vol.%201%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CPt%29.3ds.7z"
        >
          3DS1032 - Phonics Fun with Biff, Chip &amp; Kipper Vol. 1 (Europe)
          (En,Fr,De,Es,It,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1033%20-%20Phonics%20Fun%20with%20Biff%2C%20Chip%20%26%20Kipper%20Vol.%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CPt%29.3ds.7z"
        >
          3DS1033 - Phonics Fun with Biff, Chip &amp; Kipper Vol. 2 (Europe)
          (En,Fr,De,Es,It,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1034%20-%20Phonics%20Fun%20with%20Biff%2C%20Chip%20%26%20Kipper%20Vol.%203%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CPt%29.3ds.7z"
        >
          3DS1034 - Phonics Fun with Biff, Chip &amp; Kipper Vol. 3 (Europe)
          (En,Fr,De,Es,It,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1035%20-%20Theatrhythm%20Final%20Fantasy%20-%20Curtain%20Call%20%28USA%29.3ds.7z"
        >
          3DS1035 - Theatrhythm Final Fantasy - Curtain Call (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1036%20-%20Outback%20Pet%20Rescue%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1036 - Outback Pet Rescue 3D (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1037%20-%20Theatrhythm%20Final%20Fantasy%20-%20Curtain%20Call%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS1037 - Theatrhythm Final Fantasy - Curtain Call (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1038%20-%20Cooking%20Mama%205%20-%20Bon%20Appetit%21%20%28USA%29.3ds.7z"
        >
          3DS1038 - Cooking Mama 5 - Bon Appetit! (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1039%20-%20LEGO%20Batman%202%20-%20DC%20Super%20Heroes%20%28v01%29%20%28Europe%29%28En%2CFr%2CEs%2CGe%2CIt%2CDu%2CDa%29.3ds.7z"
        >
          3DS1039 - LEGO Batman 2 - DC Super Heroes (v01)
          (Europe)(En,Fr,Es,Ge,It,Du,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1040%20-%20Pets%20Paradise%20Resort%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1040 - Pets Paradise Resort 3D (Europe) (En,Fr,De,Es,It,Nl) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1041%20-%20Biohazard%20-%20Revelations%20%28South%20Korea%29.3ds.7z"
        >
          3DS1041 - Biohazard - Revelations (South Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1042%20-%20Fantasy%20Life%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1042 - Fantasy Life (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1043%20-%20FIFA%2015%20-%20Legacy%20Edition%20%28Europe%29%28En%2CEs%2CIt%29.3ds.7z"
        >
          3DS1043 - FIFA 15 - Legacy Edition (Europe)(En,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1044%20-%20Tenkai%20Knights%20-%20Brave%20Battle%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1044 - Tenkai Knights - Brave Battle (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1045%20-%20Hello%20Kitty%20Happy%20Happy%20Family%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1045 - Hello Kitty Happy Happy Family (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1046%20-%20My%20Foal%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1046 - My Foal 3D (Europe) (En,Fr,De,Es,It,Nl) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1047%20-%20FIFA%2015%20-%20Legacy%20Edition%20%28Europe%29%28En%2CFr%2CDu%29.3ds.7z"
        >
          3DS1047 - FIFA 15 - Legacy Edition (Europe)(En,Fr,Du).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1048%20-%20FIFA%2015%20-%20Legacy%20Edition%20%28USA%29%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS1048 - FIFA 15 - Legacy Edition (USA)(En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1049%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28Europe%29%28En%2CFr%2CEs%2CGe%2CIt%2CDu%2CPo%2CRu%29.3ds.7z"
        >
          3DS1049 - Super Smash Bros. for Nintendo 3DS
          (Europe)(En,Fr,Es,Ge,It,Du,Po,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1050%20-%20Haikyu%21%21%20Tsunage%21%20Itadaki%20no%20Keshiki%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1050 - Haikyu!! Tsunage! Itadaki no Keshiki!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1051%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28USA%29.3ds.7z"
        >
          3DS1051 - Super Smash Bros. for Nintendo 3DS (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1052%20-%20Kinki%20no%20Magna%20%28Japan%29.3ds.7z"
        >
          3DS1052 - Kinki no Magna (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1053%20-%20Tenkai%20Knights%20-%20Brave%20Battle%20%28Japan%29.3ds.7z"
        >
          3DS1053 - Tenkai Knights - Brave Battle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1054%20-%20Disney%20Violetta%20-%20Rhythm%20%26%20Music%20%28Europe%29%28En%2CFr%2CEs%2CGe%2CIt%2CPo%29.3ds.7z"
        >
          3DS1054 - Disney Violetta - Rhythm &amp; Music
          (Europe)(En,Fr,Es,Ge,It,Po).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1055%20-%20Trash%20Pack%2C%20The%20%28Europe%29.3ds.7z"
        >
          3DS1055 - Trash Pack, The (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1056%20-%20Nazotoki%20Battle%20TORE%21%20Densetsu%20no%20Makyuu%20o%20Fukkatsu%20Saseyo%21%20%28Japan%29.3ds.7z"
        >
          3DS1056 - Nazotoki Battle TORE! Densetsu no Makyuu o Fukkatsu Saseyo!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1057%20-%20Scribblenauts%20Unmasked%20-%20A%20DC%20Comics%20Adventure%20%28Europe%29.3ds.7z"
        >
          3DS1057 - Scribblenauts Unmasked - A DC Comics Adventure
          (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1058%20-%20Tenkai%20Knights%20-%20Brave%20Battle%20%28USA%29%20%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS1058 - Tenkai Knights - Brave Battle (USA) (En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1059%20-%20Rayman%20and%20Rabbids%20Family%20Pack%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS1059 - Rayman and Rabbids Family Pack (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1060%20-%20Monster%20Hunter%204G%20%28Japan%29.3ds.7z"
        >
          3DS1060 - Monster Hunter 4G (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1061%20-%20Pac-Man%20and%20the%20Ghostly%20Adventures%202%20%28USA%29.3ds.7z"
        >
          3DS1061 - Pac-Man and the Ghostly Adventures 2 (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1062%20-%20Majin%20Bone%20-%20Jikan%20to%20Kuukan%20no%20Majin%20%28Japan%29.3ds.7z"
        >
          3DS1062 - Majin Bone - Jikan to Kuukan no Majin (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1063%20-%20Skylanders%20Trap%20Team%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS1063 - Skylanders Trap Team (Europe)
          (En,Fr,De,Es,It,Nl,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1064%20-%202in1%20-%20My%20Vet%20Practice%203D%20-%20In%20the%20Country%20%20%20My%20Baby%20Pet%20Hotel%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1064 - 2in1 - My Vet Practice 3D - In the Country My Baby Pet Hotel
          3D (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1065%20-%20Monster%20Hunter%204G%20%28China%29.3ds.7z"
        >
          3DS1065 - Monster Hunter 4G (China).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1066%20-%20Winx%20Club%20-%20Saving%20Alfea%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CPt%29.3ds.7z"
        >
          3DS1066 - Winx Club - Saving Alfea (Europe) (En,Fr,De,Es,It,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1067%20-%20Petz%20Beach%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1067 - Petz Beach (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1068%20-%20Petz%20Countryside%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1068 - Petz Countryside (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1069%20-%20Survivor%20Heroes%28Europe%29%28En%2CFr%2CEs%2CIt%2CDa%2CSv%2CNl%2CSw%2CTr%29.3ds.7z"
        >
          3DS1069 - Survivor Heroes(Europe)(En,Fr,Es,It,Da,Sv,Nl,Sw,Tr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1070%20-%20Youkai%20Watch%202%20-%20Honke%20%28v01%29%20%28Japan%29.3ds.7z"
        >
          3DS1070 - Youkai Watch 2 - Honke (v01) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1071%20-%20Youkai%20Watch%202%20-%20Ganso%20%28v03%29%20%28Japan%29.3ds.7z"
        >
          3DS1071 - Youkai Watch 2 - Ganso (v03) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1072%20-%20Youkai%20Watch%202%20-%20Honke%20%28v03%29%20%28Japan%29.3ds.7z"
        >
          3DS1072 - Youkai Watch 2 - Honke (v03) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1073%20-%20Youkai%20Watch%202%20-%20Honke%20%28v02%29%20%28Japan%29.3ds.7z"
        >
          3DS1073 - Youkai Watch 2 - Honke (v02) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1074%20-%20Youkai%20Watch%202%20-%20Ganso%20%28v02%29%20%28Japan%29.3ds.7z"
        >
          3DS1074 - Youkai Watch 2 - Ganso (v02) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1075%20-%20Youkai%20Watch%202%20-%20Ganso%20%28v01%29%20%28Japan%29.3ds.7z"
        >
          3DS1075 - Youkai Watch 2 - Ganso (v01) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1076%20-%20Kouekizaidan%20Houjin%20Nihon%20Kanji%20Nouryoku%20Kentei%20Kyoukai%20-%20Kanken%20Training%20%28Japan%29.3ds.7z"
        >
          3DS1076 - Kouekizaidan Houjin Nihon Kanji Nouryoku Kentei Kyoukai -
          Kanken Training (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1077%20-%20Poptropica%20Forgotten%20Islands%20%28Usa%29.3ds.7z"
        >
          3DS1077 - Poptropica Forgotten Islands (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1078%20-%20Skylanders%20Trap%20Team%20%28Usa%29.3ds.7z"
        >
          3DS1078 - Skylanders Trap Team (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1079%20-%20Petz%20Beach%20%28Usa%29.3ds.7z"
        >
          3DS1079 - Petz Beach (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1080%20-%20Duck%20Dynasty%20%28Usa%29.3ds.7z"
        >
          3DS1080 - Duck Dynasty (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1081%20-%20Pac-Man%20and%20the%20Ghostly%20Adventures%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1081 - Pac-Man and the Ghostly Adventures 2 (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1082%20-%20Power%20Rangers%20-%20Super%20Megaforce%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1082 - Power Rangers - Super Megaforce (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1083%20-%20Petz%20Countryside%20%28USA%29.3ds.7z"
        >
          3DS1083 - Petz Countryside (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1084%20-%20Disney%20Magical%20World%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1084 - Disney Magical World (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1085%20-%20Pokemon%20Art%20Academy%20%28USA%29.3ds.7z"
        >
          3DS1085 - Pokemon Art Academy (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1086%20-%20Tales%20of%20the%20World%20-%20Reve%20Unitia%20%28Japan%29.3ds.7z"
        >
          3DS1086 - Tales of the World - Reve Unitia (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1087%20-%20Legend%20of%20Korra%20-%20A%20New%20Era%20Begins%2C%20The%20%28USA%29.3ds.7z"
        >
          3DS1087 - Legend of Korra - A New Era Begins, The (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1088%20-%20Chocoken%20no%20Chokotto%20Fushigi%20na%20Monogatari%20-%20Chocolat-hime%20to%20Mahou%20no%20Recipe%20%28Japan%29.3ds.7z"
        >
          3DS1088 - Chocoken no Chokotto Fushigi na Monogatari - Chocolat-hime
          to Mahou no Recipe (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1089%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Eizan%20Densha%20Hen%20%28Japan%29.3ds.7z"
        >
          3DS1089 - Tetsudou Nippon! Rosen Tabi - Eizan Densha Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1090%20-%20Top%20Model%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1090 - Top Model 3D (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1091%20-%20Teenage%20Mutant%20Ninja%20Turtles%20-%20Danger%20of%20the%20Ooze%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%29.3ds.7z"
        >
          3DS1091 - Teenage Mutant Ninja Turtles - Danger of the Ooze (Europe)
          (En,Fr,De,Es,It,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1092%20-%20Big%20Hero%206%20-%20Battle%20in%20the%20Bay%20%28USA%29.3ds.7z"
        >
          3DS1092 - Big Hero 6 - Battle in the Bay (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1093%20-%20I%20Love%20My%20Horse%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS1093 - I Love My Horse (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1094%20-%20I%20Love%20My%20Little%20Girl%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS1094 - I Love My Little Girl (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1095%20-%20I%20Love%20My%20Little%20Boy%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS1095 - I Love My Little Boy (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1096%20-%20Power%20Rangers%20-%20Super%20Megaforce%20%28USA%29.3ds.7z"
        >
          3DS1096 - Power Rangers - Super Megaforce (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1097%20-%20Harvest%20Moon%203D%20-%20The%20Lost%20Valley%20%28USA%29.3ds.7z"
        >
          3DS1097 - Harvest Moon 3D - The Lost Valley (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1098%20-%20Horse%20Vet%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1098 - Horse Vet 3D (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1099%20-%20Ultimate%20NES%20Remix%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1099 - Ultimate NES Remix (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1100%20-%20Disney%20Planes%20-%20Fire%20and%20Rescue%20%28Usa%29.3ds.7z"
        >
          3DS1100 - Disney Planes - Fire and Rescue (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1101%20-%20LEGO%20Batman%203%20-%20Beyond%20Gotham%20%28USA%29%20%28En%2CFr%2CEs%2CPt%29.3ds.7z"
        >
          3DS1101 - LEGO Batman 3 - Beyond Gotham (USA) (En,Fr,Es,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1102%20-%20Sonic%20Boom%20-%20Shattered%20Crystal%20%28USA%29.3ds.7z"
        >
          3DS1102 - Sonic Boom - Shattered Crystal (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1103%20-%20Riding%20Star%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1103 - Riding Star 3D (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1104%20-%20Teenage%20Mutant%20Ninja%20Turtles%20-%20Danger%20of%20the%20Ooze%20%28USA%29.3ds.7z"
        >
          3DS1104 - Teenage Mutant Ninja Turtles - Danger of the Ooze
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1105%20-%20LEGO%20Batman%203%20-%20Jenseits%20von%20Gotham%28Europe%29%28En%2CFr%2CGe%2CNl%2CEs%2CIt%2CDa%29.3ds.7z"
        >
          3DS1105 - LEGO Batman 3 - Jenseits von
          Gotham(Europe)(En,Fr,Ge,Nl,Es,It,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1106%20-%20Disney%20Planes%20-%20Fire%20%26%20Rescue%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1106 - Disney Planes - Fire &amp; Rescue (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1107%20-%20Tetris%20Ultimate%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1107 - Tetris Ultimate (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1108%20-%20Tetris%20Ultimate%20%28USA%29.3ds.7z"
        >
          3DS1108 - Tetris Ultimate (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1109%20-%20LEGO%20Batman%203%20-%20Beyond%20Gotham%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1109 - LEGO Batman 3 - Beyond Gotham (Europe)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1110%20-%20Adventure%20Time%20-%20The%20Secret%20of%20the%20Nameless%20Kingdom%20%28USA%29.3ds.7z"
        >
          3DS1110 - Adventure Time - The Secret of the Nameless Kingdom
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1111%20-%20Persona%20Q%20-%20Shadow%20of%20the%20Labyrinth%20%28USA%29.3ds.7z"
        >
          3DS1111 - Persona Q - Shadow of the Labyrinth (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1112%20-%20Hello%20Kitty%20and%20Sanrio%20Friends%203D%20Racing%20%28Usa%29.3ds.7z"
        >
          3DS1112 - Hello Kitty and Sanrio Friends 3D Racing (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1113%20-%20Winx%20Club%20-%20Saving%20Alfea%20%28USA%29.3ds.7z"
        >
          3DS1113 - Winx Club - Saving Alfea (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1114%20-%20One%20Piece%20-%20Super%20Grand%20Battle%21%20X%20%28Japan%29.3ds.7z"
        >
          3DS1114 - One Piece - Super Grand Battle! X (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1115%20-%20Safari%20Quest%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1115 - Safari Quest (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1116%20-%20Inazuma%20Eleven%203%20-%20Team%20Ogre%20Attacks%21%20%28Europe%29%28En%2CEs%2CIt%29.3ds.7z"
        >
          3DS1116 - Inazuma Eleven 3 - Team Ogre Attacks!
          (Europe)(En,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1117%20-%20Pokemon%20Alpha%20Sapphire%20%28Japan%29.3dz.7z"
        >
          3DS1117 - Pokemon Alpha Sapphire (Japan).3dz.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1118%20-%20Pokemon%20Omega%20Ruby%20%28Japan%29.3dz.7z"
        >
          3DS1118 - Pokemon Omega Ruby (Japan).3dz.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1119%20-%20Persona%20Q%20-%20Shadow%20of%20the%20Labyrinth%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS1119 - Persona Q - Shadow of the Labyrinth (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1120%20-%20Sonic%20Boom%20-%20Shattered%20Crystal%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1120 - Sonic Boom - Shattered Crystal (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1121%20-%20My%20Life%20On%20A%20Farm%20%28Europe%29%28En%2CFr%2CGe%2CIt%29.3ds.7z"
        >
          3DS1121 - My Life On A Farm (Europe)(En,Fr,Ge,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1122%20-%20Fantasy%20Life%20%28USA%29.3ds.7z"
        >
          3DS1122 - Fantasy Life (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1123%20-%20Pokemon%20Alpha%20Sapphire%20%28USA%29%28En%2CFr%2CGe%2CIt%2CJa%2CKo%2CEs%29.3ds.7z"
        >
          3DS1123 - Pokemon Alpha Sapphire (USA)(En,Fr,Ge,It,Ja,Ko,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1124%20-%20Pokemon%20Omega%20Ruby%20%28USA%29%28En%2CFr%2CGe%2CIt%2CJa%2CKo%2CEs%29.3ds.7z"
        >
          3DS1124 - Pokemon Omega Ruby (USA)(En,Fr,Ge,It,Ja,Ko,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1125%20-%20Penguins%20of%20Madagascar%20%28USA%29%20%28En%29.3ds.7z"
        >
          3DS1125 - Penguins of Madagascar (USA) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1126%20-%20LEGO%20The%20Hobbit%20%28France%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1126 - LEGO The Hobbit (France) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1127%20-%20LEGO%20Marvel%20Super%20Heroes%20-%20Universe%20in%20Peril%20%28France%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1127 - LEGO Marvel Super Heroes - Universe in Peril (France)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1128%20-%20Pokemon%20Alpha%20Sapphire%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CJa%2CKo%2CEs%29.3ds.7z"
        >
          3DS1128 - Pokemon Alpha Sapphire (Europe)(En,Fr,Ge,It,Ja,Ko,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1129%20-%20Pokemon%20Omega%20Ruby%20%28Europe%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS1129 - Pokemon Omega Ruby (Europe) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1130%20-%20Disney%20Planes%20%28Europe%29%20%28En%2CRu%29.3ds.7z"
        >
          3DS1130 - Disney Planes (Europe) (En,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1131%20-%20Penguins%20of%20Madagascar%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1131 - Penguins of Madagascar (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1132%20-%20Asterix%20The%20Mansions%20Of%20The%20Gods%20%28Europe%29%28En%2CFr%2CGe%2CNl%2CEs%2CIt%29.3ds.7z"
        >
          3DS1132 - Asterix The Mansions Of The Gods
          (Europe)(En,Fr,Ge,Nl,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1133%20-%20Die%20drei%20Fragezeichen%20Kids%20-%20Jagd%20auf%20das%20Phantom%20%28Germany%29%20%28De%29.3ds.7z"
        >
          3DS1133 - Die drei Fragezeichen Kids - Jagd auf das Phantom (Germany)
          (De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1134%20-%20Hero%20Bank%202%20%28Japan%29.3ds.7z"
        >
          3DS1134 - Hero Bank 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1135%20-%20Adventure%20Time%20-%20The%20Secret%20of%20the%20Nameless%20Kingdom%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS1135 - Adventure Time - The Secret of the Nameless Kingdom (Europe)
          (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1136%20-%20Shin%20Sekaiju%20no%20Meikyuu%202%20-%20Fafnir%20no%20Kishi%20%28Japan%29.3ds.7z"
        >
          3DS1136 - Shin Sekaiju no Meikyuu 2 - Fafnir no Kishi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1137%20-%20Inazuma%20Eleven%203%20-%20Lightning%20Bolt%20%28Europe%29%28En%2CEs%2CIt%29.3ds.7z"
        >
          3DS1137 - Inazuma Eleven 3 - Lightning Bolt (Europe)(En,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1138%20-%20Elminage%20Ibun%20-%20Ame%20no%20Mihashira%20Kai%20%28Japan%29.3ds.7z"
        >
          3DS1138 - Elminage Ibun - Ame no Mihashira Kai (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1139%20-%20Anpanman%20to%20Touch%20de%20Waku%20Waku%20Training%20%28Japan%29.3ds.7z"
        >
          3DS1139 - Anpanman to Touch de Waku Waku Training (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1140%20-%20DoraEigo%20-%20Nobita%20to%20Yousei%20no%20Fushigi%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS1140 - DoraEigo - Nobita to Yousei no Fushigi Collection
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1141%20-%20MahoCole%20-%20Mahou%20Idol%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS1141 - MahoCole - Mahou Idol Collection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1142%20-%20Osawari%20Tantei%20Ozawa%20Rina%20Nameko%20Rhythm%20%28Japan%29.3ds.7z"
        >
          3DS1142 - Osawari Tantei Ozawa Rina Nameko Rhythm (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1143%20-%20Shingeki%20no%20Kyojin%20-%20Jinrui%20Saigo%20no%20Tsubasa%20CHAIN%20%28Japan%29.3ds.7z"
        >
          3DS1143 - Shingeki no Kyojin - Jinrui Saigo no Tsubasa CHAIN
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1144%20-%20Fujiko%20F.%20Fujio%20Characters%20Daishuugou%21%20SF%20Dotabata%20Party%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1144 - Fujiko F. Fujio Characters Daishuugou! SF Dotabata Party!!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1145%20-%20Sengoku%20Musou%20Chronicle%203%20%28Japan%29.3ds.7z"
        >
          3DS1145 - Sengoku Musou Chronicle 3 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1146%20-%20Aikatsu%21%20365-nichi%20no%20Idol%20Days%20%28Japan%29.3ds.7z"
        >
          3DS1146 - Aikatsu! 365-nichi no Idol Days (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1147%20-%20Derby%20Stallion%20Gold%20%28Japan%29.3ds.7z"
        >
          3DS1147 - Derby Stallion Gold (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1148%20-%20WRC%20-%20FIA%20World%20Rally%20Championship%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1148 - WRC - FIA World Rally Championship (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1149%20-%20Youkai%20Watch%202%20-%20Shinuchi%20%28Japan%29.3ds.7z"
        >
          3DS1149 - Youkai Watch 2 - Shinuchi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1150%20-%20Ultimate%20NES%20Remix%20%28USA%29.3ds.7z"
        >
          3DS1150 - Ultimate NES Remix (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1151%20-%20Crazy%20Construction%20%28Europe%29%20%28En%2CFr%2CDe%29.3ds.7z"
        >
          3DS1151 - Crazy Construction (Europe) (En,Fr,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1152%20-%20Gotouchi%20Tetsudou%20-%20Gotouchi%20Chara%20to%20Nihon%20Zenkoku%20no%20Tabi%20%28Japan%29.3ds.7z"
        >
          3DS1152 - Gotouchi Tetsudou - Gotouchi Chara to Nihon Zenkoku no Tabi
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1153%20-%20Cooking%20Mama%20-%20Watashi%20no%20Sweets%20Shop%20%28Japan%29.3ds.7z"
        >
          3DS1153 - Cooking Mama - Watashi no Sweets Shop (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1154%20-%20Scribblenauts%20Unlimited%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS1154 - Scribblenauts Unlimited (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1155%20-%20Final%20Fantasy%20Explorers%20%28Japan%29.3ds.7z"
        >
          3DS1155 - Final Fantasy Explorers (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1156%20-%20Sonic%20Toon%20-%20Island%20Adventure%20%28Japan%29.3ds.7z"
        >
          3DS1156 - Sonic Toon - Island Adventure (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1157%20-%20Big%20Hero%206%20-%20Battle%20in%20the%20Bay%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1157 - Big Hero 6 - Battle in the Bay (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1158%20-%20Pac-World%202%20%28Japan%29.3ds.7z"
        >
          3DS1158 - Pac-World 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1159%20-%20Syunsoku%20Mezase%21%20Zenkoku%20Saikyou%20Runner%20%28Japan%29.3ds.7z"
        >
          3DS1159 - Syunsoku Mezase! Zenkoku Saikyou Runner (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1160%20-%2012-Sai.%20Honto%20no%20Kimochi%20%28Japan%29.3ds.7z"
        >
          3DS1160 - 12-Sai. Honto no Kimochi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1161%20-%20Sumikko%20Gurashi%20-%20Koko%20ga%20Ochitsukundesu%20%28Japan%29.3ds.7z"
        >
          3DS1161 - Sumikko Gurashi - Koko ga Ochitsukundesu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1162%20-%20Wannyan%20Pet%20Shop%20%28Japan%29.3ds.7z"
        >
          3DS1162 - Wannyan Pet Shop (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1163%20-%20Wan%20Nyan%20Pet%20Shop%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1163 - Wan Nyan Pet Shop (Japan) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1164%20-%20Mario%20Kart%207%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1164 - Mario Kart 7 (Japan) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1165%20-%20Dolly%20Kanon%20Dokidoki%20Tokimeki%20Himitsu%20no%20Ongaku%20Katsudou%20Start%20Desu%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1165 - Dolly Kanon Dokidoki Tokimeki Himitsu no Ongaku Katsudou
          Start Desu!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1166%20-%20Relaxuma%20Nakayoshi%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS1166 - Relaxuma Nakayoshi Collection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1167%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Narita%20All%20Stars%20%28Japan%29.3ds.7z"
        >
          3DS1167 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Narita All
          Stars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1168%20-%20Legend%20of%20Zelda%2C%20The%20-%20Ocarina%20of%20Time%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1168 - Legend of Zelda, The - Ocarina of Time 3D (Europe)
          (En,Fr,De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1169%20-%20Soroban%20-%20Anzan%20-%20Flash%20Anzan%20Kanzenban%20%28Japan%29.3ds.7z"
        >
          3DS1169 - Soroban - Anzan - Flash Anzan Kanzenban (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1170%20-%20Kenka%20Banchou%206%20-%20Soul%20%26%20Blood%20%28Japan%29.3ds.7z"
        >
          3DS1170 - Kenka Banchou 6 - Soul &amp; Blood (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1171%20-%20Zelda%20no%20Densetsu%20-%20Toki%20no%20Ocarina%203D%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1171 - Zelda no Densetsu - Toki no Ocarina 3D (Japan) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1172%20-%20LEGO%20Movie%20Videogame%2C%20The%20%28Japan%29.3ds.7z"
        >
          3DS1172 - LEGO Movie Videogame, The (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1173%20-%20Sega%203D%20Fukkoku%20Archives%20%28Japan%29.3ds.7z"
        >
          3DS1173 - Sega 3D Fukkoku Archives (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1174%20-%20Imagine%20Collection%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CNl%2CSv%2CNo%2CDa%29.3ds.7z"
        >
          3DS1174 - Imagine Collection (Europe) (En,Fr,De,Es,Nl,Sv,No,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1175%20-%20Legend%20of%20Legacy%2C%20The%20%28Japan%29.3ds.7z"
        >
          3DS1175 - Legend of Legacy, The (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1176%20-%20LEGO%20Marvel%20Super%20Heroes%20-%20The%20Game%20%28Japan%29.3ds.7z"
        >
          3DS1176 - LEGO Marvel Super Heroes - The Game (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1177%20-%20Pilotwings%20Resort%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1177 - Pilotwings Resort (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1178%20-%20Devil%20Survivor%202%20-%20Break%20Record%20%28Japan%29.3ds.7z"
        >
          3DS1178 - Devil Survivor 2 - Break Record (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1179%20-%20SpongeBob%20HeroPants%20%28Usa%29.3ds.7z"
        >
          3DS1179 - SpongeBob HeroPants (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1180%20-%20Ace%20Combat%203D%20-%20Cross%20Rumble%20%20%28Japan%29.3ds.7z"
        >
          3DS1180 - Ace Combat 3D - Cross Rumble (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1181%20-%20Lost%20Heroes%202%20%28Japan%29.3ds.7z"
        >
          3DS1181 - Lost Heroes 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1182%20-%20Yowamushi%20Pedal%20-%20A_a%20e%20no%20High%20Cadence%20%28Japan%29.3ds.7z"
        >
          3DS1182 - Yowamushi Pedal - A_a e no High Cadence (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1183%20-%20Big%20Hero%206%20-%20Battle%20in%20the%20Bay%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS1183 - Big Hero 6 - Battle in the Bay (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1184%20-%20Lost%20Heroes%202%20-%20Premium%20Edition%20%28Japan%29.3ds.7z"
        >
          3DS1184 - Lost Heroes 2 - Premium Edition (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1185%20-%20The%20Legend%20of%20Zelda%20Majoras%20Mask%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS1185 - The Legend of Zelda Majoras Mask 3D (Europe)
          (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1186%20-%20Monster%20Hunter%204%20Ultimate%20%28Europe%29%28En%2CFr%2CDe%2CEs%29.3ds.7z"
        >
          3DS1186 - Monster Hunter 4 Ultimate (Europe)(En,Fr,De,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1187%20-%20Ace%20Combat%20-%20Assault%20Horizon%20Legacy%20%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1187 - Ace Combat - Assault Horizon Legacy (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1188%20-%20SpongeBob%20HeroPants%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%29.3ds.7z"
        >
          3DS1188 - SpongeBob HeroPants (Europe) (En,Fr,De,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1189%20-%20Zelda%20no%20Densetsu%20-%20Majora%20no%20Kamen%203D%20%28Japan%29.3ds.7z"
        >
          3DS1189 - Zelda no Densetsu - Majora no Kamen 3D (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1190%20-%20Nanatsu%20no%20Taizai%20-%20The%20Seven%20Deadly%20Sins%20-%20Unjust%20Sin%20%28Japan%29.3ds.7z"
        >
          3DS1190 - Nanatsu no Taizai - The Seven Deadly Sins - Unjust Sin
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1191%20-%20Monster%20Hunter%204%20Ultimate%20%28USA%29.3ds.7z"
        >
          3DS1191 - Monster Hunter 4 Ultimate (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1192%20-%20The%20Legend%20Of%20Zelda%20Majoras%20Mask%203D%20%28%20Usa%29.3ds.7z"
        >
          3DS1192 - The Legend Of Zelda Majoras Mask 3D ( Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1193%20-%20DoraMoji%20-%20Nobita%20no%20Kanji%20Daisakusen%20%28Japan%29.3ds.7z"
        >
          3DS1193 - DoraMoji - Nobita no Kanji Daisakusen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1194%20-%20Flap%20Flap%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CNl%29.3ds.7z"
        >
          3DS1194 - Flap Flap (Europe) (En,Fr,De,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1195%20-%20Sekaiju%20to%20Fushigi%20no%20Dungeon%20%28Japan%29.3ds.7z"
        >
          3DS1195 - Sekaiju to Fushigi no Dungeon (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1196%20-%20LEGO%20City%20Undercover%20-%20The%20Chase%20Begins%20%28Japan%29.3ds.7z"
        >
          3DS1196 - LEGO City Undercover - The Chase Begins (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1197%20-%202in1%20Horses%203D%20Vol.3%20-%20My%20Riding%20Stables%203D%20-%20Jumping%20for%20the%20Team%20and%20My%20Western%20Horse%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CNl%29.3ds.7z"
        >
          3DS1197 - 2in1 Horses 3D Vol.3 - My Riding Stables 3D - Jumping for
          the Team and My Western Horse 3D (Europe) (En,Fr,De,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1198%20-%20Cooking%20Mama%20-%20Bon%20Appetit%21%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1198 - Cooking Mama - Bon Appetit! (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1199%20-%20Gardening%20Mama%20-%20Forest%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1199 - Gardening Mama - Forest Friends (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1200%20-%20Marvel%20Disk%20Wars%20-%20Avengers%20-%20Ultimate%20Heroes%20%28Japan%29.3ds.7z"
        >
          3DS1200 - Marvel Disk Wars - Avengers - Ultimate Heroes (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1201%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v01%29%28USA%29%28En%2CFr%2CEs%29.3dz.7z"
        >
          3DS1201 - Super Smash Bros. for Nintendo 3DS
          (v01)(USA)(En,Fr,Es).3dz.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1202%20-%20Kiniro%20no%20Corda%203%20-%20Full%20Voice%20Special%20%28Japan%29.3ds.7z"
        >
          3DS1202 - Kiniro no Corda 3 - Full Voice Special (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1203%20-%20Shin%20Tennis%20no%20Oujisama%20-%20Go%20to%20the%20top%20%28Japan%29.3ds.7z"
        >
          3DS1203 - Shin Tennis no Oujisama - Go to the top (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1204%20-%20Doraemon%20-%20Nobita%20no%20Space%20Heroes%20%28Japan%29.3ds.7z"
        >
          3DS1204 - Doraemon - Nobita no Space Heroes (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1205%20-%20Best%20Friends%20My%20Horse%203D%20%28Europe%29%20%28En%2CGe%2CIt%29.3ds.7z"
        >
          3DS1205 - Best Friends My Horse 3D (Europe) (En,Ge,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1206%20-%20Ansatsu%20Kyou_su%20-%20Koro-sensei%20Daihouimou%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1206 - Ansatsu Kyou_su - Koro-sensei Daihouimou!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1207%20-%20Code%20Name%20-%20S.T.E.A.M.%20%28USA%29.3ds.7z"
        >
          3DS1207 - Code Name - S.T.E.A.M. (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1208%20-%202in1%20Horses%203D%20Vol.2%20-%20Rivals%20in%20the%20Saddle%20and%20Jumping%20for%20the%20Team%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1208 - 2in1 Horses 3D Vol.2 - Rivals in the Saddle and Jumping for
          the Team 3D (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1209%20-%20Mario%20vs%20Don_%20Kong%20-%20Minna%20de%20Mini-Land%20%28Japan%29.3ds.7z"
        >
          3DS1209 - Mario vs Don_ Kong - Minna de Mini-Land (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1210%20-%20Fossil%20Fighters%20Frontier%20%28Usa%29.3ds.7z"
        >
          3DS1210 - Fossil Fighters Frontier (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1211%20-%20LEGO%20Ninjago%20-%20Shadow%20of%20Ronin%20%28Germany%29.3ds.7z"
        >
          3DS1211 - LEGO Ninjago - Shadow of Ronin (Germany).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1212%20-%20Navy%20Commander%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1212 - Navy Commander (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1213%20-%20LEGO%20Ninjago%20Nindroids%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS1213 - LEGO Ninjago Nindroids (Europe)(En,Fr,Ge,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1214%20-%20LEGO%20Batman%203%20Beyond%20Gotham%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDk%29%20.3ds.7z"
        >
          3DS1214 - LEGO Batman 3 Beyond Gotham (Europe) (En,Fr,Ge,It,Es,Nl,Dk)
          .3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1215%20-%20The%20LEGO%20Movie%20Videogame%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDk%29.3ds.7z"
        >
          3DS1215 - The LEGO Movie Videogame (Europe)
          (En,Fr,Ge,It,Es,Nl,Dk).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1216%20-%20Story%20of%20Seasons%20%28Usa%29.3ds.7z"
        >
          3DS1216 - Story of Seasons (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1217%20-%20Inazuma%20Eleven%20GO%20-%20Chrono%20Stones%20-%20Thunderflash%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS1217 - Inazuma Eleven GO - Chrono Stones - Thunderflash
          (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1218%20-%20Inazuma%20Eleven%20GO%20-%20Chrono%20Stones%20-%20Wildfire%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS1218 - Inazuma Eleven GO - Chrono Stones - Wildfire
          (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1219%20-%20LEGO%20Ninjago%20Shadow%20of%20Ronin%20%28Usa%29.3ds.7z"
        >
          3DS1219 - LEGO Ninjago Shadow of Ronin (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1220%20-%20Jet%20Dog%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1220 - Jet Dog (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1221%20-%20Theatrhythm%20Dragon%20Quest%20%28%20Japan%29.3ds.7z"
        >
          3DS1221 - Theatrhythm Dragon Quest ( Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1222%20-%20PriPara%20and%20Pretty%20Rhythm%20P_ara%20de%20Tsukaeru%20Oshare%20Item%201450%28Japan%29.3ds.7z"
        >
          3DS1222 - PriPara and Pretty Rhythm P_ara de Tsukaeru Oshare Item
          1450(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1223%20-%20LEGO%20Ninjago%20Shadow%20of%20Ronin%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS1223 - LEGO Ninjago Shadow of Ronin (Europe)
          (En,Fr,Ge,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1224%20-%20Kuroko%20no%20Basuke%20Mirai%20e%20no%20Kizuna%20%28Japan%29.3ds.7z"
        >
          3DS1224 - Kuroko no Basuke Mirai e no Kizuna (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1225%20-%20Nashijiru%20Busha%20Funassyi%20vs%20Dragons%20%28Japan%29.3ds.7z"
        >
          3DS1225 - Nashijiru Busha Funassyi vs Dragons (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1226%20-%20Hello%20Kitty%20%26%20Sanrio%20Friends%203D%20Racing%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1226 - Hello Kitty &amp; Sanrio Friends 3D Racing (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1227%20-%202in1%20Life%20With%20Horses%203D%20And%20My%20Baby%20Pet%20Hotel%203D%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1227 - 2in1 Life With Horses 3D And My Baby Pet Hotel 3D
          (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1228%20-%20Star%20Fox%2064%203D%20%28Japan%29%20%28Rev%203%29.3ds.7z"
        >
          3DS1228 - Star Fox 64 3D (Japan) (Rev 3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1229%20-%20Etrian%20Mystery%20Dungeon%20%28Usa%29.3ds.7z"
        >
          3DS1229 - Etrian Mystery Dungeon (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1230%20-%20My%20Pet%20School%203D%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS1230 - My Pet School 3D (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1231%20-%20Terra%20Formars%20-%20Akaki%20Hoshi%20no%20Gekitou%20%28Japan%29.3ds.7z"
        >
          3DS1231 - Terra Formars - Akaki Hoshi no Gekitou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1232%20-%20Tomodachi%20Life%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1232 - Tomodachi Life (Europe) (En,Fr,De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1233%20-%20LEGO%20Batman%203%20-%20The%20Game%20-%20Gotham%20kara%20Uchuu%20e%20%28Japan%29.3ds.7z"
        >
          3DS1233 - LEGO Batman 3 - The Game - Gotham kara Uchuu e
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1234%20-%20Rodea%20the%20Sky%20Soldier%20%28Japan%29.3ds.7z"
        >
          3DS1234 - Rodea the Sky Soldier (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1235%20-%20LEGO%20Ninjago%20-%20Shadow%20Of%20Ronin%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS1235 - LEGO Ninjago - Shadow Of Ronin (Europe)
          (En,Fr,Ge,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1236%20-%20Jewel%20Link%20-%20Double%20Pack%20-%20Safari%20Quest%20%26%20Atlantic%20Quest%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS1236 - Jewel Link - Double Pack - Safari Quest &amp; Atlantic Quest
          (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1237%20-%20Bravely%20Second%20-%20End%20Layer%20%28Japan%29.3ds.7z"
        >
          3DS1237 - Bravely Second - End Layer (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1238%20-%20Pazuru%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%29.3ds.7z"
        >
          3DS1238 - Pazuru (Europe) (En,Fr,De,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1239%20-%20Puzzle%20and%20Dragons%20-%20Super%20Mario%20Bros%20Edition%20%28Japan%29.3ds.7z"
        >
          3DS1239 - Puzzle and Dragons - Super Mario Bros Edition (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1240%20-%20Shin%20Megami%20Tensei%20-%20Devil%20Survivor%202%20Record%20Breaker%20%28USA%29%28En%29.3ds.7z"
        >
          3DS1240 - Shin Megami Tensei - Devil Survivor 2 Record Breaker
          (USA)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1241%20-%20Girls%20Mode%203%20-%20Kirakira-Code%20%28Japan%29.3ds.7z"
        >
          3DS1241 - Girls Mode 3 - Kirakira-Code (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1242%20-%20Raishi%20%28Japan%29.3ds.7z"
        >
          3DS1242 - Raishi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1243%20-%20Future%20Card%20-%20Buddyfight%20Yuujou%20no%20Bakunetsu%20Fight%21%20%28Japan%29.3ds.7z"
        >
          3DS1243 - Future Card - Buddyfight Yuujou no Bakunetsu Fight!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1244%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v01%29%28Europe%29%28En%2CFr%2CEs%2CGe%2CIt%2CDu%2CPt%2CRu%29.3ds.7z"
        >
          3DS1244 - Super Smash Bros. for Nintendo 3DS
          (v01)(Europe)(En,Fr,Es,Ge,It,Du,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1245%20-%20Puzzle%20and%20Dragons%20Z%20Plus%20Puzzle%20and%20Dragons%20Super%20Mario%20Bros%20Edition%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS1245 - Puzzle and Dragons Z Plus Puzzle and Dragons Super Mario
          Bros Edition (Europe) (En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1246%20-%20Super%20Mario%203D%20Land%20v01%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS1246 - Super Mario 3D Land v01 (Europe)
          (En,Fr,Ge,It,Es,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1247%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20-%20Haneda%20All%20Stars%20%28Japan%29.3ds.7z"
        >
          3DS1247 - Boku wa Koukuu Kanseikan - Airport Hero 3D - Haneda All
          Stars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1248%20-%20Tomodachi%20Life%20v01%20%28Usa%29.3ds.7z"
        >
          3DS1248 - Tomodachi Life v01 (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1249%20-%20LEGO%20Legends%20Of%20Chima%20Lavals%20Journey%20v01%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1249 - LEGO Legends Of Chima Lavals Journey v01 (Europe)
          (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1250%20-%20My%20Foal%203D%20v01%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1250 - My Foal 3D v01 (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1251%20-%20Code%20Name%20-%20S.T.E.A.M.%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1251 - Code Name - S.T.E.A.M. (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1252%20-%20Minarai%20Majo%20to%20MocoMoco%20Friends%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1252 - Minarai Majo to MocoMoco Friends (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1253%20-%20Nashi-jiru%20Action%21%20Funassyi%20no%20Yukai%20na%20Ohanassyi%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1253 - Nashi-jiru Action! Funassyi no Yukai na Ohanassyi (Japan)
          (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1254%20-%20Code%20Name%20-%20S.T.E.A.M.%20-%20Lincoln%20VS%20Alien%20%28Japan%29.3ds.7z"
        >
          3DS1254 - Code Name - S.T.E.A.M. - Lincoln VS Alien (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1255%20-%20New%20Style%20Boutique%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1255 - New Style Boutique (Europe) (En,Fr,De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1256%20-%20LEGO%20Marvel%20Super%20Heroes%20-%20Universe%20in%20Peril%20%28USA%29%20%28En%2CFr%2CEs%2CPt%29%20%28Spanish-only%20Audio%29.3ds.7z"
        >
          3DS1256 - LEGO Marvel Super Heroes - Universe in Peril (USA)
          (En,Fr,Es,Pt) (Spanish-only Audio).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1257%20-%20LEGO%20Marvel%20Super%20Heroes%20-%20Universe%20in%20Peril%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1257 - LEGO Marvel Super Heroes - Universe in Peril (Spain)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1258%20-%20LEGO%20Movie%20Videogame%2C%20The%20%28Spain%29%20%28En%2CFr%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1258 - LEGO Movie Videogame, The (Spain) (En,Fr,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1259%20-%20Legend%20of%20Zelda%20-%20Ocarina%20of%20Time%203D%2C%20The%20%28USA%29%20%28En%2CFr%2CEs%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1259 - Legend of Zelda - Ocarina of Time 3D, The (USA) (En,Fr,Es)
          (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1260%20-%20LEra%20Glaciale%204%20-%20Continenti%20alla%20Deriva%20-%20Giochi%20Polari%20%28Italy%29.3ds.7z"
        >
          3DS1260 - LEra Glaciale 4 - Continenti alla Deriva - Giochi Polari
          (Italy).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1261%20-%20Nintendogs%20and%20Cats%20-%20French%20Bulldog%20and%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CDa%2CRu%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1261 - Nintendogs and Cats - French Bulldog and New Friends
          (Europe) (En,Fr,De,Es,It,Nl,Pt,Da,Ru) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1262%20-%20Nintendogs%20and%20Cats%20-%20Golden%20Retriever%20and%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS1262 - Nintendogs and Cats - Golden Retriever and New Friends
          (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1263%20-%20Nintendogs%20and%20Cats%20-%20Golden%20Retriever%20%26%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1263 - Nintendogs and Cats - Golden Retriever &amp; New Friends
          (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1264%20-%20Super%20Mario%203D%20Land%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1264 - Super Mario 3D Land (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev
          2).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1265%20-%20Angry%20Birds%20Trilogy%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1265 - Angry Birds Trilogy (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1266%20-%20Downtown%20Nekketsu%20Jidaigeki%20%28Japan%29.3ds.7z"
        >
          3DS1266 - Downtown Nekketsu Jidaigeki (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1267%20-%20Fossil%20Fighters%20-%20Frontier%20%28Germany%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1267 - Fossil Fighters - Frontier (Germany) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1268%20-%20Pilotwings%20Resort%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1268 - Pilotwings Resort (Europe) (En,Fr,De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1269%20-%20Lord%20of%20Magna%20Maiden%20Heaven%20%28U%29.3ds.7z"
        >
          3DS1269 - Lord of Magna Maiden Heaven (U).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1270%20-%20Puzzle%20and%20Dragons%20Z%20-%20Puzzle%20and%20Dragons%20Super%20Mario%20Bros%20Edition%20%28Usa%29.3ds.7z"
        >
          3DS1270 - Puzzle and Dragons Z - Puzzle and Dragons Super Mario Bros
          Edition (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1271%20-%20Nintendogs%20and%20Cats%20-%20Toy%20Poodle%20and%20New%20Friends%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1271 - Nintendogs and Cats - Toy Poodle and New Friends (Japan)
          (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1272%20-%20Tribe%20Cool%20Crew%20-%20THE%20GAME%20%28Japan%29.3ds.7z"
        >
          3DS1272 - Tribe Cool Crew - THE GAME (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1273%20-%20Hatsune%20Miku%20-%20Project%20Mirai%20Deluxe%20%28Japan%29.3ds.7z"
        >
          3DS1273 - Hatsune Miku - Project Mirai Deluxe (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1274%20-%20Nintendogs%20and%20Cats%20-%20Toy%20Poodle%20%26%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1274 - Nintendogs and Cats - Toy Poodle &amp; New Friends (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1275%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Ohmi%20Tetsudou%20Hen%20%28Japan%29.3ds.7z"
        >
          3DS1275 - Tetsudou Nippon! Rosen Tabi - Ohmi Tetsudou Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1276%20-%20Stella%20Glow%20%28Japan%29.3ds.7z"
        >
          3DS1276 - Stella Glow (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1277%20-%20Shin%20Rorona%20no%20Atelier%20Hajimari%20no%20Monogatari%20-%20Arland%20no%20Renkinjutsushi%20%28Japan%29.3ds.7z"
        >
          3DS1277 - Shin Rorona no Atelier Hajimari no Monogatari - Arland no
          Renkinjutsushi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1278%20-%20Rhythm%20Tengoku%20-%20The%20Best%20Plus%20%28Japan%29.3ds.7z"
        >
          3DS1278 - Rhythm Tengoku - The Best Plus (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1279%20-%20LEGO%20Jurassic%20World%20%28%20Germany%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%2CDa%29.3ds.7z"
        >
          3DS1279 - LEGO Jurassic World ( Germany)(En,Fr,Ge,It,Es,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1280%20-%20Dragon%20Ball%20Z%20-%20Extreme%20Butouden%20%28Japan%29.3ds.7z"
        >
          3DS1280 - Dragon Ball Z - Extreme Butouden (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1281%20-%20Wreck-It%20Ralph%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%29.3ds.7z"
        >
          3DS1281 - Wreck-It Ralph (Europe) (En,Fr,De,Es,It,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1282%20-%20LEGO%20Jurassic%20World%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1282 - LEGO Jurassic World (Europe) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1283%20-%20LEGO%20Jurassic%20World%20%28Usa%29.3ds.7z"
        >
          3DS1283 - LEGO Jurassic World (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1284%20-%20My%20Zoo%20-%20Vet%20Practice%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CNl%29.3ds.7z"
        >
          3DS1284 - My Zoo - Vet Practice 3D (Europe) (En,Fr,De,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1285%20-%20Popolocrois%20-%20Bokujou%20Monogatari%20%28Japan%29.3ds.7z"
        >
          3DS1285 - Popolocrois - Bokujou Monogatari (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1286%20-%20Harvest%20Moon%20The%20Lost%20Valley%20%28Europe%29%28En%2CFr%2CGe%2CEs%29.3ds.7z"
        >
          3DS1286 - Harvest Moon The Lost Valley (Europe)(En,Fr,Ge,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1287%20-%20LEGO%20Jurassic%20World%20%28France%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1287 - LEGO Jurassic World (France) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1288%20-%20Fire%20Emblem%20If%20-%20Anya%20Oukoku%20%28Japan%29.3ds.7z"
        >
          3DS1288 - Fire Emblem If - Anya Oukoku (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1289%20-%20Fire%20Emblem%20If%20-%20Byakuya%20Oukoku%20%28Japan%29.3ds.7z"
        >
          3DS1289 - Fire Emblem If - Byakuya Oukoku (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1290%20-%20Garfield%20Kart%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1290 - Garfield Kart (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1291%20-%20Paddington%20-%20Adventures%20in%20London%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1291 - Paddington - Adventures in London (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1292%20-%20Yakari%20-%20The%20Mystery%20of%20Four%20Seasons%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1292 - Yakari - The Mystery of Four Seasons (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1293%20-%20I%20Love%20My%20Dogs%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1293 - I Love My Dogs (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1294%20-%20I%20Love%20My%20Cats%20%28Europe%29%20%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1294 - I Love My Cats (Europe) (En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1295%20-%20Kaitou%20Joker%20-%20Toki%20o%20Koeru%20Kaitou%20to%20Ushinawareta%20Houseki%20%28Japan%29.3ds.7z"
        >
          3DS1295 - Kaitou Joker - Toki o Koeru Kaitou to Ushinawareta Houseki
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1296%20-%20Youkai%20Watch%20Busters%20-%20Akanekodan%20%28Japan%29.3ds.7z"
        >
          3DS1296 - Youkai Watch Busters - Akanekodan (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1297%20-%20Youkai%20Watch%20Busters%20-%20Shiroinutai%20%28Japan%29.3ds.7z"
        >
          3DS1297 - Youkai Watch Busters - Shiroinutai (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1298%20-%20Fire%20Emblem%20If%20-%20Special%20Edition%20%28Japan%29.3ds.7z"
        >
          3DS1298 - Fire Emblem If - Special Edition (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1299%20-%20Chou%20Tousouchuu%20Atsumare%21%20Saikyou%20no%20Tousousha-tachi%20%28Japan%29.3ds.7z"
        >
          3DS1299 - Chou Tousouchuu Atsumare! Saikyou no Tousousha-tachi
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1300%20-%20The%20Smurfs%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1300 - The Smurfs (Europe)(En,Fr,Ge,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1301%20-%20LEGO%20Pirates%20of%20the%20Caribbean%20-%20The%20Video%20Game%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%2CNo%2CDa%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1301 - LEGO Pirates of the Caribbean - The Video Game (Europe)
          (En,Fr,De,Es,It,Nl,Sv,No,Da) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1302%20-%20LEGO%20Batman%203%20-%20Beyond%20Gotham%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1302 - LEGO Batman 3 - Beyond Gotham (Spain)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1303%20-%20LEGO%20The%20Hobbit%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1303 - LEGO The Hobbit (Spain) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1304%20-%20Daigyakuten%20Saiban%20-%20Naruhodou%20Ryuunosuke%20no%20Bouken%20%28Japan%29.3ds.7z"
        >
          3DS1304 - Daigyakuten Saiban - Naruhodou Ryuunosuke no Bouken
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1305%20-%20Hello%20Kitty%20to%20Sanrio%20Characters%20-%20World%20Rock%20Tour%20%28Japan%29.3ds.7z"
        >
          3DS1305 - Hello Kitty to Sanrio Characters - World Rock Tour
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1306%20-%20Lucky%20Luke%20and%20The%20Daltons%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1306 - Lucky Luke and The Daltons (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1307%20-%20Etrian%20Odyssey%202%20Untold%20-%20The%20Fafnir%20Knight%20%28Usa%29.3ds.7z"
        >
          3DS1307 - Etrian Odyssey 2 Untold - The Fafnir Knight (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1308%20-%20Dasshutsu%20Adventure%20-%20Zetsubou%20Yousai%20%28Japan%29.3ds.7z"
        >
          3DS1308 - Dasshutsu Adventure - Zetsubou Yousai (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1309%20-%20Doubutsu%20no%20Mori%20-%20Happy%20Home%20Designer%20%28Japan%29.3ds.7z"
        >
          3DS1309 - Doubutsu no Mori - Happy Home Designer (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1310%20-%20Langrisser%20-%20Re%20-%20Incarnation%20Tensei%20%28Japan%29.3ds.7z"
        >
          3DS1310 - Langrisser - Re - Incarnation Tensei (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1311%20-%20Corpse%20Party%20-%20Blood%20Covered%20-%20Repeated%20Fear%20%28Japan%29.3ds.7z"
        >
          3DS1311 - Corpse Party - Blood Covered - Repeated Fear (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1312%20-%20Wan%20Nyan%20Dobutsu%20Byouin%20-%20Suteki%20na%20Juui-San%20ni%20Narou%21%20%28Japan%29.3ds.7z"
        >
          3DS1312 - Wan Nyan Dobutsu Byouin - Suteki na Juui-San ni Narou!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1313%20-%20Nobunaga%20no%20Yabou%202%20%28Japan%29.3ds.7z"
        >
          3DS1313 - Nobunaga no Yabou 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1314%20-%20Sangokushi%202%20%28Japan%29.3ds.7z"
        >
          3DS1314 - Sangokushi 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1315%20-%20Go%21%20Princess%20PreCure%20-%20Sugar%20Oukoku%20to%206-nin%20no%20Princess%21%20%28Japan%29.3ds.7z"
        >
          3DS1315 - Go! Princess PreCure - Sugar Oukoku to 6-nin no Princess!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1316%20-%20Hoppe-chan%20-%20Punitto%20Shibotte%20Daibouken%21%20%28Japan%29.3ds.7z"
        >
          3DS1316 - Hoppe-chan - Punitto Shibotte Daibouken! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1317%20-%20Nintendogs%20and%20Cats%20-%20French%20Bulldog%20and%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1317 - Nintendogs and Cats - French Bulldog and New Friends
          (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1318%20-%20Ace%20Combat%20-%20Assault%20Horizon%20Legacy%20%20%28Usa%29.3ds.7z"
        >
          3DS1318 - Ace Combat - Assault Horizon Legacy (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1319%20-%20Tetris%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1319 - Tetris (Europe) (En,Fr,De,Es,It,Nl) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1320%20-%20Bonbonribbon%20-%20Tokimeki%20Coord%20Kirakira%20Dance%20%28Japan%29.3ds.7z"
        >
          3DS1320 - Bonbonribbon - Tokimeki Coord Kirakira Dance (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1321%20-%20Mario%20Kart%207%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1321 - Mario Kart 7 (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1322%20-%20Garfield%20Kart%20%28Usa%29.3ds.7z"
        >
          3DS1322 - Garfield Kart (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1323%20-%20Paddington%20-%20Adventures%20in%20London%20%28Usa%29.3ds.7z"
        >
          3DS1323 - Paddington - Adventures in London (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1324%20-%20LEGO%20The%20Lord%20of%20the%20Rings%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1324 - LEGO The Lord of the Rings (Spain)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1325%20-%20Super%20Robot%20Taisen%20BX%20%28Japan%29.3ds.7z"
        >
          3DS1325 - Super Robot Taisen BX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1326%20-%20LEGO%20Batman%203%20-%20Gotham%20e%20Oltre%20%28Italy%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1326 - LEGO Batman 3 - Gotham e Oltre (Italy)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1327%20-%20LEGO%20Jurassic%20World%20%28Italy%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1327 - LEGO Jurassic World (Italy) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1328%20-%20LBX%20-%20Little%20Battlers%20eXperience%20%28USA%29%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS1328 - LBX - Little Battlers eXperience (USA)(En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1329%20-%20Dragon%20Quest%20VIII%20-%20Sora%20to%20Umi%20to%20Daichi%20to%20Norowareshi%20Himegimi%20%28Japan%29.3ds.7z"
        >
          3DS1329 - Dragon Quest VIII - Sora to Umi to Daichi to Norowareshi
          Himegimi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1330%20-%20Famicom%20Remix%20Best%20Choice%20%28Japan%29.3ds.7z"
        >
          3DS1330 - Famicom Remix Best Choice (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1331%20-%20LBX%20-%20Little%20Battlers%20eXperience%20%28Europe%29%28En%29.3ds.7z"
        >
          3DS1331 - LBX - Little Battlers eXperience (Europe)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1332%20-%20Asonde%20Shogi%20ga%20Kyoukunaru%21%20Kane%20Hoshi%20Shogi%20DX%20%28Japan%29.3ds.7z"
        >
          3DS1332 - Asonde Shogi ga Kyoukunaru! Kane Hoshi Shogi DX
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1333%20-%20Kobayashi%20ga%20Kawai%20Sugite%20Tsurai%21%21%20Game%20Demo%20Kyun%20Moe%20MAX%20ga%20Tomara%20Nai%20%28Japan%29.3ds.7z"
        >
          3DS1333 - Kobayashi ga Kawai Sugite Tsurai!! Game Demo Kyun Moe MAX ga
          Tomara Nai (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1334%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Yui%20Rail-Hen%20%28Japan%29.3ds.7z"
        >
          3DS1334 - Tetsudou Nippon! Rosen Tabi - Yui Rail-Hen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1335%20-%20Etrian%20Mystery%20Dungeon%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS1335 - Etrian Mystery Dungeon (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1336%20-%20Hatsune%20Miku%20-%20Project%20Mirai%20DX%20%28USA%29.3ds.7z"
        >
          3DS1336 - Hatsune Miku - Project Mirai DX (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1337%20-%20Senran%20Kagura%202%20-%20Deep%20Crimson%20%28Europe%29.3ds.7z"
        >
          3DS1337 - Senran Kagura 2 - Deep Crimson (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1338%20-%20LEGO%20Ninjago%20-%20Nindroids%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1338 - LEGO Ninjago - Nindroids (Spain)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1339%20-%20Hatsune%20Miku%20-%20Project%20Mirai%20DX%20%28Europe%29%20%28En%29.3ds.7z"
        >
          3DS1339 - Hatsune Miku - Project Mirai DX (Europe) (En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1340%20-%20Senran%20Kagura%202%20-%20Deep%20Crimson%20%28USA%29.3ds.7z"
        >
          3DS1340 - Senran Kagura 2 - Deep Crimson (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1341%20-%20Monster%20High%20-%20Skultimate%20Roller%20Maze%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%2CNo%2CDa%2CFi%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1341 - Monster High - Skultimate Roller Maze (Europe)
          (En,Fr,De,Es,It,Nl,Sv,No,Da,Fi) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1342%20-%20Skylanders%20SuperChargers%20Racing%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%2CNo%2CDa%2CFi%29.3ds.7z"
        >
          3DS1342 - Skylanders SuperChargers Racing (Europe)
          (En,Fr,De,Es,It,Nl,Sv,No,Da,Fi).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1343%20-%20Teenage%20Mutant%20Ninja%20Turtles%20-%20Master%20Splinters%20Training%20Pack%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%29.3ds.7z"
        >
          3DS1343 - Teenage Mutant Ninja Turtles - Master Splinters Training
          Pack (Europe) (En,Fr,De,Es,It,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1344%20-%20MonHun%20Nikki%20-%20Poka%20Poka%20Ailu%20Mura%20DX%20%28Japan%29.3ds.7z"
        >
          3DS1344 - MonHun Nikki - Poka Poka Ailu Mura DX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1345%20-%20Atelier%20Deco%20La%20Doll%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS1345 - Atelier Deco La Doll Collection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1346%20-%20LEGO%20Jurassic%20World%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1346 - LEGO Jurassic World (Spain) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1347%20-%20FIFA%2012%20%28Germany%29%20%28De%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1347 - FIFA 12 (Germany) (De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1348%20-%20Animal%20Crossing%20-%20Happy%20Home%20Designer%20%28USA%29%28En%2CFr%29.3ds.7z"
        >
          3DS1348 - Animal Crossing - Happy Home Designer (USA)(En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1349%20-%20Animal%20Crossing%20-%20Happy%20Home%20Designer%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS1349 - Animal Crossing - Happy Home Designer
          (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1350%20-%20LEGO%20Ninjago%20Ronin%20no%20Kage%20%28Japan%29.3ds.7z"
        >
          3DS1350 - LEGO Ninjago Ronin no Kage (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1351%20-%20Pokemon%20Chou%20Fushigi%20no%20Dungeon%20%28Japan%29.3ds.7z"
        >
          3DS1351 - Pokemon Chou Fushigi no Dungeon (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1352%20-%20Katachi%20Shinhakken%21%20Rittai%20Picross%202%20%28Japan%29.3ds.7z"
        >
          3DS1352 - Katachi Shinhakken! Rittai Picross 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1353%20-%20Thomas%20and%20Friends%20-%20Steaming%20around%20Sodor%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1353 - Thomas and Friends - Steaming around Sodor (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1354%20-%20Are%20You%20Smarter%20Than%20a%205th%20Grader%20%28Usa%29.3ds.7z"
        >
          3DS1354 - Are You Smarter Than a 5th Grader (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1355%20-%20Dragon%20Ball%20Z%20-%20Extreme%20Butoden%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1355 - Dragon Ball Z - Extreme Butoden (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1356%20-%20Pro%20Yakyuu%20Famista%20Returns%20%28Japan%29.3ds.7z"
        >
          3DS1356 - Pro Yakyuu Famista Returns (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1357%20-%20Chibi-Robo%21%20Zip%20Lash%20%28USA%29.3ds.7z"
        >
          3DS1357 - Chibi-Robo! Zip Lash (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1358%20-%20Legend%20of%20Legacy%2C%20The%20%28USA%29.3ds.7z"
        >
          3DS1358 - Legend of Legacy, The (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1359%20-%20Goosebumps%20-%20The%20Game%20%28USA%29.3ds.7z"
        >
          3DS1359 - Goosebumps - The Game (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1360%20-%20Nagenawa%20Action%21%20Guruguru%21%20Chibi%20Robo%21%20%28Japan%29.3ds.7z"
        >
          3DS1360 - Nagenawa Action! Guruguru! Chibi Robo! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1361%20-%20Smurfs%2C%20The%20%28USA%29.3ds.7z"
        >
          3DS1361 - Smurfs, The (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1362%20-%20Legend%20of%20Zelda%20-%20TriForce%20Heroes%2C%20The%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3dz.7z"
        >
          3DS1362 - Legend of Zelda - TriForce Heroes, The
          (Europe)(En,Fr,Ge,It,Es).3dz.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1363%20-%20Zelda%20no%20Densetsu%20-%20Triforce%203-Juushi%20%28Japan%29.3ds.7z"
        >
          3DS1363 - Zelda no Densetsu - Triforce 3-Juushi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1364%20-%20Gravity%20Falls%20Legend%20of%20the%20Gnome%20Gemulets%20%28Usa%29.3ds.7z"
        >
          3DS1364 - Gravity Falls Legend of the Gnome Gemulets (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1365%20-%20Legend%20of%20Zelda%20-%20TriForce%20Heroes%2C%20The%20%28USA%29%28En%29.3dz.7z"
        >
          3DS1365 - Legend of Zelda - TriForce Heroes, The (USA)(En).3dz.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1366%20-%20Dragon%20Ball%20Z%20-%20Extreme%20Butoden%20%28USA%29.3ds.7z"
        >
          3DS1366 - Dragon Ball Z - Extreme Butoden (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1367%20-%20Bibi%20Und%20Tina%20Das%20Spiel%20Zum%20Film%20v1.1%20%28Germany%29%20%28Ge%29.3ds.7z"
        >
          3DS1367 - Bibi Und Tina Das Spiel Zum Film v1.1 (Germany) (Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1368%20-%20Star%20Fox%2064%203D%20v1.3%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CEs%29.3ds.7z"
        >
          3DS1368 - Star Fox 64 3D v1.3 (Europe)(En,Fr,Ge,It,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1369%20-%20My%20Life%20on%20a%20Farm%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CNl%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1369 - My Life on a Farm 3D (Europe) (En,Fr,De,It,Nl) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1370%20-%20Tomodachi%20Life%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1370 - Tomodachi Life (Europe) (En,Fr,De,Es,It,Nl) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1371%20-%20Adventure%20Time%20-%20Finn%20%26%20Jake%20Investigations%20%28USA%29.3ds.7z"
        >
          3DS1371 - Adventure Time - Finn &amp; Jake Investigations (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1372%20-%20Shovel%20Knight%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1372 - Shovel Knight (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1373%20-%20Gravity%20Falls%20-%20Legend%20of%20the%20Gnome%20Gemulets%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1373 - Gravity Falls - Legend of the Gnome Gemulets (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1374%20-%20Peanuts%20Movie%2C%20The%20-%20Snoopy%27s%20Grand%20Adventure%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%29.3ds.7z"
        >
          3DS1374 - Peanuts Movie, The - Snoopy's Grand Adventure (Europe)
          (En,Fr,De,Es,It,Nl,Sv).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1375%20-%20Adventure%20Time%20-%20Finn%20%26%20Jake%20Investigations%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1375 - Adventure Time - Finn &amp; Jake Investigations (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1376%20-%20Chibi-Robo%21%20Zip%20Lash%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1376 - Chibi-Robo! Zip Lash (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1377%20-%20LEGO%20Ninjago%20-%20Shadow%20of%20Ronin%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1377 - LEGO Ninjago - Shadow of Ronin (Spain)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1378%20-%20Shovel%20Knight%20%28USA%29.3ds.7z"
        >
          3DS1378 - Shovel Knight (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1379%20-%20Peanuts%20Movie%2C%20The%20-%20Snoopy%27s%20Grand%20Adventure%20%28USA%29.3ds.7z"
        >
          3DS1379 - Peanuts Movie, The - Snoopy's Grand Adventure (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1380%20-%20Barbie%20and%20Her%20Sisters%20-%20Puppy%20Rescue%20%28USA%29.3ds.7z"
        >
          3DS1380 - Barbie and Her Sisters - Puppy Rescue (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1381%20-%20Yo-Kai%20Watch%20%28USA%29.3ds.7z"
        >
          3DS1381 - Yo-Kai Watch (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1382%20-%20Hello%20Kitty%20and%20the%20Apron%20of%20Magic%20-%20Rhythm%20Cooking%20%28Europe%29.3ds.7z"
        >
          3DS1382 - Hello Kitty and the Apron of Magic - Rhythm Cooking
          (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1383%20-%20LBX%20-%20Little%20Battlers%20eXperience%20%28Europe%29%20%28En%2CEs%2CIt%29.3ds.7z"
        >
          3DS1383 - LBX - Little Battlers eXperience (Europe) (En,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1384%20-%20Biohazard%20-%20The%20Mercenaries%203D%20%28Japan%29%20%28Rev%201%29%20.3ds.7z"
        >
          3DS1384 - Biohazard - The Mercenaries 3D (Japan) (Rev 1) .3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1385%20-%20Devil%20Summoner%20-%20Soul%20Hackers%20%28v01%29%28Japan%29.3ds.7z"
        >
          3DS1385 - Devil Summoner - Soul Hackers (v01)(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1386%20-%207th%20Dragon%20III%20Code%20-%20VFD%20%28Japan%29.3ds.7z"
        >
          3DS1386 - 7th Dragon III Code - VFD (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1387%20-%20PriPara%20Mezase%21%20Idol%20Grand%20Prix%20No.1%21%20%28Japan%29.3ds.7z"
        >
          3DS1387 - PriPara Mezase! Idol Grand Prix No.1! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1388%20-%20Horse%20Life%204%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1388 - Horse Life 4 (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1389%20-%20Rodea%20the%20Sky%20Soldier%20%28Europe%29%20%28En%2CJa%2CFr%2CDe%29.3ds.7z"
        >
          3DS1389 - Rodea the Sky Soldier (Europe) (En,Ja,Fr,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1390%20-%20Rodea%20the%20Sky%20Soldier%20%28USA%29.3ds.7z"
        >
          3DS1390 - Rodea the Sky Soldier (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1391%20-%20Project%20X%20Zone%202%20-%20Brave%20New%20World%20%28Original%20Game%20Sound%20Edition%29%20%28Japan%29.3ds.7z"
        >
          3DS1391 - Project X Zone 2 - Brave New World (Original Game Sound
          Edition) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1392%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Joumou%20Denki%20Tetsudou%20Hen%20%28Japan%29.3ds.7z"
        >
          3DS1392 - Tetsudou Nippon! Rosen Tabi - Joumou Denki Tetsudou Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1393%20-%20Disney%20Magic%20Castle%20-%20My%20Happy%20Life%202%20%28Japan%29.3ds.7z"
        >
          3DS1393 - Disney Magic Castle - My Happy Life 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1394%20-%20Shin%20Megami%20Tensei%20-%20Devil%20Survivor%202%20-%20Record%20Breaker%20%28Europe%29.3ds.7z"
        >
          3DS1394 - Shin Megami Tensei - Devil Survivor 2 - Record Breaker
          (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1395%20-%20Hello%20Kitty%20%26%20Friends%20-%20Rock%20n%27%20World%20Tour%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1395 - Hello Kitty &amp; Friends - Rock n' World Tour (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1396%20-%20Minna%20no%20Pokemon%20Scramble%20%28Japan%29.3ds.7z"
        >
          3DS1396 - Minna no Pokemon Scramble (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1397%20-%20New%20Style%20Boutique%202%20-%20Fashion%20Forward%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1397 - New Style Boutique 2 - Fashion Forward (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1398%20-%20Stella%20Glow%20%28USA%29.3ds.7z"
        >
          3DS1398 - Stella Glow (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1399%20-%20Barbie%20%26%20Her%20Sisters%20-%20Puppy%20Rescue%20%28Europe%29%20%28En%2CFr%2CDe%29.3ds.7z"
        >
          3DS1399 - Barbie &amp; Her Sisters - Puppy Rescue (Europe)
          (En,Fr,De).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1400%20-%20Pokemon%20Super%20Mystery%20Dungeon%20%28USA%29%28En%29.3ds.7z"
        >
          3DS1400 - Pokemon Super Mystery Dungeon (USA)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1401%20-%20Moco%20Moco%20Friends%20%28USA%29%28En%29.3ds.7z"
        >
          3DS1401 - Moco Moco Friends (USA)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1402%20-%20Monster%20High%20-%20New%20Ghoul%20in%20School%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20.3ds.7z"
        >
          3DS1402 - Monster High - New Ghoul in School (Europe) (En,Fr,De,Es,It)
          .3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1403%20-%20Kung%20Fu%20Panda%20-%20Showdown%20of%20Legendary%20Legends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1403 - Kung Fu Panda - Showdown of Legendary Legends (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1404%20-%20I%20Love%20My%20Pony%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1404 - I Love My Pony (Europe) (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1405%20-%20Monster%20Hunter%20X%20%28Japan%29%28Online%20Patched%29%28Eng%20v9.0%29.3dz.7z"
        >
          3DS1405 - Monster Hunter X (Japan)(Online Patched)(Eng v9.0).3dz.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1406%20-%20Monster%20High%20-%20New%20Ghoul%20in%20School%20%28USA%29%28En%29.3ds.7z"
        >
          3DS1406 - Monster High - New Ghoul in School (USA)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1407%20-%20Adventure%20Time%20-%20Hey%20Ice%20King%20Why%27d%20You%20Steal%20Our%20Garbage%21%21%20%28USA%29%28Rev%201%29%28En%29.3ds.7z"
        >
          3DS1407 - Adventure Time - Hey Ice King Why'd You Steal Our Garbage!!
          (USA)(Rev 1)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1408%20-%20LEGO%20Jurassic%20World%20%28Japan%29.3ds.7z"
        >
          3DS1408 - LEGO Jurassic World (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1409%20-%20Project%20X%20Zone%202%20-%20Brave%20New%20World%20%28Japan%29.3ds.7z"
        >
          3DS1409 - Project X Zone 2 - Brave New World (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1410%20-%20Mario%20%26%20Luigi%20-%20Paper%20Jam%20Bros.%20%28Europe%29%28Du%2CGe%2CEn%2CFr%2CEs%2CIt%2CPt%2CRu%29.3ds.7z"
        >
          3DS1410 - Mario &amp; Luigi - Paper Jam Bros.
          (Europe)(Du,Ge,En,Fr,Es,It,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1411%20-%20Fireman%20Sam%20-%20To%20the%20Rescue%20%28Europe%29%28Du%2CGe%2CEn%2CFr%2CEs%2CIt%29.3ds.7z"
        >
          3DS1411 - Fireman Sam - To the Rescue
          (Europe)(Du,Ge,En,Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1412%20-%20Sumikko%20Gurashi%20-%20Omise%20Hajimerundesu%20%28Japan%29.3ds.7z"
        >
          3DS1412 - Sumikko Gurashi - Omise Hajimerundesu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1413%20-%20Kung%20Fu%20Panda%20-%20Showdown%20of%20Legendary%20Legends%20%28USA%29%28En%29.3ds.7z"
        >
          3DS1413 - Kung Fu Panda - Showdown of Legendary Legends
          (USA)(En).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1414%20%20-%20Barbie%20%26%20Her%20Sisters%20-%20Puppy%20Rescue%20%28Europe%29%28Fr%2CEs%2CIt%29.3ds.7z"
        >
          3DS1414 - Barbie &amp; Her Sisters - Puppy Rescue
          (Europe)(Fr,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1415%20-%20Gudetama%20-%20Hanjuku%20de%20Tanomuwa%20%28Japan%29.3ds.7z"
        >
          3DS1415 - Gudetama - Hanjuku de Tanomuwa (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1416%20-%20Monster%20Strike%20%28Japan%29.3ds.7z"
        >
          3DS1416 - Monster Strike (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1417%20-%20Disney%20Baymax%20-%20Heroes%20Battle%20%28Japan%29.3ds.7z"
        >
          3DS1417 - Disney Baymax - Heroes Battle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1418%20-%20Shokugeki%20no%20Soma%20-%20Yuujou%20to%20Kizuna%20no%20Hitosara%20%28Japan%29.3ds.7z"
        >
          3DS1418 - Shokugeki no Soma - Yuujou to Kizuna no Hitosara
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1419%20-%20Sega%203D%20Fukkoku%20Archives%202%20%28Japan%29.3ds.7z"
        >
          3DS1419 - Sega 3D Fukkoku Archives 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1420%20-%20Story%20of%20Seasons%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1420 - Story of Seasons (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1421%20-%20Downtown%20no%20Gaki%20no%20Tsukai%20Yaarahen%20de%21%21%20Zettai%20ni%20Tsukamatte%20Haikenai%20Gasu%20Kurobikari%20Land%20%28Japan%29.3ds.7z"
        >
          3DS1421 - Downtown no Gaki no Tsukai Yaarahen de!! Zettai ni
          Tsukamatte Haikenai Gasu Kurobikari Land (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1422%20-%20Kaite%20Oboeru%20-%20DoraGana%20%28Japan%29.3ds.7z"
        >
          3DS1422 - Kaite Oboeru - DoraGana (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1423%20-%20Nintendogs%20%20%20Cats%20-%20Shiba%20%26%20New%20Friends%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1423 - Nintendogs Cats - Shiba &amp; New Friends (Japan) (Rev
          2).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1424%20-%20Shantae%20-%20Kaizoku%20no%20Noroi%20%28Japan%29.3ds.7z"
        >
          3DS1424 - Shantae - Kaizoku no Noroi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1425%20-%20Dream%20Girl%20Premier%20%28Japan%29.3ds.7z"
        >
          3DS1425 - Dream Girl Premier (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1426%20-%20Aikatsu%21%20My%20No.1%20Stage%21%20%28Japan%29.3ds.7z"
        >
          3DS1426 - Aikatsu! My No.1 Stage! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1427%20-%20Medarot%209%20-%20Kabuto%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS1427 - Medarot 9 - Kabuto Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1428%20-%20Medarot%209%20-%20Kuwagata%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS1428 - Medarot 9 - Kuwagata Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1429%20-%20Chao%20Illust%20Club%20%28Japan%29.3ds.7z"
        >
          3DS1429 - Chao Illust Club (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1430%20-%20My%20Melody%20-%20Negai%20ga%20Kanau%20Fushigi%20na%20Hako%20%28Japan%29.3ds.7z"
        >
          3DS1430 - My Melody - Negai ga Kanau Fushigi na Hako (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1431%20-%20Nico%20Puchi%20Girls%20Runway%20%28Japan%29.3ds.7z"
        >
          3DS1431 - Nico Puchi Girls Runway (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1432%20-%20Sousaku%20Alice%20to%20Oujisama%21%20%28Japan%29.3ds.7z"
        >
          3DS1432 - Sousaku Alice to Oujisama! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1433%20-%20Rayman%20Origins%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1433 - Rayman Origins (Europe) (En,Fr,De,Es,It,Nl) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1434%20-%20CardFight%21%21%20Vanguard%20G%20-%20Stride%20to%20Victory%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1434 - CardFight!! Vanguard G - Stride to Victory!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1435%20-%20Boku%20wa%20Koukuu%20Kanseikan%20-%20Airport%20Hero%203D%20Kankuu%20All%20Stars%20%28Japan%29.3ds.7z"
        >
          3DS1435 - Boku wa Koukuu Kanseikan - Airport Hero 3D Kankuu All Stars
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1436%20-%20Yo-Kai%20Watch%20%28Australia%29.3ds.7z"
        >
          3DS1436 - Yo-Kai Watch (Australia).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1437%20-%20Zelda%20Musou%20-%20Hyrule%20All-Stars%20%28Japan%29.3ds.7z"
        >
          3DS1437 - Zelda Musou - Hyrule All-Stars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1438%20-%20Pokemon%20Rumble%20World%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1438 - Pokemon Rumble World (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1439%20-%20Petz%20Fantasy%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CSv%2CNo%2CDa%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1439 - Petz Fantasy 3D (Europe) (En,Fr,De,Es,It,Nl,Sv,No,Da) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1440%20-%20Mario%20%26%20Luigi%20-%20Paper%20Jam%20%28USA%29.3ds.7z"
        >
          3DS1440 - Mario &amp; Luigi - Paper Jam (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1441%20-%20Final%20Fantasy%20Explorers%20%28Europe%29%20%28En%2CFr%29.3ds.7z"
        >
          3DS1441 - Final Fantasy Explorers (Europe) (En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1442%20-%20LEGO%20Marvel%20Avengers%20%28Germany%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1442 - LEGO Marvel Avengers (Germany) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1443%20-%20LEGO%20Marvel%20Avengers%20%28USA%29%20%28En%2CFr%2CEs%2CPt%29.3ds.7z"
        >
          3DS1443 - LEGO Marvel Avengers (USA) (En,Fr,Es,Pt).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1444%20-%20Final%20Fantasy%20Explorers%20%28USA%29.3ds.7z"
        >
          3DS1444 - Final Fantasy Explorers (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1445%20-%20Sonic%20Generations%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1445 - Sonic Generations (Europe) (En,Fr,De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1446%20-%20Mario%20Tennis%20Open%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1446 - Mario Tennis Open (Europe) (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1447%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%205%29.3ds.7z"
        >
          3DS1447 - Super Smash Bros. for Nintendo 3DS (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 5).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1448%20-%20Legend%20of%20Legacy%2C%20The%20%28Europe%29.3ds.7z"
        >
          3DS1448 - Legend of Legacy, The (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1449%20-%20LEGO%20Marvel%20Avengers%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1449 - LEGO Marvel Avengers (Europe) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1450%20-%20Project%20X%20Zone%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1450 - Project X Zone 2 (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1451%20-%20Etrian%20Odyssey%202%20Untold%20-%20The%20Fafnir%20Knight%20%28Europe%29.3ds.7z"
        >
          3DS1451 - Etrian Odyssey 2 Untold - The Fafnir Knight (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1452%20-%20Pokemon%20Super%20Mystery%20Dungeon%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1452 - Pokemon Super Mystery Dungeon (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1453%20-%20LEGO%20Marvel%20Avengers%20%28France%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1453 - LEGO Marvel Avengers (France) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1454%20-%20Mega%20Man%20Legacy%20Collection%20%28USA%29.3ds.7z"
        >
          3DS1454 - Mega Man Legacy Collection (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1455%20-%20Project%20X%20Zone%202%20%28USA%29.3ds.7z"
        >
          3DS1455 - Project X Zone 2 (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1456%20-%20Fire%20Emblem%20Fates%20-%20Special%20Edition%20%28USA%29.3ds.7z"
        >
          3DS1456 - Fire Emblem Fates - Special Edition (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1457%20-%20Mario%20%26%20Sonic%20at%20Rio%20Olympics%20%28Japan%29.3ds.7z"
        >
          3DS1457 - Mario &amp; Sonic at Rio Olympics (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1458%20-%20Fire%20Emblem%20Fates%20-%20Birthright%20%28USA%29.3ds.7z"
        >
          3DS1458 - Fire Emblem Fates - Birthright (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1459%20-%20Fire%20Emblem%20Fates%20-%20Conquest%20%28USA%29.3ds.7z"
        >
          3DS1459 - Fire Emblem Fates - Conquest (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1460%20-%20Bravely%20Second%20-%20End%20Layer%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1460 - Bravely Second - End Layer (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1461%20-%20Terraria%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1461 - Terraria (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1462%20-%20Mario%20%26%20Luigi%20-%20Dream%20Team%20Bros.%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1462 - Mario &amp; Luigi - Dream Team Bros. (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1463%20-%20Goosebumps%20-%20The%20Game%20%28Europe%29.3ds.7z"
        >
          3DS1463 - Goosebumps - The Game (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1464%20-%20Return%20to%20PopoloCrois%20-%20A%20Story%20of%20Seasons%20Fairytale%20%28USA%29.3ds.7z"
        >
          3DS1464 - Return to PopoloCrois - A Story of Seasons Fairytale
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1465%20-%20LEGO%20Marvel%20Avengers%20%28Italy%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1465 - LEGO Marvel Avengers (Italy) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1466%20-%20Stella%20Glow%20%28Europe%29.3ds.7z"
        >
          3DS1466 - Stella Glow (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1467%20-%20Terraria%20%28USA%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1467 - Terraria (USA) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1468%20-%20Mario%20%26%20Sonic%20at%20the%20Rio%202016%20Olympic%20Games%20%28USA%29%20%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS1468 - Mario &amp; Sonic at the Rio 2016 Olympic Games (USA)
          (En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1469%20-%20Hyrule%20Warriors%20Legends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1469 - Hyrule Warriors Legends (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1470%20-%20Hyrule%20Warriors%20Legends%20%28USA%29.3ds.7z"
        >
          3DS1470 - Hyrule Warriors Legends (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1471%20-%20LEGO%20Marvel%20Avengers%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1471 - LEGO Marvel Avengers (Spain) (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1472%20-%20Luv%20Me%20Buddies%20Wonderland%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1472 - Luv Me Buddies Wonderland (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1473%20-%20Mario%20%26%20Sonic%20at%20the%20Rio%202016%20Olympic%20Games%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1473 - Mario &amp; Sonic at the Rio 2016 Olympic Games (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1474%20-%20Mario%20%26%20Luigi%20RPG%20-%20Paper%20Mario%20MIX%20%28Japan%29.3ds.7z"
        >
          3DS1474 - Mario &amp; Luigi RPG - Paper Mario MIX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1475%20-%20Disney%20Anna%20to%20Yuki%20no%20Joou%20-%20Olaf%20no%20Okurimono%20%28Japan%29.3ds.7z"
        >
          3DS1475 - Disney Anna to Yuki no Joou - Olaf no Okurimono
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1476%20-%20Haikyu%21%21%20Cross%20Team%20Match%21%20%28Japan%29.3ds.7z"
        >
          3DS1476 - Haikyu!! Cross Team Match! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1477%20-%20Medarot%20Girls%20Mission%20-%20Kabuto%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS1477 - Medarot Girls Mission - Kabuto Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1478%20-%20Medarot%20Girls%20Mission%20-%20Kuwagata%20Ver.%20%28Japan%29.3ds.7z"
        >
          3DS1478 - Medarot Girls Mission - Kuwagata Ver. (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1479%20-%20Bravely%20Second%20-%20End%20Layer%20%28USA%29.3ds.7z"
        >
          3DS1479 - Bravely Second - End Layer (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1480%20-%20Langrisser%20Re%20-%20Incarnation%20-TENSEI-%20%28USA%29.3ds.7z"
        >
          3DS1480 - Langrisser Re - Incarnation -TENSEI- (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1481%20-%20Ansatsu%20Kyou_su%20-_assin%20Ikusei%20Keikaku%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1481 - Ansatsu Kyou_su -_assin Ikusei Keikaku!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1482%20-%20Disney%20Art%20Academy%20%28Japan%29.3ds.7z"
        >
          3DS1482 - Disney Art Academy (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1483%20-%20Pom%20Pom%20Purin%20-%20Corocoro%20Daibouken%20%28Japan%29.3ds.7z"
        >
          3DS1483 - Pom Pom Purin - Corocoro Daibouken (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1484%20-%20Simple%20Series%20for%20Nintendo%203DS%20Vol.%202%20-%20The%20Mis_sukara%20no%20Dasshutsu%20Archives%201%20%28Japan%29.3ds.7z"
        >
          3DS1484 - Simple Series for Nintendo 3DS Vol. 2 - The Mis_sukara no
          Dasshutsu Archives 1 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1485%20-%20Simple%20Series%20for%20Nintendo%203DS%20Vol.%203%20-%20The%20Mis_sukara%20no%20Dasshutsu%20Archives%202%20%28Japan%29.3ds.7z"
        >
          3DS1485 - Simple Series for Nintendo 3DS Vol. 3 - The Mis_sukara no
          Dasshutsu Archives 2 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1486%20-%20Terraria%20%28Japan%29.3ds.7z"
        >
          3DS1486 - Terraria (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1487%20-%20Yo-Kai%20Watch%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1487 - Yo-Kai Watch (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1488%20-%20Raishi%20-%20Konpeki%20no%20Shou%20%28Japan%29.3ds.7z"
        >
          3DS1488 - Raishi - Konpeki no Shou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1489%20-%20Sega%203D%20Classics%20Collection%20%28USA%29.3ds.7z"
        >
          3DS1489 - Sega 3D Classics Collection (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1490%20-%20Pokemon%20Rumble%20World%20%28USA%29.3ds.7z"
        >
          3DS1490 - Pokemon Rumble World (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1491%20-%20Rockman%20Classics%20Collection%20%28Japan%29.3ds.7z"
        >
          3DS1491 - Rockman Classics Collection (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1492%20-%20Shin%20Megami%20Tensei%20IV%20-%20Final%20%28Japan%29.3ds.7z"
        >
          3DS1492 - Shin Megami Tensei IV - Final (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1493%20-%20Youkai%20Sangokushi%20%28Japan%29.3ds.7z"
        >
          3DS1493 - Youkai Sangokushi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1494%20-%20Biohazard%20-%20Revelations%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1494 - Biohazard - Revelations (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1495%20-%20Devil%20Survivor%20-%20Over%20Clock%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1495 - Devil Survivor - Over Clock (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1496%20-%20Monster%20Hunter%203G%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1496 - Monster Hunter 3G (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1497%20-%20Nazo%20Waku%20Yakata%20-%20Oto%20no%20Ma%20ni%20Ma%20ni%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1497 - Nazo Waku Yakata - Oto no Ma ni Ma ni (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1498%20-%20Tobidase%20Doubutsu%20no%20Mori%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1498 - Tobidase Doubutsu no Mori (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1499%20-%20LEGO%20Marvel%20Avengers%20%28Japan%29.3ds.7z"
        >
          3DS1499 - LEGO Marvel Avengers (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1500%20-%20Tomodachi%20Collection%20-%20Shin%20Seikatsu%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1500 - Tomodachi Collection - Shin Seikatsu (Japan) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1501%20-%20Dragon%20Quest%20Monsters%20-%20Joker%203%20%28Japan%29.3ds.7z"
        >
          3DS1501 - Dragon Quest Monsters - Joker 3 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1502%20-%20Doraemon%20-%20Shin%20Nobita%20no%20Nippon%20Tanjou%20%28Japan%29.3ds.7z"
        >
          3DS1502 - Doraemon - Shin Nobita no Nippon Tanjou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1503%20-%20Disney%20Art%20Academy%20%28USA%29.3ds.7z"
        >
          3DS1503 - Disney Art Academy (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1504%20-%20Fire%20Emblem%20Fates%20-%20Conquest%20%28Europe%29.3ds.7z"
        >
          3DS1504 - Fire Emblem Fates - Conquest (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1505%20-%20Fire%20Emblem%20Fates%20-%20Birthright%20%28Europe%29%28En%2CFr%2CGe%2CIt%2CSp%29.3ds.7z"
        >
          3DS1505 - Fire Emblem Fates - Birthright
          (Europe)(En,Fr,Ge,It,Sp).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1506%20-%20Teenage%20Mutant%20Ninja%20Turtles%20-%20Master%20Splinter%27s%20Training%20Pack%20%28USA%29%20%28En%2CFr%29.3ds.7z"
        >
          3DS1506 - Teenage Mutant Ninja Turtles - Master Splinter's Training
          Pack (USA) (En,Fr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1507%20-%20Boku%20no%20Hero%20Academia%20-%20Battle%20for%20All%20%28Japan%29.3ds.7z"
        >
          3DS1507 - Boku no Hero Academia - Battle for All (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1508%20-%20Fire%20Emblem%20Fates%20-%20Special%20Edition%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1508 - Fire Emblem Fates - Special Edition (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1509%20-%20Skylanders%20SuperChargers%20Racing%20%28USA%29.3ds.7z"
        >
          3DS1509 - Skylanders SuperChargers Racing (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1510%20-%20Kirby%20-%20Planet%20Robobot%20%28Europe%29.3ds.7z"
        >
          3DS1510 - Kirby - Planet Robobot (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1511%20-%20Hoshi%20no%20_by%20-%20Robobo%20Planet%20%28Japan%29.3ds.7z"
        >
          3DS1511 - Hoshi no _by - Robobo Planet (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1512%20-%20Taiko%20no%20Tatsujin%20-%20Chibi%20Dragon%20to%20Fushigi%20na%20Orb%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1512 - Taiko no Tatsujin - Chibi Dragon to Fushigi na Orb (Japan)
          (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1513%20-%20Dairantou%20Smash%20Brothers%20for%20Nintendo%203DS%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1513 - Dairantou Smash Brothers for Nintendo 3DS (Japan) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1514%20-%20Bokujou%20Monogatari%20-%20Hajimari%20no%20Daichi%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1514 - Bokujou Monogatari - Hajimari no Daichi (Japan) (Rev
          2).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1515%20-%20Gyakuten%20Saiban%206%20%28Japan%29.3ds.7z"
        >
          3DS1515 - Gyakuten Saiban 6 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1516%20-%20Nintendogs%20%20%20Cats%20-%20French%20Bulldog%20%26%20New%20Friends%20%28Japan%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1516 - Nintendogs Cats - French Bulldog &amp; New Friends (Japan)
          (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1517%20-%20Mike%20the%20Knight%20and%20the%20Great%20Gallop%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1517 - Mike the Knight and the Great Gallop (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1518%20-%20Kirby%20-%20Planet%20Robobot%20%28USA%29.3ds.7z"
        >
          3DS1518 - Kirby - Planet Robobot (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1519%20-%20Legend%20of%20Zelda%20-%20Ocarina%20of%20Time%203D%2C%20The%20%28Korea%29.3ds.7z"
        >
          3DS1519 - Legend of Zelda - Ocarina of Time 3D, The (Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1520%20-%20Pokemon%20X%20%28Korea%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS1520 - Pokemon X (Korea) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1521%20-%20Pokemon%20Y%20%28Korea%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS1521 - Pokemon Y (Korea) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1522%20-%20Pokemon%20Omega%20Ruby%20%28Korea%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1522 - Pokemon Omega Ruby (Korea) (En,Ja,Fr,De,Es,It,Ko) (Rev
          2).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1523%20-%20Pokemon%20Alpha%20Sapphire%20%28Korea%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1523 - Pokemon Alpha Sapphire (Korea) (En,Ja,Fr,De,Es,It,Ko) (Rev
          2).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1524%20-%20Senran%20Kagura%202%20-%20Deep%20Crimson%20%28Korea%29.3ds.7z"
        >
          3DS1524 - Senran Kagura 2 - Deep Crimson (Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1525%20-%20Yo-Kai%20Watch%20%28Korea%29.3ds.7z"
        >
          3DS1525 - Yo-Kai Watch (Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1526%20-%20Rodea%20the%20Sky%20Soldier%20%28Korea%29.3ds.7z"
        >
          3DS1526 - Rodea the Sky Soldier (Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1527%20-%20Monster%20Hunter%204%20%28Korea%29.3ds.7z"
        >
          3DS1527 - Monster Hunter 4 (Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1528%20-%20Tekken%203D%20-%20Prime%20Edition%20%28Korea%29.3ds.7z"
        >
          3DS1528 - Tekken 3D - Prime Edition (Korea).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1529%20-%20Taiko%20no%20Tatsujin%20-%20Don%20Don%21%20Mystery%20Adventure%20%28Japan%29.3ds.7z"
        >
          3DS1529 - Taiko no Tatsujin - Don Don! Mystery Adventure
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1530%20-%20Kouenji%20Joshi%20Soccer%203%20-%20Koisuru%20Eleven%20Itsuka%20wa%20Heaven%20%28Japan%29.3ds.7z"
        >
          3DS1530 - Kouenji Joshi Soccer 3 - Koisuru Eleven Itsuka wa Heaven
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1531%20-%20Bokujou%20Monogatari%20-%203-tsu%20no%20Sato%20no%20Taisetsu%20na%20Tomodachi%20%28Japan%29.3ds.7z"
        >
          3DS1531 - Bokujou Monogatari - 3-tsu no Sato no Taisetsu na Tomodachi
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1532%20-%20Youkai%20Watch%202%20-%20Shinuchi%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1532 - Youkai Watch 2 - Shinuchi (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1533%20-%20Youkai%20Watch%20Busters%20-%20Akanekodan%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1533 - Youkai Watch Busters - Akanekodan (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1534%20-%20Youkai%20Watch%20Busters%20-%20Shiroinutai%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1534 - Youkai Watch Busters - Shiroinutai (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1535%20-%20LEGO%20Star%20Wars%20-Das%20Erwachen%20der%20Macht%20%28Germany%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1535 - LEGO Star Wars -Das Erwachen der Macht (Germany)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1536%20-%20Zero%20Escape%20-%20Zero%20Time%20Dilemma%20%28USA%29.3ds.7z"
        >
          3DS1536 - Zero Escape - Zero Time Dilemma (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1537%20-%20LEGO%20Star%20Wars%20-%20The%20Force%20Awakens%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1537 - LEGO Star Wars - The Force Awakens (Europe)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1538%20-%20Teddy%20Together%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1538 - Teddy Together (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1539%20-%20LEGO%20Star%20Wars%20-%20The%20Force%20Awakens%20%28USA%29.3ds.7z"
        >
          3DS1539 - LEGO Star Wars - The Force Awakens (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1540%20-%20Culdcept%20Revolt%20%28Japan%29.3ds.7z"
        >
          3DS1540 - Culdcept Revolt (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1541%20-%20Zero%20Escape%20-%20Toki%20no%20Dilemma%20%28Japan%29.3ds.7z"
        >
          3DS1541 - Zero Escape - Toki no Dilemma (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1542%20-%20LEGO%20Star%20Wars%20-%20Le%20Reveil%20de%20la%20Force%20%28Europe%29%28French%29.3ds.7z"
        >
          3DS1542 - LEGO Star Wars - Le Reveil de la Force
          (Europe)(French).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1543%20-%20LEGO%20Star%20Wars%20-%20El%20Despertar%20de%20la%20Fuerza%20%28Spain%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1543 - LEGO Star Wars - El Despertar de la Fuerza (Spain)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1544%20-%20LEGO%20Star%20Wars%20-%20Il%20Risveglio%20della%20Forza%20%28Italy%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CDa%29.3ds.7z"
        >
          3DS1544 - LEGO Star Wars - Il Risveglio della Forza (Italy)
          (En,Fr,De,Es,It,Nl,Da).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1545%20-%20Monster%20Hunter%20Generations%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1545 - Monster Hunter Generations (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1546%20-%20Disney%20Art%20Academy%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1546 - Disney Art Academy (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1547%20-%207th%20Dragon%20III%20Code%20-%20VFD%20%28USA%29.3ds.7z"
        >
          3DS1547 - 7th Dragon III Code - VFD (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1548%20-%20Mario%20Tennis%20Open%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1548 - Mario Tennis Open (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1549%20-%20Yo-Kai%20Watch%203%20-%20Sushi%20%28Japan%29.3ds.7z"
        >
          3DS1549 - Yo-Kai Watch 3 - Sushi (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1550%20-%20Yo-Kai%20Watch%203%20-%20Tempura%20%28Japan%29.3ds.7z"
        >
          3DS1550 - Yo-Kai Watch 3 - Tempura (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1551%20-%20Monster%20Hunter%20Generations%20%28USA%29.3ds.7z"
        >
          3DS1551 - Monster Hunter Generations (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1552%20-%20Sumikko%20Gurashi%20-%20Mura%20o%20Tsukurundesu%20%28Japan%29.3ds.7z"
        >
          3DS1552 - Sumikko Gurashi - Mura o Tsukurundesu (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1553%20-%20Puzzle%20%26%20Dragons%20X%20-%20Kami%20no%20Shou%20%28Japan%29.3ds.7z"
        >
          3DS1553 - Puzzle &amp; Dragons X - Kami no Shou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1554%20-%20Puzzle%20%26%20Dragons%20X%20-%20Ryuu%20no%20Shou%20%28Japan%29.3ds.7z"
        >
          3DS1554 - Puzzle &amp; Dragons X - Ryuu no Shou (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1555%20-%20Dairantou%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v07%29%20%5Bb%5D%20%28Japan%29.3ds.7z"
        >
          3DS1555 - Dairantou Smash Bros. for Nintendo 3DS (v07) [b]
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1556%20-%2012-Sai.%20Koisuru%20Diary%20%28Japan%29.3ds.7z"
        >
          3DS1556 - 12-Sai. Koisuru Diary (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1557%20-%20Sekaiju%20no%20Meikyuu%20V%20-%20Nagaki%20Shinwa%20no%20Hate%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1557 - Sekaiju no Meikyuu V - Nagaki Shinwa no Hate (Japan) (Rev
          1).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1558%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Kikansha%20Thomas%20Hen%20-%20Ooigawa%20Tetsudou%20wo%20Hashirou%21%20%28Japan%29.3ds.7z"
        >
          3DS1558 - Tetsudou Nippon! Rosen Tabi - Kikansha Thomas Hen - Ooigawa
          Tetsudou wo Hashirou! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1559%20-%20Dragon%20Ball%20Fusions%20%28Japan%29.3ds.7z"
        >
          3DS1559 - Dragon Ball Fusions (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1560%20-%20Metroid%20Prime%20-%20Federation%20Force%20%28USA%29%20%28En%2CFr%2CEs%29.3ds.7z"
        >
          3DS1560 - Metroid Prime - Federation Force (USA) (En,Fr,Es).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1561%20-%20Legend%20of%20Zelda%20-%20Majora%27s%20Mask%203D%2C%20The%20%28v01%29%20%28USA%29.3ds.7z"
        >
          3DS1561 - Legend of Zelda - Majora's Mask 3D, The (v01) (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1562%20-%20Style%20Savvy%20-%20Fashion%20Forward%20%28USA%29.3ds.7z"
        >
          3DS1562 - Style Savvy - Fashion Forward (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1563%20-%20Pokemon%20X%20%28Taiwan%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS1563 - Pokemon X (Taiwan) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1564%20-%20Pokemon%20Y%20%28Taiwan%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CKo%29.3ds.7z"
        >
          3DS1564 - Pokemon Y (Taiwan) (En,Ja,Fr,De,Es,It,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1565%20-%20Metroid%20Prime%20-%20Federation%20Force%20%28Japan%29.3ds.7z"
        >
          3DS1565 - Metroid Prime - Federation Force (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1566%20-%20Shovel%20Knight%20%28Japan%29.3ds.7z"
        >
          3DS1566 - Shovel Knight (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1567%20-%20Metroid%20Prime%20-%20Federation%20Force%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1567 - Metroid Prime - Federation Force (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1568%20-%20Shin%20Megami%20Tensei%20IV%20-%20Apocalypse%20%28USA%29.3ds.7z"
        >
          3DS1568 - Shin Megami Tensei IV - Apocalypse (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1569%20-%20Dragon%20Quest%20VII%20-%20Fragments%20of%20the%20Forgotten%20Past%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1569 - Dragon Quest VII - Fragments of the Forgotten Past (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1570%20-%20Dragon%20Quest%20VII%20-%20Fragments%20of%20the%20Forgotten%20Past%20%28USA%29.3ds.7z"
        >
          3DS1570 - Dragon Quest VII - Fragments of the Forgotten Past
          (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1571%20-%20Armed%20Blue%20Gunvolt%20-%20Striker%20Pack%20%28Japan%29.3ds.7z"
        >
          3DS1571 - Armed Blue Gunvolt - Striker Pack (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1572%20-%20Sonic%20Boom%20-%20Fire%20%26%20Ice%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1572 - Sonic Boom - Fire &amp; Ice (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1573%20-%20Sonic%20Boom%20-%20Fire%20%26%20Ice%20%28USA%29.3ds.7z"
        >
          3DS1573 - Sonic Boom - Fire &amp; Ice (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1574%20-%20River%20City%20-%20Tokyo%20Rumble%20%28USA%29.3ds.7z"
        >
          3DS1574 - River City - Tokyo Rumble (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1575%20-%20Yo-Kai%20Watch%202%20-%20Fleshy%20Souls%20%28USA%29.3ds.7z"
        >
          3DS1575 - Yo-Kai Watch 2 - Fleshy Souls (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1576%20-%20Yo-Kai%20Watch%202%20-%20Bony%20Spirits%20%28USA%29..3ds.7z"
        >
          3DS1576 - Yo-Kai Watch 2 - Bony Spirits (USA)..3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1577%20-%20Mario%20Party%20-%20Star%20Rush%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS1577 - Mario Party - Star Rush (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1578%20-%20Azure%20Striker%20Gunvolt%20-%20Striker%20Pack%20%28USA%29.3ds.7z"
        >
          3DS1578 - Azure Striker Gunvolt - Striker Pack (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1579%20-%20Disney%20Magical%20World%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1579 - Disney Magical World 2 (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1580%20-%20Disney%20Magical%20World%202%20%28USA%29.3ds.7z"
        >
          3DS1580 - Disney Magical World 2 (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1581%20-%20Rhythm%20Heaven%20Megamix%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1581 - Rhythm Heaven Megamix (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1582%20-%20Nintendogs%20%20%20Cats%20-%20Golden%20Retriever%20%26%20New%20Friends%20%28USA%29%20%28En%2CFr%2CEs%29%20%28Rev%202%29.3ds.7z"
        >
          3DS1582 - Nintendogs Cats - Golden Retriever &amp; New Friends (USA)
          (En,Fr,Es) (Rev 2).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1583%20-%20Shantae%20and%20the%20Pirates%20Curse%20%28USA%29.3ds.7z"
        >
          3DS1583 - Shantae and the Pirates Curse (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1584%20-%20Shantae%20and%20the%20Pirates%20Curse%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1584 - Shantae and the Pirates Curse (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1585%20-%20One%20Piece%20-%20Dai%20Kaizoku%20Colosseum%20%28Japan%29.3ds.7z"
        >
          3DS1585 - One Piece - Dai Kaizoku Colosseum (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1586%20-%20Downtown%20Nekketsu%20Monogatari%20SP%20%28Japan%29.3ds.7z"
        >
          3DS1586 - Downtown Nekketsu Monogatari SP (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1587%20-%20Corpse%20Party%20%28USA%29.3ds.7z"
        >
          3DS1587 - Corpse Party (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1588%20-%20Harvest%20Moon%20-%20Skytree%20Village%20%28USA%29.3ds.7z"
        >
          3DS1588 - Harvest Moon - Skytree Village (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1589%20-%20Chou%20Sentouchuu%20Kyuukyoku%20no%20Shinobi%20to%20Battle%20Player%20Choujou%20Kessen%20%28Japan%29.3ds.7z"
        >
          3DS1589 - Chou Sentouchuu Kyuukyoku no Shinobi to Battle Player
          Choujou Kessen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1590%20-%20Kobito%20Game%20Taizen%20%28Japan%29.3ds.7z"
        >
          3DS1590 - Kobito Game Taizen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1591%20-%20Sega%203D%20Classics%20Collection%20%28Europe%29.3ds.7z"
        >
          3DS1591 - Sega 3D Classics Collection (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1592%20-%20Disney%202-Pack%20-%20Frozen%20-%20Olaf_s%20Quest%20%20%20Big%20Hero%206%20-%20Battle%20in%20the%20Bay%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CNl%29.3ds.7z"
        >
          3DS1592 - Disney 2-Pack - Frozen - Olaf_s Quest Big Hero 6 - Battle in
          the Bay (Europe) (En,Fr,De,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1593%20-%20Monster%20Hunter%20Stories%20%28Japan%29.3ds.7z"
        >
          3DS1593 - Monster Hunter Stories (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1594%20-%20Mario%20Party%20-%20Star%20Rush%20%28Japan%29.3ds.7z"
        >
          3DS1594 - Mario Party - Star Rush (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1595%20-%20Mario%20Party%20-%20Star%20Rush%20%28USA%29.3ds.7z"
        >
          3DS1595 - Mario Party - Star Rush (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1596%20-%20Cartoon%20Network%20-%20Battle%20Crashers%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1596 - Cartoon Network - Battle Crashers (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1597%20-%20Yo-Kai%20Watch%202%20-%20Bony%20Spirits%20%28Australia%29.3ds.7z"
        >
          3DS1597 - Yo-Kai Watch 2 - Bony Spirits (Australia).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1598%20-%20Yo-Kai%20Watch%202%20-%20Fleshy%20Souls%20%28Australia%29.3ds.7z"
        >
          3DS1598 - Yo-Kai Watch 2 - Fleshy Souls (Australia).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1599%20-%20LEGO%20Star%20Wars%20-%20Force%20no%20Kakusei%20%28Japan%29.3ds.7z"
        >
          3DS1599 - LEGO Star Wars - Force no Kakusei (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1600%20-%20Sonic%20Toon%20-%20Fire%20and%20Ice%20%28Japan%29.3ds.7z"
        >
          3DS1600 - Sonic Toon - Fire and Ice (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1601%20-%20Saiki%20Kusuo%20no%20Sainan%20-%20Shijou%20Saidai%20no%20Sainan%20%28Japan%29.3ds.7z"
        >
          3DS1601 - Saiki Kusuo no Sainan - Shijou Saidai no Sainan
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1602%20-%20Beyblade%20Burst%20%28Japan%29.3ds.7z"
        >
          3DS1602 - Beyblade Burst (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1603%20-%20Pokemon%20Sun%20%28Japan%29.3ds.7z"
        >
          3DS1603 - Pokemon Sun (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1604%20-%20Pocket%20Monsters%20Moon%20%28Japan%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CZh%2CKo%29.3ds.7z"
        >
          3DS1604 - Pocket Monsters Moon (Japan)
          (En,Ja,Fr,De,Es,It,Zh,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1605%20-%20Pokemon%20Moon%20%28Europe%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CZh%2CKo%29.3ds.7z"
        >
          3DS1605 - Pokemon Moon (Europe) (En,Ja,Fr,De,Es,It,Zh,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1606%20-%20Pokemon%20Sun%20%28Europe%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CZh%2CKo%29.3ds.7z"
        >
          3DS1606 - Pokemon Sun (Europe) (En,Ja,Fr,De,Es,It,Zh,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1607%20-%20Cartoon%20Network%20-%20Battle%20Crashers%20%28USA%29.3ds.7z"
        >
          3DS1607 - Cartoon Network - Battle Crashers (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1608%20-%20PriPara%20Mezameyo%21%20Megami%20no%20Dress%20Design%20%28Japan%29.3ds.7z"
        >
          3DS1608 - PriPara Mezameyo! Megami no Dress Design (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1609%20-%20Pokemon%20Sun%20%28USA%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CZh%2CKo%29.3ds.7z"
        >
          3DS1609 - Pokemon Sun (USA) (En,Ja,Fr,De,Es,It,Zh,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1610%20-%20Pokemon%20Moon%20%28USA%29%20%28En%2CJa%2CFr%2CDe%2CEs%2CIt%2CZh%2CKo%29.3ds.7z"
        >
          3DS1610 - Pokemon Moon (USA) (En,Ja,Fr,De,Es,It,Zh,Ko).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1611%20-%20Dragon%20Ball%20Fusions%20%28USA%29.3ds.7z"
        >
          3DS1611 - Dragon Ball Fusions (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1612%20-%20Aikatsu%20Stars%21%20My%20Special%20Appeal%20%28Japan%29.3ds.7z"
        >
          3DS1612 - Aikatsu Stars! My Special Appeal (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1613%20-%20Tetsudou%20Nippon%21%20Rosen%20Tabi%20-%20Aizu%20Tetsudou%20Hen%20%28Japan%29.3ds.7z"
        >
          3DS1613 - Tetsudou Nippon! Rosen Tabi - Aizu Tetsudou Hen
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1614%20-%20LEGO%20Ninjago%20-%20Nindroids%20%28Japan%29.3ds.7z"
        >
          3DS1614 - LEGO Ninjago - Nindroids (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1615%20-%20RPG%20Tsukuru%20Fes%20%28Japan%29.3ds.7z"
        >
          3DS1615 - RPG Tsukuru Fes (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1616%20-%20Super%20Mario%20Maker%20for%20Nintendo%203DS%20%28Japan%29.3ds.7z"
        >
          3DS1616 - Super Mario Maker for Nintendo 3DS (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1617%20-%20Picross%203D%20-%20Round%202%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1617 - Picross 3D - Round 2 (Europe) (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1618%20-%20Super%20Mario%20Maker%20for%20Nintendo%203DS%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29.3ds.7z"
        >
          3DS1618 - Super Mario Maker for Nintendo 3DS (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1619%20-%20Shin%20Megami%20Tensei%20IV%20-%20Apocalypse%20%28Europe%29.3ds.7z"
        >
          3DS1619 - Shin Megami Tensei IV - Apocalypse (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1620%20-%20Super%20Mario%20Maker%20for%20Nintendo%203DS%20%28USA%29.3ds.7z"
        >
          3DS1620 - Super Mario Maker for Nintendo 3DS (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1621%20-%207th%20Dragon%20III%20Code%20-%20VFD%20%28Europe%29.3ds.7z"
        >
          3DS1621 - 7th Dragon III Code - VFD (Europe).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1622%20-%20All%20Kamen%20Rider%20-%20Rider%20Revolution%20%28Japan%29.3ds.7z"
        >
          3DS1622 - All Kamen Rider - Rider Revolution (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1623%20-%20Digimon%20Universe%20-%20Appli%20Monsters%20%28Japan%29.3ds.7z"
        >
          3DS1623 - Digimon Universe - Appli Monsters (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1624%20-%20Tobidase%20Doubutsu%20no%20Mori%20-%20amiibo%20%20%28Japan%29.3ds.7z"
        >
          3DS1624 - Tobidase Doubutsu no Mori - amiibo (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1625%20-%20Yo-kai%20Watch%203%20-%20Sukiyaki%20%28Japan%29.3ds.7z"
        >
          3DS1625 - Yo-kai Watch 3 - Sukiyaki (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1626%20-%20Animal%20Crossing%20-%20New%20Leaf%20-%20Welcome%20Amiibo%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29.3ds.7z"
        >
          3DS1626 - Animal Crossing - New Leaf - Welcome Amiibo (Europe)
          (En,Fr,De,Es,It).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1627%20-%20Legend%20of%20Zelda%2C%20The%20-%20Majora_s%20Mask%203D%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1627 - Legend of Zelda, The - Majora_s Mask 3D (Europe)
          (En,Fr,De,Es,It) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1628%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%204%29.3ds.7z"
        >
          3DS1628 - Super Smash Bros. for Nintendo 3DS (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 4).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1629%20-%20Nintendogs%20and%20Cats%20-%20Toy%20Poodle%20and%20New%20Friends%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1629 - Nintendogs and Cats - Toy Poodle and New Friends (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1630%20-%20Jikkyou%20Powerful%20Pro%20Yakyuu%20Heroes%20%28Japan%29.3ds.7z"
        >
          3DS1630 - Jikkyou Powerful Pro Yakyuu Heroes (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1631%20-%20Momotarou%20Dentetsu%202017%20-%20Tachiagare%20Nippon%21%21%20%28Japan%29.3ds.7z"
        >
          3DS1631 - Momotarou Dentetsu 2017 - Tachiagare Nippon!! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1632%20-%20Hyakumasu%20Dora-San%20Nobita%20no%20Time%20Battle%20%28Japan%29.3ds.7z"
        >
          3DS1632 - Hyakumasu Dora-San Nobita no Time Battle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1633%20-%20Miitopia%20%28Japan%29.3ds.7z"
        >
          3DS1633 - Miitopia (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1634%20-%20A-Ressha%20de%20Ikou%203D%20NEO%20%28Japan%29.3ds.7z"
        >
          3DS1634 - A-Ressha de Ikou 3D NEO (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1635%20-%20Kunio-kun%20Nekketsu%20Complete%20-%20Famicom-Hen%20%28Japan%29.3ds.7z"
        >
          3DS1635 - Kunio-kun Nekketsu Complete - Famicom-Hen (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1636%20-%20Gudetama%20-%20Okawari%20Ikagassuka%20%28Japan%29.3ds.7z"
        >
          3DS1636 - Gudetama - Okawari Ikagassuka (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1637%20-%20FIFA%2012%20%28v01%29%28Europe%29%28En%2CFr%2CDu%29.3ds.7z"
        >
          3DS1637 - FIFA 12 (v01)(Europe)(En,Fr,Du).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1638%20-%20Dragon%20Quest%20VIII%20-%20Journey%20of%20the%20Cursed%20King%20%28Europe%29%28En%2CFr%2CIt%2CSp%2CGr%29.3ds.7z"
        >
          3DS1638 - Dragon Quest VIII - Journey of the Cursed King
          (Europe)(En,Fr,It,Sp,Gr).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1639%20-%20Dragon%20Quest%20VIII%20-%20Journey%20of%20the%20Cursed%20King%20%28USA%29.3ds.7z"
        >
          3DS1639 - Dragon Quest VIII - Journey of the Cursed King (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1640%20-%20Hotel%20Transylvania%20%28Europe%29%20%28En%2CFr%2CNl%29.3ds.7z"
        >
          3DS1640 - Hotel Transylvania (Europe) (En,Fr,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1641%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%2CPt%2CRu%29%20%28Rev%2010%29.3ds.7z"
        >
          3DS1641 - Super Smash Bros. for Nintendo 3DS (Europe)
          (En,Fr,De,Es,It,Nl,Pt,Ru) (Rev 10).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1642%20-%20Kamiwaza%20Wanda%20-%20Kirakira%20Ichibangai%20Kikiippatsu%20%28Japan%29.3ds.7z"
        >
          3DS1642 - Kamiwaza Wanda - Kirakira Ichibangai Kikiippatsu
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1643%20-%20Puyo%20Puyo%20Chronicle%20%28Japan%29.3ds.7z"
        >
          3DS1643 - Puyo Puyo Chronicle (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1644%20-%20Poochy%20%26%20Yoshis%20Woolly%20World%20%28Europe%29%20%28En%2CFr%2CDe%2CEs%2CIt%2CNl%29.3ds.7z"
        >
          3DS1644 - Poochy &amp; Yoshis Woolly World (Europe)
          (En,Fr,De,Es,It,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1645%20-%20Wannyan%20Pet%20Shop%20v03%20%28Japan%29.3ds.7z"
        >
          3DS1645 - Wannyan Pet Shop v03 (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1646%20-%20Hakoboi%20HakozumeBOX%20%28Japan%29.3ds.7z"
        >
          3DS1646 - Hakoboi HakozumeBOX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1647%20-%20Poochy%20%26%20Yoshis%20Woolly%20World%20%28USA%29.3ds.7z"
        >
          3DS1647 - Poochy &amp; Yoshis Woolly World (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1648%20-%20Animal%20Crossing%20-%20New%20Leaf%20-%20Welcome%20Amiibo%20%28USA%29.3ds.7z"
        >
          3DS1648 - Animal Crossing - New Leaf - Welcome Amiibo (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1649%20-%20Disney%202%20-%20Pack%20-%20Frozen%20-%20Olafs%20Quest%20%20%20Big%20Hero%206%20-%20Battle%20in%20the%20Bay%20%28USA%29.3ds.7z"
        >
          3DS1649 - Disney 2 - Pack - Frozen - Olafs Quest Big Hero 6 - Battle
          in the Bay (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1650%20-%20Barbie%20-%20Glam%20and%20Groom%20Pups%20%28Usa%29.3ds.7z"
        >
          3DS1650 - Barbie - Glam and Groom Pups (Usa).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1651%20-%20Dragon%20Quest%20Monsters%20-%20Joker%203%20Professional%20%28Japan%29.3ds.7z"
        >
          3DS1651 - Dragon Quest Monsters - Joker 3 Professional (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1652%20-%20Osomatsu-san%20Matsu%20Matsuri%21%20%28Japan%29.3ds.7z"
        >
          3DS1652 - Osomatsu-san Matsu Matsuri! (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1653%20-%20Poochy%21%20to%20Yoshi%20Wool%20World%20%28Japan%29.3ds.7z"
        >
          3DS1653 - Poochy! to Yoshi Wool World (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1654%20-%20Dragon%20Ball%20-%20Fusions%20%28EUR%29%28M5%29.3ds.7z"
        >
          3DS1654 - Dragon Ball - Fusions (EUR)(M5).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1655%20-%20Dairantou%20Smash%20Brothers%20for%20Nintendo%203DS%20%28Japan%29%20%28Rev%203%29.3ds.7z"
        >
          3DS1655 - Dairantou Smash Brothers for Nintendo 3DS (Japan) (Rev
          3).3ds.7z{" "}
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1656%20-%20Adventure%20Time%20-%20Nameless%20Oukoku%20no%20Sannin%20no%20Princess%20%28Japan%29.3ds.7z"
        >
          3DS1656 - Adventure Time - Nameless Oukoku no Sannin no Princess
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1657%20-%20Rilu%20Rilu%20Fairilu%20Kirakira%20-%20Hajimete%20no%20Fairilu%20Magic%20%28Japan%29.3ds.7z"
        >
          3DS1657 - Rilu Rilu Fairilu Kirakira - Hajimete no Fairilu Magic
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1658%20-%20LEGO%20Batman%202%20-%20DC%20Super%20Heroes%20%28v01%29%28German%29%28En%2CGe%29.3ds.7z"
        >
          3DS1658 - LEGO Batman 2 - DC Super Heroes (v01)(German)(En,Ge).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1659%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v03%29%28Europe%29%28M8%29.3ds.7z"
        >
          3DS1659 - Super Smash Bros. for Nintendo 3DS (v03)(Europe)(M8).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1660%20-%20Story%20of%20Seasons%20-%20Trio%20of%20Towns%20%28USA%29.3ds.7z"
        >
          3DS1660 - Story of Seasons - Trio of Towns (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1661%20-%20Mario%20Sports%20Superstars%20%28Europe%29%20%28En%2CFr%2CDe%2CIt%2CEs%2CNl%29.3ds.7z"
        >
          3DS1661 - Mario Sports Superstars (Europe) (En,Fr,De,It,Es,Nl).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1662%20-%20Rune%20Factory%204%20%28Japan%29%20%28Rev%201%29.3ds.7z"
        >
          3DS1662 - Rune Factory 4 (Japan) (Rev 1).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1663%20-%20Monster%20Hunter%20XX%20Double%20Cross%20%28Japan%29.3ds.7z"
        >
          3DS1663 - Monster Hunter XX Double Cross (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1664%20-%20SpongeBob%20SquigglePants%203D%20%28v01%29%28Europe%29%28M5%29.3ds.7z"
        >
          3DS1664 - SpongeBob SquigglePants 3D (v01)(Europe)(M5).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1665%20-%20SpongeBob%20SquigglePants%203D%20%28v01%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1665 - SpongeBob SquigglePants 3D (v01)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1666%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v05%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1666 - Super Smash Bros. for Nintendo 3DS (v05)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1667%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v06%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1667 - Super Smash Bros. for Nintendo 3DS (v06)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1668%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v10%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1668 - Super Smash Bros. for Nintendo 3DS (v10)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1669%20-%20Super%20Mario%203D%20Land%20%28v01%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1669 - Super Mario 3D Land (v01)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1670%20-%20Sonic%20Generations%20%28v01%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1670 - Sonic Generations (v01)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1671%20-%20LEGO%20Pirates%20of%20the%20Caribbean%20-%20The%20Video%20Game%20%28v01%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1671 - LEGO Pirates of the Caribbean - The Video Game
          (v01)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1672%20-%20Mario%20%26%20Luigi%20-%20Dream%20Team%20%28v01%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1672 - Mario &amp; Luigi - Dream Team (v01)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1673%20-%20Mario%20Tennis%20Open%20%28USA%29%28M3%29.3ds.7z"
        >
          3DS1673 - Mario Tennis Open (USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1674%20-%20Hotel%20Transylvania%20%28USA%29%28M3%29.3ds.7z"
        >
          3DS1674 - Hotel Transylvania (USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1675%20-%20Harvest%20Moon%203D%20-%20The%20Tale%20of%20Two%20Towns%20%28v02%29%28USA%29.3ds.7z"
        >
          3DS1675 - Harvest Moon 3D - The Tale of Two Towns (v02)(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1676%20-%20Zero%20Escape%20-%20Virtue%27s%20Last%20Reward%20%28v02%29%28USA%29.3ds.7z"
        >
          3DS1676 - Zero Escape - Virtue's Last Reward (v02)(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1677%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v07%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1677 - Super Smash Bros. for Nintendo 3DS (v07)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1678%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v01%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1678 - Super Smash Bros. for Nintendo 3DS (v01)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1679%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v03%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1679 - Super Smash Bros. for Nintendo 3DS (v03)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1680%20-%20Mario%20Sports%20Superstars%20%28USA%29.3ds.7z"
        >
          3DS1680 - Mario Sports Superstars (USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1681%20-%20Professor%20Layton%20and%20the%20Miracle%20Mask%20%28v01%29%28USA%29.3ds.7z"
        >
          3DS1681 - Professor Layton and the Miracle Mask (v01)(USA).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1682%20-%20Yo-Kai%20Watch%202%20-%20Bony%20Spirits%20%28Europe%29%28M6%29.3ds.7z"
        >
          3DS1682 - Yo-Kai Watch 2 - Bony Spirits (Europe)(M6).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1683%20-%20Yo-Kai%20Watch%202%20-%20Fleshy%20Souls%20%28Europe%29%28M6%29.3ds.7z"
        >
          3DS1683 - Yo-Kai Watch 2 - Fleshy Souls (Europe)(M6).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1684%20-%20Harvest%20Moon%203D%20-%20The%20Tale%20of%20Two%20Towns%20%28v01%29%28Europe%29%28M3%29.3ds.7z"
        >
          3DS1684 - Harvest Moon 3D - The Tale of Two Towns
          (v01)(Europe)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1685%20-%20Super%20Smash%20Bros.%20for%20Nintendo%203DS%20%28v04%29%28USA%29%28M3%29.3ds.7z"
        >
          3DS1685 - Super Smash Bros. for Nintendo 3DS (v04)(USA)(M3).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1686%20-%20Fire%20Emblem%20Echoes%20-%20Mou%20Hitori%20no%20Eiyuu%20Ou%20%28v01%29%20%28Japan%29.3ds.7z"
        >
          3DS1686 - Fire Emblem Echoes - Mou Hitori no Eiyuu Ou (v01)
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1687%20-%20Doraemon%20-%20Nobita%20no%20Nankyoku%20Kachikochi%20Daibouken%20%28Japan%29.3ds.7z"
        >
          3DS1687 - Doraemon - Nobita no Nankyoku Kachikochi Daibouken
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1688%20-%20Manga-ka%20Debut%20Monogatari%20-%20Suteki%20na%20Manga%20o%20Egakou%20%28Japan%29.3ds.7z"
        >
          3DS1688 - Manga-ka Debut Monogatari - Suteki na Manga o Egakou
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1689%20-%20Sega%203D%20Fukkoku%20Archives%203%20-%20Final%20Stage%20%28Japan%29.3ds.7z"
        >
          3DS1689 - Sega 3D Fukkoku Archives 3 - Final Stage (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1690%20-%20Super%20Mario%20Maker%20for%20Nintendo%203DS%20%28v02%29%20%28Japan%29.3ds.7z"
        >
          3DS1690 - Super Mario Maker for Nintendo 3DS (v02) (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1691%20-%20Future%20Card%20Buddyfight%20-%20Mezase%21%20Buddy%20Champion%21%20%28Japan%29.3ds.7z"
        >
          3DS1691 - Future Card Buddyfight - Mezase! Buddy Champion!
          (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1692%20-%20Mario%20Sports%20Superstars%20%28Japan%29.3ds.7z"
        >
          3DS1692 - Mario Sports Superstars (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1693%20-%20Pro%20Yakyuu%20Famista%20Climax%20%28Japan%29.3ds.7z"
        >
          3DS1693 - Pro Yakyuu Famista Climax (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1694%20-%20Dragon%20Ball%20Heroes%20-%20Ultimate%20Mission%20X%20%28Japan%29.3ds.7z"
        >
          3DS1694 - Dragon Ball Heroes - Ultimate Mission X (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1695%20-%20Pro%20Yakyuu%20Famista%20Climax%20%28v01%29%28Japan%29.3ds.7z"
        >
          3DS1695 - Pro Yakyuu Famista Climax (v01)(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1696%20-%20Super%20Mario%203D%20Land%20%28v01%29%28Japan%29.3ds.7z"
        >
          3DS1696 - Super Mario 3D Land (v01)(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1697%20-%20Super%20Mario%203D%20Land%20%28v02%29%28Japan%29.3ds.7z"
        >
          3DS1697 - Super Mario 3D Land (v02)(Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1698%20-%20Cube%20Creator%20DX%20%28Japan%29.3ds.7z"
        >
          3DS1698 - Cube Creator DX (Japan).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1699%20-%20Fire%20Emblem%20Echoes%20-%20Shadows%20of%20Valentia%20%28Europe%29%28M6%29.3ds.7z"
        >
          3DS1699 - Fire Emblem Echoes - Shadows of Valentia (Europe)(M6).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>

        <a
          className="download-link"
          href="https://archive.org/download/nintendo-3ds-complete-collection/3DS1700%20-%20Rayman%203D%20%28v01%29%28Europe%29%28M6%29.3ds.7z"
        >
          3DS1700 - Rayman 3D (v01)(Europe)(M6).3ds.7z
          <span className="iconochive-download" aria-hidden="true"></span>
        </a>
      </div>
    </div>
  );
};

export default Home;